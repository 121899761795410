import { store } from 'application/redux/store';
import { TypesData } from 'types/types.type';
import { getTypesData } from 'application/redux/selectors';

const setType = (typesKey: string, object: any, types: any) => {
    const typeKey = typesKey.slice(0, -1);
    const typeIdKey = typeKey + 'Id';
    const typeIdsKey = typeKey + 'Ids';
    if (typeof object === 'object' && typeIdKey in object) {
        const typeId = object[typeIdKey];
        if (typeId && typeId.toString() in types) {
            object[typeKey] = types[typeId];
        }
    }

    if (typeof object === 'object' && typeIdsKey in object) {
        const typeIds = object[typeIdsKey];
        if (Array.isArray(typeIds)) {
            object[typesKey] = [];
            typeIds.forEach((id) => {
                if (id && id.toString() in types) {
                    object[typesKey].push(types[id]);
                }
            });
        }
    }

    // replace in children
    const objectKeys = Object.keys(object).filter((key) => object[key] !== null && typeof object[key] === 'object');
    objectKeys.forEach((objKey) => {
        if (Array.isArray(object[objKey])) {
            object[objKey].forEach((obj: any) => setType(typesKey, obj, types));
        } else {
            setType(typesKey, object[objKey], types);
        }
    });
};

export function setTypes(data?: any) {
    if (!data || (Array.isArray(data) && data.length === 0)) {
        return data;
    }
    // @ts-ignore
    const allTypes: TypesData = getTypesData(store.getState());
    Object.keys(allTypes).forEach((typesKey) => {
        if (Array.isArray(data)) {
            data.forEach((value) => setType(typesKey, value, allTypes[typesKey]));
        } else {
            setType(typesKey, data, allTypes[typesKey]);
        }
    });
    return data;
}
