import AuthenticationState from '../store/AuthenticationState';
import { Reducer } from 'redux';
import { REDUX, REDUX_PERSIST } from 'constants/index';
import { persist } from '../lib/persist';

const initialState: AuthenticationState = {
    isAuthenticated: false,
    signInError: false,
    user: {},
    token: '',
};

const persistWhitelist = ['isAuthenticated', 'user', 'token'];

const authReducer: Reducer<AuthenticationState> = (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case `${REDUX.AUTH_LOGIN}/LOADING`: {
            return state;
        }
        case `${REDUX.AUTH_LOGOUT}/SUCCESS`: {
            return {
                ...state,
                user: {},
                isAuthenticated: false,
                signInError: false,
                token: '',
            };
        }
        case `${REDUX.AUTH_LOGIN}/SUCCESS`: {
            return {
                ...state,
                user: payload.user,
                isAuthenticated: true,
                signInError: false,
                token: payload.token,
            };
        }
        case `${REDUX.AUTH_LOGIN}/ERROR`: {
            return {
                ...state,
                error: payload,
                isAuthenticated: false,
                signInError: true,
                token: '',
            };
        }
        default: {
            return state;
        }
    }
};

export default persist(REDUX_PERSIST.AUTH, persistWhitelist, authReducer);
