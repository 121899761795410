import { COLORS } from 'constants/index';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

const MuiOutlinedInput = {
    root: {
        [`& .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: `${COLORS.GRAY_MED}`,
        },
        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: `${COLORS.GRAY_MED} !important`,
        },
        [`& .${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: `${COLORS.ERROR} !important`,
        },
    },
    notchedOutline: {
        borderColor: `${COLORS.GRAY_LIGHT}`,
    },
};

export default MuiOutlinedInput;
