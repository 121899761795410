import * as React from 'react';
import { Select as MuiSelect, OutlinedInput, Input, InputLabel } from '@mui/material';
import { SelectProps } from '@mui/material/Select';
import InputWrapper from './InputWrapper';
import { COLORS } from 'constants/index';

interface Props extends Partial<SelectProps> {
    errorMessage?: any;
    fullHeight?: boolean;
    title?: string;
    value?: any;
    variant?: 'filled' | 'outlined' | 'standard';
    sx?: any;
}

const Select: React.FunctionComponent<Props> = (props) => {
    const { children, errorMessage, fullHeight, title, label, variant, sx, ...restOfProps } = props;
    const styles = variant === 'filled' ? { backgroundColor: COLORS.WHITE } : {};
    const selectLabel = variant === 'standard' ? label : null;
    const input =
        variant === 'standard' ? <Input style={styles} /> : <OutlinedInput style={styles} label="" name={title} />;
    return (
        <InputWrapper title={title} error={errorMessage} fullHeight={fullHeight} sx={sx}>
            {selectLabel && <InputLabel id={selectLabel + 'selectLabel'}>{selectLabel}</InputLabel>}
            <MuiSelect
                variant="standard"
                id={title}
                labelId={selectLabel + 'selectLabel'}
                displayEmpty={false}
                MenuProps={{ anchorOrigin: { vertical: 'top', horizontal: 'center' } }}
                input={input}
                // variant={variant}
                error={!!errorMessage}
                {...restOfProps}
            >
                {children}
            </MuiSelect>
        </InputWrapper>
    );
};

export default Select;
