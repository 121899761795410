import { COLORS } from 'constants/index';

const MuiSvgIcon = {
    colorError: {
        color: COLORS.ERROR,
    },
    colorAction: {
        color: COLORS.SUCCESS,
    },
};

export default MuiSvgIcon;
