import * as React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import styles from '../styles/Button';
import { withStyles } from '@mui/styles';

type ButtonType = 'primary' | 'secondary' | 'text';

interface Props extends ButtonProps {
    classes?: any;
    disabled?: boolean;
    isLoading?: boolean;
    hidden?: boolean;
    lrType?: ButtonType;
    onClick?: (e?: any) => void;
}

function getVariantByType(type: ButtonType) {
    switch (type) {
        case 'primary':
            return 'contained';
        case 'secondary':
            return 'outlined';
        case 'text':
            return 'text';
        default:
            return '';
    }
}

class LrButton extends React.Component<Props> {
    render() {
        const {
            type,
            disabled,
            isLoading,
            hidden,
            children,
            classes,
            onClick,
            variant,
            lrType,
            color,
            ...rest
        } = this.props;

        const customVriant = lrType ? getVariantByType(lrType) : variant;
        const customColor = lrType ? 'primary' : color;

        if (hidden) return <></>;

        return (
            <Button
                variant={customVriant || 'contained'}
                disabled={isLoading || disabled}
                onClick={onClick}
                color={customColor || undefined}
                type={type}
                {...rest}
            >
                {isLoading && <CircularProgress size={24} color="secondary" className={classes.buttonProgress} />}
                {!isLoading && <>{children}</>}
            </Button>
        );
    }
}

export default withStyles(styles)(LrButton);
