import { COLORS } from 'constants/index';

const MuiCheckbox = {
    root: {
        padding: '0px',
        height: '20px',
        width: '20px',
        border: `0px solid ${COLORS.GRAY_LIGHT}`,
        borderRadius: '6px',
    },
};

export default MuiCheckbox;
