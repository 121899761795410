import { createSelector } from 'reselect';

import ApplicationState from '../store/ApplicationState';
import SubmissionsState, { SubmissionData } from '../store/SubmissionsState';
import { getGridSelectors } from '../lib/gridSelectorCreators';

const submissionState = (state: ApplicationState) => state.submissions;
const data = (state: SubmissionsState) => state.data;
const error = (state: SubmissionsState) => state.error;
const loading = (state: SubmissionsState) => state.isLoading;
const submissions = (d: SubmissionData) => d.submissions;

const gridSelectors = getGridSelectors(submissionState);

export const getSubmissionsGridColumns = gridSelectors.getGridColumns;
export const getSubmissionsGridFilters = gridSelectors.getGridFilters;
export const getSubmissionsGridError = gridSelectors.getGridError;

export const getSubmissionsData = createSelector(submissionState, data);

export const getSubmissions = createSelector(getSubmissionsData, submissions);

export const getSubmissionsError = createSelector(submissionState, error);

export const getSubmissionsLoading = createSelector(submissionState, loading);
