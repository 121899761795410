import * as React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Menu, MenuItem, Typography, Button, IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Add from '@mui/icons-material/Add';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { ReactComponent as Logo } from 'assets/lumen_logo.svg';
import { getUserName } from 'application/redux/selectors';
import LrButton from 'components/LrButton';
import { Margin } from 'components/Layout';

import { LogoWrapper, MenuWrapper } from './styled';
import { ReactFragment } from 'react';
import LrAboutDialog from './components/LrAboutDialog';

interface Props extends RouteComponentProps {
    handleClick?: () => void;
    label: ReactFragment | string;
    subHeading?: ReactFragment | string;
    actionEnabled?: boolean;
}

const defaultProps: Partial<Props> = {
    actionEnabled: true,
};

const Header: React.FunctionComponent<Props> = ({ handleClick, label, history, subHeading, actionEnabled }) => {
    // Local State
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showAbout, setShowAbout] = React.useState(false);

    // Global State
    const name = useSelector(getUserName);

    function handleOpen(event: any) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    const renderLabelAndAction = () => (
        <Grid container spacing={2} alignItems="center">
            <Grid>
                {handleClick && (
                    <LrButton
                        data-testid="header-action"
                        lrType="primary"
                        size="small"
                        disabled={!actionEnabled}
                        onClick={handleClick}
                    >
                        <Add fontSize="large" />
                    </LrButton>
                )}
            </Grid>
            <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h3" style={{ margin: 0 }}>
                    {label}
                </Typography>
                {subHeading && (
                    <Margin left="24">
                        <Typography variant="subtitle1" display="block" style={{ margin: 0 }}>
                            {subHeading}
                        </Typography>
                    </Margin>
                )}
            </Grid>
        </Grid>
    );

    const renderHelp = () => (
        <IconButton size={'small'} style={{ width: '40px' }} onClick={() => setShowAbout(!showAbout)}>
            <HelpOutlineIcon style={{ fontSize: 15 }} />
        </IconButton>
    );

    const renderUserMenu = () => (
        <>
            <Button
                data-testid="header-user-menu-button"
                aria-controls="header-user-menu"
                aria-haspopup="true"
                onClick={handleOpen}
                style={{ margin: '0px' }}
            >
                <Typography variant="caption" style={{ marginBottom: 0, textTransform: 'none' }}>
                    {name}
                </Typography>
                <Margin left="15">
                    {!anchorEl && <ArrowDropDown fontSize="small" />}
                    {!!anchorEl && <ArrowDropUp fontSize="small" />}
                </Margin>
            </Button>
            <Menu
                id="header-user-menu"
                data-testid="header-user-menu"
                anchorEl={anchorEl}
                keepMounted
                disableAutoFocusItem={true}
                open={!!anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={() => history.push('/logout')}>Logout</MenuItem>
            </Menu>
        </>
    );

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                spacing={2}
                style={{ margin: 0, width: '100%', height: '100%' }}
            >
                <Grid xs={1}>
                    <LogoWrapper onClick={() => history.push('/home')}>
                        <Logo />
                    </LogoWrapper>
                </Grid>
                <Grid xs={10}>
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="space-between"
                        style={{ margin: 0, width: '100%' }}
                    >
                        <Grid xs={8}>{renderLabelAndAction()}</Grid>
                        <Grid xs={4}>
                            <MenuWrapper>
                                {renderUserMenu()}
                                {renderHelp()}
                            </MenuWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <LrAboutDialog show={showAbout} onClose={() => setShowAbout(false)} />
        </>
    );
};

Header.defaultProps = defaultProps;
export default withRouter(Header);
