import React from 'react';
import { Card, Typography, CardContent, CardActions } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import styles from './styles';
import LrButton from 'components/LrButton/';
import { ReactComponent as Logo } from 'assets/lumen_logo.svg';
import { withStyles } from '@mui/styles';

interface LoadingProps {
    classes: any;
}

const NotFound: React.FunctionComponent<LoadingProps> = ({ classes }) => (
    <Grid className={classes.root} container alignContent="center" alignItems="center" justifyContent="center">
        <Grid className={classes.content}>
            <Card className={classes.card}>
                <div className={classes.cardHeader}>
                    <Logo />
                </div>
                <CardContent className={classes.content}>
                    <Typography variant="h3">404</Typography>
                    <Typography variant="h5">Page Not Found</Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                    <LrButton lrType="primary" href="/auth">
                        Go Home
                    </LrButton>
                </CardActions>
            </Card>
        </Grid>
    </Grid>
);

export default withStyles(styles)(NotFound);
