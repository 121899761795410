import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Wrapper } from '../styled/Dropzone';
import LrButton from 'components/LrButton';
import { Spacing } from 'components/Layout';
import { useEffect } from 'react';

interface Props {
    acceptedFileTypes?: string[];
    caption?: string;
    height?: string;
    multiple?: boolean;
    nested?: boolean;
    minSize?: number;
    maxSize?: number;
    onFileDrop: (files: any[]) => void;
    isLoading?: boolean;
    startBrowse?: boolean;
}

const defaultProps = {
    multiple: true,
    nested: false,
    height: '100%',
    isLoading: false,
};

const LrDropzone: React.FunctionComponent<Props> = (props) => {
    const {
        acceptedFileTypes,
        caption,
        height,
        multiple,
        minSize,
        maxSize,
        nested,
        onFileDrop,
        isLoading,
        startBrowse,
    } = props;

    const { getRootProps, getInputProps, isDragActive, isDragReject, open } = useDropzone({
        onDrop: onFileDrop,
        accept: acceptedFileTypes,
        multiple,
        minSize,
        maxSize,
    });
    const getBodyText = () => (isDragActive ? 'Drop documents here' : 'Drop to upload documents');
    const getBodySize = () => (isDragActive ? 'h2' : 'body2');
    const getCaptionText = () => (isDragActive ? '' : caption || 'Any File Type');

    useEffect(() => {
        if (startBrowse) {
            open();
        }
    }, [startBrowse]);

    return (
        <Wrapper {...getRootProps()} isDragActive={isDragActive} nested={nested} height={height}>
            <Grid
                container
                direction="column"
                alignItems="center"
                alignContent="center"
                justifyContent="center"
                style={{ height: '100%' }}
            >
                <input {...getInputProps()} />
                <Typography variant={getBodySize()} align="center">
                    {getBodyText()}
                </Typography>
                <Typography variant="caption" align="center">
                    {getCaptionText()}
                </Typography>
                <Spacing height="8px" />
                {!isDragActive && (
                    <>
                        <LrButton lrType="primary" style={{ marginLeft: 0, maxWidth: '150px' }} isLoading={isLoading}>
                            Browse
                        </LrButton>
                    </>
                )}
                {isDragReject && <div>That file type is unsupported...</div>}
            </Grid>
        </Wrapper>
    );
};

LrDropzone.defaultProps = defaultProps;
export default React.memo(LrDropzone);
