import { combineReducers, AnyAction } from 'redux';
import { REDUX } from 'constants/index';
import { UnderwriterReview, UwSubjectivity } from '../../../types/underwriting.type';
import { UWReviewData } from '../store/UWReviewState';
import GLPropertyTableRowModel from '../../../components/Underwriting/components/GLPropertyTable/GLPropertyTableRowModel';

const initialState: UWReviewData = {
    propertyTableValues: [] as any[],
    underwriterReview: {} as UnderwriterReview,
    subjectivities: [],
    glPropertyTableValues: [] as GLPropertyTableRowModel[],
    glUnderwriterReview: {} as UnderwriterReview,
    glUWInitialized: false,
};

const data = (state = initialState, action: AnyAction): UWReviewData => {
    switch (action.type) {
        case `${REDUX.UW_REVIEW}/SUCCESS`: {
            const { property, underwriterReview } = action.payload;
            return {
                ...state,
                propertyTableValues: property,
                underwriterReview,
                subjectivities: underwriterReview.subjectivities.map((subjectivity: UwSubjectivity) => ({
                    ...subjectivity,
                    submissionId: underwriterReview.submissionId, // adding the submissionId so we can correctly display source if this is from an aggregated
                })),
            };
        }
        case `${REDUX.UW_REVIEW}/LOADING`:
        case `${REDUX.CLEAR_SELECTED_SUBMISSION}`:
        case `${REDUX.UW_REVIEW}/ERROR`: {
            return initialState;
        }
        case REDUX.UW_REVIEW_SAVE_ALL_PROPERTIES: {
            return {
                ...state,
                propertyTableValues: action.payload,
            };
        }
        case REDUX.UW_REVIEW_SAVE_ONE_PROPERTY: {
            const { data: d } = action.payload;
            const propertyTableValues = [...state.propertyTableValues];
            const index = propertyTableValues.findIndex((tv) => tv.rowId === d.rowId);
            if (index >= 0) {
                propertyTableValues[index] = d;
            }
            return {
                ...state,
                propertyTableValues,
            };
        }
        case REDUX.UW_REVIEW_SAVE_ALL_SUBJECTIVITIES: {
            return {
                ...state,
                subjectivities: action.payload,
            };
        }
        case REDUX.UW_REVIEW_SAVE_UW_REVIEW: {
            return {
                ...state,
                underwriterReview: action.payload,
            };
        }
        case REDUX.UW_REVIEW_SAVE_UW_GL_REVIEW: {
            return {
                ...state,
                glUnderwriterReview: action.payload,
            };
        }
        case REDUX.UW_REVIEW_SAVE_ALL_GL_PROPERTIES: {
            return {
                ...state,
                glPropertyTableValues: action.payload,
            };
        }
        case REDUX.UW_REVIEW_SAVE_ONE_GL_PROPERTY: {
            const { data: d } = action.payload;
            const glPropertyTableValues = [...state.glPropertyTableValues];
            const index = glPropertyTableValues.findIndex((tv) => tv.rowId === d.rowId);
            if (index >= 0) {
                glPropertyTableValues[index] = d;
            }
            return {
                ...state,
                glPropertyTableValues,
            };
        }
        case REDUX.UW_REVIW_GL_INITIALIZED: {
            return {
                ...state,
                glUWInitialized: action.payload,
            };
        }
        default:
            return state;
    }
};

function error(state: string = '', action: AnyAction): string {
    switch (action.type) {
        case `${REDUX.UW_REVIEW}/ERROR`: {
            return action.payload || 'Error Occurred';
        }
        case `${REDUX.UW_REVIEW}/LOADING`: {
            return '';
        }
        default:
            return state;
    }
}

function isLoading(state: boolean = false, action: AnyAction): boolean {
    switch (action.type) {
        case `${REDUX.UW_REVIEW}/LOADING`: {
            return true;
        }
        case `${REDUX.UW_REVIEW}/SUCCESS`:
        case `${REDUX.UW_REVIEW}/ERROR`: {
            return false;
        }
        default:
            return state;
    }
}

export default combineReducers({
    data,
    error,
    isLoading,
});
