import { Organization } from 'types/organization.type';
import { BaseActiveProducer } from 'helpers/ActiveUserProducer/BaseActiveProducer';
import { Link } from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react';

export class AgentActiveProducer extends BaseActiveProducer {
    public getIsAgent(): boolean {
        return true;
    }

    getSubHeader(assignedProducer: Organization, doSelectProducer: () => void): ReactElement | string {
        // either we don't have an assignedProducer context or the current user is assigned
        if (!assignedProducer || this.getIsActiveProducerAssigned(assignedProducer)) {
            if (this.availableProducers.length > 1) {
                return (
                    <>
                        Producer Context:{' '}
                        <Link
                            href="#"
                            onClick={() => {
                                doSelectProducer();
                            }}
                            underline="hover"
                        >
                            {this.activeProducer?.name || ''}
                        </Link>
                    </>
                );
            } else {
                return `Producer Context: ${this.activeProducer?.name || ''}`; // there aren't any other producers to select from
            }
        } else {
            // we have an assignedProducer context, but it isn't the active user, this is the base case that non-agents also use
            return super.getSubHeader(assignedProducer, doSelectProducer);
        }
    }

    validateAssignedProducer(assignedProducer: Organization): Organization | undefined {
        if (assignedProducer) {
            const foundProducer = this.availableProducers.find((p) => p.id === assignedProducer.id);
            if (foundProducer) {
                return foundProducer;
            }
        }
        return this.activeProducer;
    }

    getTemplateQualifier(fileType: string, availableQualifiers: string[] | undefined): string {
        const noTaxTypes = [
            'QUOTE',
            'BINDER',
            'INVOICE',
            'POLICY',
            'ENDORSEMENT_AJ',
            'ENDORSEMENT_RL',
            'ENDORSEMENT_CA',
            'ENDORSEMENT_AL',
        ];
        if (noTaxTypes.includes(fileType)) {
            return this.activeProducer.isFileTaxes &&
                !!availableQualifiers?.length &&
                availableQualifiers.includes('NO_TAX')
                ? 'NO_TAX'
                : '';
        }
        return super.getTemplateQualifier(fileType, availableQualifiers);
    }
}
