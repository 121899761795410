import * as React from 'react';
import format from 'date-fns/format';
import {
    forwardRef,
    RefForwardingComponent,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import {
    Widget,
    addResponseMessage,
    addUserMessage,
    dropMessages,
    toggleWidget,
    setQuickButtons,
} from '@lumen/react-chat-widget';

import '@lumen/react-chat-widget/lib/styles.css';
import { MessagingChannelMessage } from '../../../types/messaging.type';
import logo from 'assets/lumen_logo.svg';
import MessagingClientLauncher from './MessagingClientLauncher';
import { MessagingClientStyleWrapper } from './MessagingClientStyleWrapper';
import { Backdrop } from '@mui/material';
import LrDownloadButton, { LrDownloadButtonAPI } from '../../LrDownloadButton';

interface MessagingClientProps {
    onMessageEntered: (msg: string) => void;
    transcriptHeaderText?: string;
    messages: MessagingChannelMessage[];
    isLumen: boolean;
}

export interface MessagingClientAPI {
    toggleShowClient: () => void;
}

const MessagingClient: RefForwardingComponent<MessagingClientAPI, MessagingClientProps> = (props, ref) => {
    const { onMessageEntered, messages, isLumen, transcriptHeaderText } = { ...props };
    const [showChat, setShowChat] = useState<boolean>(false);
    const downloadButtonApi = useRef<LrDownloadButtonAPI>(null);
    useEffect(() => {
        dropMessages();
        loadCurrentMessages();
        return () => dropMessages();
    }, [messages]);

    useEffect(() => {
        setQuickButtons([{ label: 'Download Transcript', value: 'download' }]);
    }, []);

    const getCustomLauncher = (handleToggle: any) => {
        return (
            <MessagingClientLauncher
                showChat={showChat}
                toggle={() => {
                    handleToggle();
                }}
            />
        );
    };

    const dataFetchFn = useCallback(() => {
        return new Promise<any>((resolve) => {
            const ret = buildMessageTranscript();
            resolve({ body: ret });
        });
    }, []);

    const getEmptyLauncher = () => {
        return <></>;
    };

    const loadCurrentMessages = () => {
        messages.forEach((message) => {
            if (message.isLumen !== isLumen) {
                if (message.id && message.timestamp) {
                    addResponseMessage(message.message, message.id, new Date(message.timestamp));
                } else {
                    addResponseMessage(message.message);
                }
            } else {
                if (message.id && message.timestamp) {
                    addUserMessage(message.message, message.id, new Date(message.timestamp));
                } else {
                    addUserMessage(message.message);
                }
            }
        });
    };

    const handleNewUserMessage = (newMessage: string) => {
        onMessageEntered && onMessageEntered(newMessage);
    };

    const handleQuickButton = (buttonValue: string) => {
        if (buttonValue === 'download') {
            downloadButtonApi?.current?.doDownload();
        }
    };

    const buildMessageTranscript = () => {
        let transcriptText = 'Lumen Risk Kurrent Message Transcript';
        if (transcriptHeaderText) {
            transcriptText += `: ${transcriptHeaderText}`;
        }
        transcriptText += '\n\n';
        messages.forEach((message) => {
            const timestampDate = message.timestamp ? new Date(message.timestamp) : null;
            let timestampString = '';
            if (timestampDate) {
                timestampString = format(timestampDate, 'Pp');
            }

            transcriptText += `${timestampString ? timestampString + ' - ' : ''}${
                message.isLumen ? 'LUMEN:' : 'AGENT:'
            } ${message.message}\n`;
        });
        return transcriptText;
    };

    useImperativeHandle(ref, () => ({
        toggleShowClient: () => {
            toggleWidget();
        },
    }));

    return (
        <>
            <Backdrop
                open={showChat}
                style={{ zIndex: 10 }}
                onClick={() => {
                    toggleWidget();
                }}
            >
                <MessagingClientStyleWrapper onClick={(event) => event.stopPropagation()}>
                    <Widget
                        handleShowChat={(isShowChat: boolean) => {
                            setShowChat(isShowChat);
                        }}
                        handleNewUserMessage={handleNewUserMessage}
                        title="Submission Messages"
                        subtitle="Leave your Underwriter a Message"
                        profileAvatar={!isLumen ? logo : undefined}
                        showTimeStamp={true}
                        launcher={(handleToggle: any) => getEmptyLauncher()}
                        handleQuickButtonClicked={handleQuickButton}
                    />
                </MessagingClientStyleWrapper>
            </Backdrop>
            <LrDownloadButton
                fileName={`Lumen Messages Transcript ${transcriptHeaderText} ${format(new Date(), 'P')}.txt`}
                ref={downloadButtonApi}
                hide={true}
                dataFetchFn={dataFetchFn}
            />
        </>
    );
};

const ForwardedMessaginClient = forwardRef(MessagingClient);
export default React.memo(ForwardedMessaginClient);
