interface Action {
    type: string;
    payload?: any;
    error?: string;
}

interface ActionCreator {
    loading: () => Action;
    success: (data?: any) => Action;
    error: (error: string) => Action;
}

const actionCreator = (constant: string): ActionCreator => ({
    loading: () => ({ type: `${constant}/LOADING` }),

    success: (data) => ({ type: `${constant}/SUCCESS`, payload: data }),

    error: (error) => ({
        type: `${constant}/ERROR`,
        payload: error || 'There was an unknown error.',
    }),
});

export default actionCreator;
