import moment from 'moment';
import { RowNode } from 'ag-grid-community/dist/lib/entities/rowNode';

export const numberSort = (num1: number, num2: number) => {
    return num1 - num2;
};

export const dateSort = (filterLocalDateAtMidnight: Date, value: string) => {
    const localMidnight = moment(filterLocalDateAtMidnight);
    const cellDate = moment(value);

    if (cellDate.isBefore(localMidnight)) {
        return -1;
    } else if (cellDate.isAfter(localMidnight)) {
        return 1;
    } else {
        return 0;
    }
};

export const nullEmptyBottomSort = (a: any, b: any, nodeA: RowNode, nodeB: RowNode, isInverted: boolean) => {
    if (a === b) {
        return 0;
    }
    // for null
    else if (!a) {
        return isInverted ? -1 : 1;
    } else if (!b) {
        return isInverted ? 1 : -1;
    } else {
        return a.localeCompare(b);
    }
};
