import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import styles from './styles';
import LumenLoader from 'components/Loader';
import { withStyles } from '@mui/styles';

interface LoadingProps {
    classes: any;
}

const Loading: React.FunctionComponent<LoadingProps> = ({ classes }) => (
    <Grid className={classes.root} container alignContent="center" alignItems="center" justifyContent="center">
        <Grid className={classes.content}>
            <LumenLoader />
        </Grid>
    </Grid>
);

export default withStyles(styles)(Loading);
