import * as React from 'react';

import { useSelector } from 'react-redux';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import Loading from 'views/Loading';
import { Suspense } from 'react';

import { getTopLevelUserGroup } from 'application/redux/selectors';

export interface PermissionRouteProps extends RouteProps {
    permissions: string[];
    customRender?: (props: RouteProps) => React.ReactNode;
}

const PermissionRoute: React.FunctionComponent<PermissionRouteProps> = (props) => {
    const { component: Component, customRender, permissions, ...restOfProps } = props;

    // Global State
    const group = useSelector(getTopLevelUserGroup);

    const renderRoute = (routeProps: RouteProps) =>
        // prettier-ignore
        {
        if (permissions.includes(group)) {
            if (customRender) {
                return customRender(routeProps)
            } else {
                // @ts-ignore
                return (<Suspense fallback={<Loading/>}><Component {...routeProps} /></Suspense>)
            }
        }
        else {
            return <Redirect to="/auth"/>;
        }
    };

    return <Route exact {...restOfProps} render={renderRoute} />;
};

export default PermissionRoute;
