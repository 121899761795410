import { combineReducers, AnyAction } from 'redux';
import { REDUX } from 'constants/index';
import { LocationOptionsData } from 'types/types.type';

export const initialState: LocationOptionsData = {
    options: {},
};

const data = (state = initialState, action: AnyAction): LocationOptionsData => {
    switch (action.type) {
        case `${REDUX.LOCATION_OPTIONS}/SUCCESS`: {
            return action.payload || state;
        }
        case `${REDUX.LOCATION_OPTIONS}/LOADING`:
        case `${REDUX.LOCATION_OPTIONS}/ERROR`: {
            return initialState;
        }
        default:
            return state;
    }
};

function error(state: string = '', action: AnyAction): string {
    switch (action.type) {
        case `${REDUX.LOCATION_OPTIONS}/ERROR`: {
            return action.payload || '';
        }
        case `${REDUX.LOCATION_OPTIONS}/LOADING`: {
            return '';
        }
        default:
            return state;
    }
}

function isLoading(state: boolean = false, action: AnyAction): boolean {
    switch (action.type) {
        case `${REDUX.LOCATION_OPTIONS}/LOADING`: {
            return true;
        }
        case `${REDUX.LOCATION_OPTIONS}/SUCCESS`:
        case `${REDUX.LOCATION_OPTIONS}/ERROR`: {
            return false;
        }
        default:
            return state;
    }
}

function hasResolved(state: boolean = false, action: AnyAction): boolean {
    switch (action.type) {
        case `${REDUX.LOCATION_OPTIONS}/ERROR`:
        case `${REDUX.LOCATION_OPTIONS}/LOADING`: {
            return false;
        }
        case `${REDUX.LOCATION_OPTIONS}/SUCCESS`: {
            return true;
        }
        default:
            return state;
    }
}

export default combineReducers({
    data,
    error,
    isLoading,
    hasResolved,
});
