import { combineReducers, AnyAction } from 'redux';
import { GRID_DATA_TYPES } from 'constants/index';
import { persist } from './persist';

const initialState = null;

// Exported for testing reusability
export const initialGridState = {
    columns: initialState,
    filters: initialState,
    error: '',
};

const persistWhitelist = ['columns'];

/* Note:
    data and error are not normal reducers.
    these are to be used to generate reusable reducers,
    so they take another param to dynamically create the same reducer for different actions

*/
const data = (tableName: string, dataType: string) => (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case `${tableName}/${dataType}`: {
            return action.payload;
        }
        case `${tableName}/ERROR`: {
            return initialState;
        }
        default:
            return state;
    }
};

const error = (tableName: string) => (state: string = '', action: AnyAction) => {
    switch (action.type) {
        case `${tableName}/ERROR`: {
            return action.payload;
        }
        case `${tableName}/${GRID_DATA_TYPES.COLUMNS}`:
        case `${tableName}/${GRID_DATA_TYPES.FILTERS}`: {
            return '';
        }
        default:
            return state;
    }
};

export const getGridReducer = (tableName: string, persistKey: string) => {
    const reducers = combineReducers({
        columns: data(tableName, GRID_DATA_TYPES.COLUMNS),
        filters: data(tableName, GRID_DATA_TYPES.FILTERS),
        error: error(tableName),
    });
    return persist(persistKey, persistWhitelist, reducers);
};
