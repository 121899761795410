import * as React from 'react';
import { models, Report, Embed, service, Page } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useCallback, useEffect, useState } from 'react';
import { fetchPowerBIEmbedInfo } from '../../../services/reporting';
import { makeStyles } from '@mui/styles';
import { PowerBIEmbedInfo } from '../../../types/reporting.type';
import { useSelector } from 'react-redux';
import {
    getReportingLocations,
    getReportingPrimaryLoaded,
    getReportingSelectedReport,
} from '../../../application/redux/selectors';
import LumenLoader from '../../Loader';
import { useActions } from '../../../hooks/useActions';
import * as Actions from '../../../application/redux/actions';

interface Props {
    reportId: string;
    workspaceId: string;
    reportName?: string;
    hideFilters?: boolean;
    height?: string;
    pageName?: string;
    hidePageNav?: boolean;
    hideReportBorder?: boolean;
    isPrimaryReport?: boolean;
    waitForPrimary?: boolean;
    disableBootstrap?: boolean;
}

const EmbeddedPowerBIReport: React.FunctionComponent<Props> = (props) => {
    const {
        reportId,
        workspaceId,
        reportName,
        height,
        hideFilters,
        pageName,
        hidePageNav,
        hideReportBorder,
        isPrimaryReport,
        waitForPrimary,
        disableBootstrap,
    } = props;

    const { setReportingPrimaryLoaded } = useActions(Actions);
    const isPrimaryLoaded = useSelector(getReportingPrimaryLoaded);
    const selectedReport = useSelector(getReportingSelectedReport);
    // PowerBI Report object (to be received via callback)
    const [report, setReport] = useState<Report>();
    const [tokenExpiration, setTokenExpiration] = useState<string>();

    // Report config useState hook
    // Values for properties like embedUrl, accessToken and settings will be set on click of buttons below
    const [reportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
        type: 'report',
        embedUrl: undefined,
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        permissions: 0,
        pageName: !!pageName ? encodeURIComponent(pageName) : undefined,
        settings: {
            panes: {
                pageNavigation: {
                    visible: !hidePageNav,
                    position: models.PageNavigationPosition.Left,
                },
                filters: {
                    visible: !hideFilters,
                    expanded: false,
                },
            },
        },
    });

    const useHeight = height || '80vh';

    const handleRenderedEvent = useCallback(
        (event: any) => {
            console.log((reportName ? reportName : reportId) + ' Report has rendered');
            if (isPrimaryReport) {
                setReportingPrimaryLoaded(true);
            }
        },
        [report]
    );

    // Map of event handlers to be applied to the embedding report
    const eventHandlersMap = new Map([
        [
            'loaded',
            () => {
                console.log(reportId + ' Report has loaded');
            },
        ],
        ['rendered', handleRenderedEvent],
        [
            'error',
            (event?: service.ICustomEvent<any>) => {
                if (event) {
                    console.error(event.detail);
                }
            },
        ],
        [
            'pageChanged',
            (event: any) => {
                if (event) console.log(event.detail.newPage);
            },
        ],
    ]);

    // Fetch sample report's config (eg. embedUrl and AccessToken) for embedding
    const fetchEmbedInfoToken = async () => {
        // Fetch sample report's embed config
        const reportInfo = await getNewAccessToken();

        const foundReport = reportInfo.embedReports.find((r) => r.id === reportId);
        if (foundReport) {
            // Set the fetched embedUrl and embedToken in the report config
            setReportConfig({
                ...reportConfig,
                embedUrl: foundReport.embedUrl,
                accessToken: reportInfo.embedToken.token,
            });
        }
    };

    const getNewAccessToken: () => Promise<PowerBIEmbedInfo> = async () => {
        const reportInfo = await fetchPowerBIEmbedInfo(reportId, workspaceId);
        // Update the new token expiration time
        setTokenExpiration(reportInfo.embedToken.expiration);
        return reportInfo;
    };

    const checkTokenAndUpdate = () => {
        // Get the current time
        const currentTime = Date.now();
        const expiration = Date.parse(tokenExpiration || '');

        // Time until token expiration in milliseconds
        const timeUntilExpiration = expiration - currentTime;
        const timeToUpdate = 10 * 60 * 1000; // we want to wait until 10 mins before the token expires in order to update

        // Update the token if it is about to expired
        if (timeUntilExpiration <= timeToUpdate) {
            updateToken();
        }
    };

    const updateToken = async () => {
        // Generate a new embed token or refresh the user Azure AD access token
        const newAccessToken = await getNewAccessToken();

        if (report) {
            // Set the new access token
            await report.setAccessToken(newAccessToken.embedToken.token);
        }
    };

    useEffect(() => {
        fetchEmbedInfoToken();
    }, []);

    useEffect(() => {
        // Set the refresh interval time to 20 seconds
        const timerRefresh = setInterval(() => {
            checkTokenAndUpdate();
        }, 20000);
        // clearing interval
        return () => {
            clearInterval(timerRefresh);
        };
    }, [tokenExpiration]);

    const classes = makeStyles({
        report: {
            height: useHeight,
            overflow: 'hidden',
            ...(hideReportBorder ? { '& iframe': { border: 'none' } } : {}),
        },
    })();

    if (!isPrimaryLoaded && waitForPrimary && selectedReport !== reportName) {
        return <LumenLoader />;
    }

    if (disableBootstrap && !reportConfig.embedUrl) {
        return <LumenLoader />;
    }

    return (
        <>
            <PowerBIEmbed
                cssClassName={classes.report}
                embedConfig={reportConfig}
                getEmbeddedComponent={(embedObject: Embed) => {
                    setReport(embedObject as Report);
                }}
                eventHandlers={eventHandlersMap}
            />
        </>
    );
};

export default EmbeddedPowerBIReport;
