import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect, Route } from 'react-router';
import OktaSignInWidget from './components/OktaSignInWidget';
import { AuthState, OAuthProvider } from '../../application/Providers/Authentication/OAuthProvider';
import { Spacing } from '../../components/Layout';
import { useLocation } from 'react-router-dom';

interface Props {
    authState: AuthState;
    authService: OAuthProvider;
}

interface State {
    baseUrl: string | undefined;
}

const Login: React.FC = (props) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [baseUrl, setBaseUrl] = useState<string | undefined>(process.env.REACT_APP_OKTA_BASE);
    const useQuery = () => new URLSearchParams(useLocation().search);

    const query = useQuery();
    const referer = query.get('referer');

    const onSuccess = (res: any) => {
        return oktaAuth.handleLoginRedirect(res, referer || '');
    };

    const onError = () => {
        // TODO what to do on login error
    };

    return (
        <>
            {authState?.isAuthenticated ? (
                <Route render={() => <Redirect to={{ pathname: referer || '/' }} />} />
            ) : (
                // this is pulling from the Okta signing page background for Lumen okta...
                // TODO pull this image into the app and use directly
                <div
                    style={{
                        background: "url('https://ok7static.oktacdn.com/fs/bco/7/fs0vfrn9c1i7rZCki356')",
                        backgroundSize: 'cover',
                        backgroundPosition: '50%',
                        position: 'absolute',
                        height: '100vh',
                        width: '100%',
                    }}
                >
                    <Spacing height={'24px'} />
                    <OktaSignInWidget baseUrl={baseUrl || ''} onSuccess={onSuccess} onError={onError} />
                </div>
            )}
        </>
    );
};

export default Login;
