import * as Yup from 'yup';

const lossSchema = (showNote: boolean, isClaim: boolean) =>
    Yup.object().shape({
        locationId: Yup.string().required('Required'),
        date: Yup.string().min(10, 'Must be a valid date').max(10, 'Must be a valid date').required('Required'),
        perilType: Yup.string().required('Required'),
        productLineCode: Yup.string().required('Required'),
        status: isClaim ? Yup.string() : Yup.string().required('Required'),
        paid: isClaim ? Yup.string() : Yup.string().required('Required'),
        reserved: isClaim
            ? Yup.string()
            : Yup.string().when('status', { is: '0', then: Yup.string().required('Required') }),
        description: Yup.string().required('Required'),
        note: Yup.string().test(
            'note-length',
            'Please enter a detailed reason',
            (value) => !showNote || (value && value.length > 10) || false
        ),
    });

export default lossSchema;
