import { SubmissionDetails } from 'types/submission.type';
import { SUBMISSION_TYPES, ENDORSEMENT_TYPES } from 'constants/types';

export const isEndorsementWithoutUnderwriting = (submission: SubmissionDetails): boolean => {
    if (submission && submission.isEndorsement && submission.endorsementType) {
        return (
            submission.endorsementType.code === ENDORSEMENT_TYPES.NON_MONIED ||
            submission.endorsementType.code === ENDORSEMENT_TYPES.MONIED_LOCATION_REMOVAL ||
            submission.endorsementType.code === ENDORSEMENT_TYPES.MONIED_ADJUSTMENT ||
            submission.endorsementType.code === ENDORSEMENT_TYPES.CANCELLATION ||
            submission.endorsementType.code === ENDORSEMENT_TYPES.EXTENSION
        );
    }
    return false;
};

export const isNonMoniedEndorsement = (submission: SubmissionDetails): boolean => {
    if (submission.isEndorsement && submission.endorsementType) {
        return submission.endorsementType.code === 'NON_MONIED';
    }
    return false;
};

export const getSubmissionType = (submission: SubmissionDetails): string => {
    const { endorsementType } = submission;
    if (!endorsementType) return SUBMISSION_TYPES.SUBMISSION;
    if (endorsementType.code === ENDORSEMENT_TYPES.MONIED_LOCATION_ADDITION) return SUBMISSION_TYPES.ENDORSEMENT_ADD;
    if (endorsementType.code === ENDORSEMENT_TYPES.MONIED_LOCATION_REMOVAL) return SUBMISSION_TYPES.ENDORSEMENT_REMOVE;
    if (endorsementType.code === ENDORSEMENT_TYPES.NON_MONIED) return SUBMISSION_TYPES.ENDORSEMENT_INFO;
    if (endorsementType.code === ENDORSEMENT_TYPES.MONIED_ADJUSTMENT) return SUBMISSION_TYPES.ENDORSEMENT_ADJUSTMENT;
    if (endorsementType.code === ENDORSEMENT_TYPES.CANCELLATION) return SUBMISSION_TYPES.ENDORSEMENT_CANCELLATION;
    if (endorsementType.code === ENDORSEMENT_TYPES.EXTENSION) return SUBMISSION_TYPES.ENDORSEMENT_ADJUSTMENT; // extension is effectively an adjustment endorsement
    return SUBMISSION_TYPES.SUBMISSION;
};

export const isLocationAddEndorsement = (submission: SubmissionDetails): boolean => {
    return (
        submission &&
        submission.isEndorsement &&
        submission.endorsementType?.code === ENDORSEMENT_TYPES.MONIED_LOCATION_ADDITION
    );
};
export const isLocationRemoveEndorsement = (submission: SubmissionDetails): boolean => {
    return (
        submission &&
        submission.isEndorsement &&
        (submission.endorsementType?.code === ENDORSEMENT_TYPES.CANCELLATION ||
            submission.endorsementType?.code === ENDORSEMENT_TYPES.MONIED_LOCATION_REMOVAL)
    );
};
