import * as React from 'react';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import NavItem from './NavItem';
import { SubmissionDetails } from 'types/submission.type';
import { Badge, IconButton } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { useSelector } from 'react-redux';
import { getTopLevelUserGroup } from '../../../application/redux/selectors';
import { getIsAdminRole } from '../../../helpers/roles';
import LrDialog from '../../LrDialog';
import { fetchUwAlert, updateUwAlert } from '../../../services/app';
import { TextInput } from '../../Input';
import ScheduleIcon from '@mui/icons-material/Schedule';
import moment from 'moment';

interface UnderwriterAlertsInfoProps {
    submission?: SubmissionDetails;
}

const UnderwriterAlertsInfo: FunctionComponent<UnderwriterAlertsInfoProps> = (props) => {
    const { submission } = { ...props };
    const [isNewAlert, setIsNewAlert] = useState<boolean>(false);
    const [showUwAlerts, setShowUwAlerts] = useState<boolean>(false);
    const [uwAlert, setUwAlert] = useState<string>('');
    const group = useSelector(getTopLevelUserGroup);
    const isAdmin = getIsAdminRole(group);

    const fetchAlert = async () => {
        const response = await fetchUwAlert();
        if (response && !!response.userAlertIsNew) {
            setIsNewAlert(true);
        }
        if (response && response.uwAlert) {
            setUwAlert(response.uwAlert);
        }
    };

    const saveUwAlert = async () => {
        const response = await updateUwAlert(uwAlert, true);
        setShowUwAlerts(false);
    };

    const handleLinkClick = () => {
        setShowUwAlerts(true);
        setIsNewAlert(false);
        updateUwAlert(undefined, false);
    };

    const handleAddDateTime = () => {
        if (isAdmin) {
            const newUwAlert = uwAlert + (!!uwAlert ? '\n\n' : '') + moment().format('llll') + ' - ';
            setUwAlert(newUwAlert);
        }
    };

    useEffect(() => {
        fetchAlert();
    }, []);
    const getIconComponent = () => (
        <>
            <Badge variant="dot" overlap={'circular'} color={'secondary'} invisible={!isNewAlert}>
                <NewReleasesIcon htmlColor={!isNewAlert ? undefined : '#54565A'} />
            </Badge>
        </>
    );

    return (
        <>
            <NavItem onClick={handleLinkClick} icon={getIconComponent()}>
                {!isNewAlert ? 'UW Alerts' : <b>UW Alerts</b>}
            </NavItem>
            <LrDialog
                isOpen={showUwAlerts}
                title={'Underwriter Alerts'}
                width="800px"
                onCancel={() => setShowUwAlerts(false)}
                onSubmit={isAdmin ? saveUwAlert : () => setShowUwAlerts(false)}
                submitButtonText={isAdmin ? 'Save' : 'Ok'}
                closeButton
            >
                <div style={{ minHeight: '400px' }}>
                    <TextInput
                        rows={25}
                        multiline
                        fullheight
                        value={uwAlert}
                        readOnly={!isAdmin}
                        onChange={(e) => setUwAlert(e.target.value)}
                    />
                </div>
                {isAdmin && (
                    <IconButton onClick={handleAddDateTime} size="large">
                        <ScheduleIcon />
                    </IconButton>
                )}
            </LrDialog>
        </>
    );
};

export default UnderwriterAlertsInfo;
