import { combineReducers, AnyAction } from 'redux';
import { REDUX } from 'constants/index';
import { SubmissionDetails, SubmissionInspectionData, SubmissionUnderwritingStatus } from 'types/submission.type';
import { Organization } from 'types/organization.type';
import { Document2Post } from 'types/document.type';

const initialState = {
    id: '',
    effectiveDate: '',
    namedInsured: '',
    statusTypeId: '',
    statusType: {
        id: '',
        displayValue: '',
        code: '',
    },
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    narrative: '',
    producer: {} as Organization,
    boundDocuments: [],
    lossDocuments: {} as Map<string, Document2Post[]>,
    documents: [],
    locations: [],
    transactionEntries: [],
    losses: [],
    isEndorsement: false,
    isRemovalEndorsement: false,
    endorsementTypeId: '',
    endorsingSubmissionId: '',
    isInForce: false,
    isBound: false,
    isExpiring: false,
    isExpired: false,
    isPolicy: false,
    isRenewal: false,
    effectivePolicyNumber: '',
    hasAgentMessages: false,
    hasLumenMessages: false,
    hasAllApprovedReferredLocations: true,
    uwStatus: {} as SubmissionUnderwritingStatus,
    inspectionData: {} as SubmissionInspectionData,
    underwritingNotes: '',
    primaryLimit: '',
    policies: [],
};

function data(state: SubmissionDetails = initialState, action: AnyAction): SubmissionDetails {
    switch (action.type) {
        // TODO: use this details object for all "selected submissions" instead of "selected" in submissions store
        case `${REDUX.ENDORSEMENT_CONTEXT_SAVE}/SUCCESS`: {
            return action.payload;
        }

        case `${REDUX.ENDORSEMENT_CONTEXT_CLEAR}`:
        case `${REDUX.ENDORSEMENT_CONTEXT_SAVE}/ERROR`: {
            return initialState;
        }

        default:
            return state;
    }
}

function error(state: string = '', action: AnyAction): string {
    switch (action.type) {
        case `${REDUX.ENDORSEMENT_CONTEXT_SAVE}/ERROR`: {
            return action.payload;
        }
        case `${REDUX.ENDORSEMENT_CONTEXT_SAVE}/LOADING`: {
            return '';
        }
        default:
            return state;
    }
}

function isLoading(state: boolean = false, action: AnyAction): boolean {
    switch (action.type) {
        case `${REDUX.ENDORSEMENT_CONTEXT_SAVE}/LOADING`: {
            return true;
        }
        case `${REDUX.ENDORSEMENT_CONTEXT_SAVE}/SUCCESS`:
        case `${REDUX.ENDORSEMENT_CONTEXT_CLEAR}`:
        case `${REDUX.ENDORSEMENT_CONTEXT_SAVE}/ERROR`: {
            return false;
        }
        default:
            return state;
    }
}

function hasResolved(state: boolean = false, action: AnyAction): boolean {
    switch (action.type) {
        case `${REDUX.ENDORSEMENT_CONTEXT_SAVE}/LOADING`:
            return false;
        case `${REDUX.ENDORSEMENT_CONTEXT_SAVE}/SUCCESS`:
        case `${REDUX.ENDORSEMENT_CONTEXT_CLEAR}`:
        case `${REDUX.ENDORSEMENT_CONTEXT_SAVE}/ERROR`: {
            return true;
        }
        default:
            return state;
    }
}

export default combineReducers({
    data,
    error,
    isLoading,
    hasResolved,
});
