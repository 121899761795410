import * as React from 'react';
// import { logout as oktaLogout } from 'application/Providers/Authentication';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { ROUTES } from 'constants/index';
import Loading from 'views/Loading';
import { useOktaAuth } from '@okta/okta-react';

interface LogoutProps extends RouteComponentProps {
    logoutSuccess: () => void;
    delayMs?: number;
}

const defaultProps = {
    delayMs: 100,
};

const Logout: React.FunctionComponent<LogoutProps> = (props) => {
    const { logoutSuccess, delayMs } = props;
    const [isLoggingOut, setIsLoggingOut] = React.useState(true);
    const { oktaAuth } = useOktaAuth();

    const logoutOfEverything = async () => {
        logoutSuccess && logoutSuccess();
        await oktaAuth
            .signOut({
                revokeAccessToken: false,
                postLogoutRedirectUri: window.location.origin + ROUTES.login(),
            })
            .catch((reason: any) => {
                if (reason.name === 'AuthApiError') {
                    // we are probably already logged out but we need to manually redirect
                    setIsLoggingOut(false);

                    return;
                }
            });
    };
    const completeLogout = () => {
        const timeout = setTimeout(() => logoutOfEverything(), delayMs);
        return () => clearTimeout(timeout);
    };
    React.useEffect(completeLogout, []);

    return isLoggingOut ? <Loading /> : <Redirect to={ROUTES.login()} />;
};

Logout.defaultProps = defaultProps;
export default withRouter(Logout);
