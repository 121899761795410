import { Organization } from 'types/organization.type';

export const EmptySubmissionUserPreferenceStateUserData: SubmissionUserPreferenceStateUserData = {
    assignedProducer: {} as Organization,
};
export interface SubmissionUserPreferenceStateUserData {
    assignedProducer: Organization;
}

export interface SubmissionUserPreferenceStateData {
    [key: string]: SubmissionUserPreferenceStateUserData;
}

export default interface SubmissionUserPreferenceState {
    data: SubmissionUserPreferenceStateData;
}
