export const DIALOG_NAMES = {
    ADJUSTMENT: 'ADJUSTMENT',
    EDIT: 'EDIT',
    NONE: 'NONE',
    REMOVAL: 'REMOVAL',
    CANCEL: 'CANCEL',
    VOID: 'VOID',
    RESTORE: 'RESTORE',
    RENEW: 'RENEW',
    NOTICES: 'NOTICES',
    EXTEND: 'EXTEND',
};
