import { LogCreateOptions, LogFetchOptions } from '../types/logs.types';
import { getHeaders, getQueryString, SubmissionURL } from './config';
import { checkStatus, getData } from './normalizer';

export async function fetchUwAlert() {
    const url = `${SubmissionURL}/app/uwAlert`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus).then(getData);
}

export async function updateUwAlert(newAlert: string | undefined, userAlertIsNew: boolean) {
    const payload: { uwAlert?: string; userAlertIsNew: boolean } = { userAlertIsNew };
    if (!!newAlert) {
        payload.uwAlert = newAlert;
    }
    const url = `${SubmissionURL}/app/uwAlert`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };
    return fetch(url, options).then(checkStatus).then(getData);
}
