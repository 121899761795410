import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import reducers from '../reducers';

const middlewares: any[] = [thunk];

const composedMiddleware = composeWithDevTools(applyMiddleware(...middlewares));

export const store = createStore(reducers, composedMiddleware);
export const persistor = persistStore(store);
