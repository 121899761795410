import { combineReducers, AnyAction } from 'redux';
import { REDUX } from 'constants/index';
import { CarrierTreatyYear } from '../../../types/carrier.types';

const initialCTYState: CarrierTreatyYear[] = [];

const currentTreaties = (state = initialCTYState, action: AnyAction): CarrierTreatyYear[] => {
    switch (action.type) {
        case `${REDUX.BUSINESS_DATA_CURRENT_TREATIES}/SUCCESS`: {
            return action.payload;
        }
        case `${REDUX.BUSINESS_DATA_CURRENT_TREATIES}/LOADING`: {
            return [];
        }
        case `${REDUX.BUSINESS_DATA_CURRENT_TREATIES}/ERROR`: {
            return [];
        }
        default:
            return state;
    }
};

const allTreaties = (state = initialCTYState, action: AnyAction): CarrierTreatyYear[] => {
    switch (action.type) {
        case `${REDUX.BUSINESS_DATA_ALL_TREATIES}/SUCCESS`: {
            return action.payload;
        }
        case `${REDUX.BUSINESS_DATA_ALL_TREATIES}/LOADING`: {
            return [];
        }
        case `${REDUX.BUSINESS_DATA_ALL_TREATIES}/ERROR`: {
            return [];
        }
        default:
            return state;
    }
};

export default combineReducers({
    currentTreaties,
    allTreaties,
});
