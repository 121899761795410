import { REDUX, REDUX_PERSIST } from 'constants/index';
import { AnyAction, combineReducers } from 'redux';
import { SubmissionUserPreferenceStateUserData } from '../store/SubmissionUserPreferenceState';
import { persist } from '../lib/persist';

const initialState = {} as SubmissionUserPreferenceStateUserData;
const persistWhitelist = ['data'];

const data = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case `${REDUX.SUBMISSION_USER_PREFERENCES}/SUCCESS`: {
            return Object.keys(action.payload).reduce(
                (r, s) => {
                    if (s in r) {
                        r[s] = { ...r[s], ...action.payload[s] };
                    } else {
                        r[s] = action.payload[s];
                    }

                    return r;
                },
                { ...state }
            );
        }
        case `${REDUX.SUBMISSION_USER_PREFERENCES}/LOADING`:
        case `${REDUX.SUBMISSION_USER_PREFERENCES}/ERROR`: {
            return initialState;
        }
        default:
            return state;
    }
};

export default persist(REDUX_PERSIST.SUBMISSION_USER_PREFERENCES, persistWhitelist, combineReducers({ data }));
