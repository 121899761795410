import { Dispatch } from 'redux';
import actionCreator from '../lib/actionCreator';
import { REDUX } from '../../../constants';
import { fetchSubmissionReferralMetadata } from '../../../services/referrals';

export const fetchReferralMetadataAction = actionCreator(REDUX.FETCH_REFERRAL_METADATA);

export function fetchReferralMetaData(id: string) {
    return async (dispatch: Dispatch) => {
        dispatch(fetchReferralMetadataAction.loading());

        fetchSubmissionReferralMetadata(id)
            .then((referralMetaData) => {
                dispatch(fetchReferralMetadataAction.success(referralMetaData));
            })
            .catch((reason) => {
                dispatch(fetchReferralMetadataAction.error(reason));
            });
    };
}
