import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { COLORS } from 'constants/styles';

interface NavLinkProps {
    $nested: boolean;
    $step: boolean;
    $disabled: boolean;
}

interface NavStepProps {
    isComplete: boolean;
}

interface NavActionProps {
    $disabled: boolean;
}

const StyledNavBase = css`
    display: flex;
    text-decoration: none;
    height: 32px;
    width: 100%;
    color: ${COLORS.GRAY_MED};
    &:hover {
        cursor: pointer;
    }
`;

export const NavStep = styled.div<NavStepProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid ${COLORS.GRAY_LIGHT};
    margin-right: 13px;
    width: 24px;
    height: 24px;
    box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.08);
    ${({ isComplete }) =>
        isComplete &&
        css`
            background-color: ${COLORS.SUCCESS};
            color: ${COLORS.WHITE};
        `}
`;

export const StyledNavAction = styled.a<NavActionProps>`
    ${StyledNavBase};
    padding-left: 24px;
    &:hover {
        cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
    }
    color: ${(props) => (props.$disabled ? COLORS.GRAY_DISABLED : COLORS.GRAY_MED)};
`;

export const StyledNavLink = styled(NavLink)<NavLinkProps>`
    ${StyledNavBase};
    padding-left: ${(props) => (props.$nested ? '40px' : '24px')};
        
    &:hover {
        cursor: ${(props) => (props.$step ? 'default' : 'pointer')};
    }

    &.active {
        background-color: ${COLORS.INFO_HIGHLIGHT};
        padding-left: ${(props) => (props.$nested ? '32px' : '16px')};
        color: ${COLORS.SECONDARY}
        border-radius: 2px;

        &::before {
            content: "";
            width: 8px;
            background-color: ${COLORS.INFO_LIGHT};
            position: relative;
            left: ${(props) => (props.$nested ? '-32px' : '-16px')};
        };
        
        ${NavStep} {
            background-color: ${COLORS.SUCCESS};
            color: ${COLORS.WHITE};
        }
    }
`;
