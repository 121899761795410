import { getHeaders, SubmissionURL } from './config';
import { checkStatus } from './normalizer';
import { CarrierTreatyYear } from '../types/carrier.types';

export async function getLowestActiveReferralCTY() {
    const url = `${SubmissionURL}/carrier-treaty-year/lowestActiveReferral`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((resp) => resp.json());
}

export async function getCurrentTreatiesData(): Promise<CarrierTreatyYear[]> {
    const url = `${SubmissionURL}/carrier-treaty-year/active`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((resp) => resp.json());
}

export async function getAllTreatiesData(productLineType?: string): Promise<CarrierTreatyYear[]> {
    const url = `${SubmissionURL}/carrier-treaty-year${productLineType ? '?productLineType=' + productLineType : ''}`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((resp) => resp.json());
}
