import { createSelector } from 'reselect';

import ApplicationState from 'application/redux/store/ApplicationState';
import BusinessDataState from '../store/BusinessDataState';

const businessDataState = (state: ApplicationState) => state.business;
const currentTreaties = (state: BusinessDataState) => state.allTreaties.filter((t) => t.isActive);
const allTreaties = (state: BusinessDataState) => state.allTreaties;

export const getCurrentTreaties = createSelector(businessDataState, currentTreaties);
export const getAllTreaties = createSelector(businessDataState, allTreaties);
export const getAllTreatiesByProduct = (productLineType: string) =>
    createSelector(getAllTreaties, (treaties) => treaties.filter((t) => t.productLineType === productLineType));
