import { OKTA_GROUPS } from 'constants/index';

export function getRole(roles: string[]) {
    if (roles.includes(OKTA_GROUPS.ADMIN)) {
        return OKTA_GROUPS.ADMIN;
    } else if (roles.includes(OKTA_GROUPS.ACCOUNT_MANAGER)) {
        return OKTA_GROUPS.ACCOUNT_MANAGER;
    } else if (roles.includes(OKTA_GROUPS.UNDERWRITER)) {
        return OKTA_GROUPS.UNDERWRITER;
    } else if (roles.includes(OKTA_GROUPS.AGENT)) {
        return OKTA_GROUPS.AGENT;
    } else if (roles.includes(OKTA_GROUPS.CARRIER_REP)) {
        return OKTA_GROUPS.CARRIER_REP;
    } else {
        return 'auth';
    }
}

export function getIsAdminRole(role: string) {
    return role === OKTA_GROUPS.ADMIN;
}

export function getIsUnderwriterRole(role: string) {
    return role === OKTA_GROUPS.ADMIN || role === OKTA_GROUPS.UNDERWRITER;
}

export function getIsAgentRole(role: string) {
    return role === OKTA_GROUPS.AGENT;
}

export function getIsCarrierRepRole(role: string) {
    return role === OKTA_GROUPS.CARRIER_REP;
}

export function getIsAccountManagerRole(role: string) {
    return role === OKTA_GROUPS.ADMIN || role === OKTA_GROUPS.ACCOUNT_MANAGER;
}

export function getIsLumenRole(role: string) {
    return role === OKTA_GROUPS.ACCOUNT_MANAGER || role === OKTA_GROUPS.ADMIN || role === OKTA_GROUPS.UNDERWRITER;
}
