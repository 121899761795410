import styled from 'styled-components';
import { COLORS } from 'constants/index';

export const GridWrapper = styled.div`
    height: ${(props) => props.theme.height};
    
    .visible-on-hover {
        display: none;
    }

    .ag-row-hover .visible-on-hover {
        display: inline-flex;
    }

    &.ag-theme-material {
      color: ${COLORS.SECONDARY};
      font-size: 16px;
      font-weight: 400;
      font-family: europa, sans-serif;
      font-style: normal;

      .ag-ltr .ag-cell {
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      }

      .ag-row {
        border-width: 0;
      }

      .ag-row-last {
        border-color: rgba(0, 0, 0, 0.06);
      }

      .ag-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        font-family: europa, sans-serif;
        color: ${COLORS.GRAY_MED};
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
      }

      .ag-status-bar {
        border: none;
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        color: ${COLORS.GRAY_MED};
        font-size: 14px;
        padding-left: 0;
      }

      .ag-name-value-value {
        color: ${COLORS.SECONDARY};
      }

      .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
        border: none;
      }

      .ag-row-selected {
        background-color: transparent;

        .ag-cell {
          background-color: ${COLORS.PRIMARY_LIGHTER};
        }
      }

      .ag-row-disabled {
        background-color: transparent;

        .ag-cell {
          color: ${COLORS.GRAY_DISABLED};
        }
      }

      .ag-row-error {
        background-color: transparent;

        .ag-cell {
          background-color: ${COLORS.ERROR_LIGHTER};
        }
      }

      // Header Filters and Checkboxes
      .ag-tab-header .ag-tab.ag-tab-selected {
        border-bottom-color: ${COLORS.PRIMARY};
      }

      .ag-input-wrapper input[type='text']:focus {
        border-bottom-color: ${COLORS.PRIMARY};
      }

      .ag-icon-checkbox-unchecked {
        color: ${COLORS.GRAY_LIGHT};
      }

      .ag-icon-checkbox-checked,
      .ag-icon-checkbox-indeterminate {
        color: ${COLORS.GRAY_DARK};
      }

      .ag-cell.ag-cell-inline-editing {
        height: 100%;
      }
    }

    &.ag-theme-balham {
        // border-radius: 8px;
        color: ${COLORS.SECONDARY};
        font-size: 12px;
        font-weight: 400;
        font-family: europa, sans-serif;
        font-style: normal;

        .ag-root {
            border: none;
        }

        .ag-side-bar {
            border: none;
        }

        .ag-tool-panel-wrapper {
            border-left: 1px solid ${COLORS.AG_GRAY_LIGHT};
            border-top: 1px solid ${COLORS.AG_GRAY_LIGHT};
            border-bottom: none;
        }

        .ag-row:not(.ag-row-first) {
            border-width: 0;
        }

        .ag-header {
            // border: none;
            // background-color: ${COLORS.WHITE};
            font-family: europa, sans-serif;
            font-size: 13px;
            line-height: 18px;
            font-weight: 400;
        }

        .ag-status-bar {
            border: none;
            color: ${COLORS.GRAY_MED};
            font-size: 14px;
        }

        .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
            border: none;
        }

        .ag-row-selected {
            background-color: transparent;
            .ag-cell {
                background-color: transparent;
            }
        }

        // Header Filters and Checkboxes
        .ag-tab-header .ag-tab.ag-tab-selected {
            border-bottom-color: ${COLORS.PRIMARY};
        }

        .ag-input-wrapper input[type='text']:focus {
            border-bottom-color: ${COLORS.PRIMARY};
        }
        
        .ag-checkbox-input-wrapper::after {
            color: ${COLORS.GRAY_LIGHT};
        }
      
        
        .ag-checked::after{
            color: ${COLORS.GRAY_DARK};
        }
    }

    .ag-root-wrapper {
        // border-radius: 8px;
    }
`;
