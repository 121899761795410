import * as React from 'react';
import { Typography } from '@mui/material';

import { Spacing, Page } from 'components/Layout';
import LrDropzone from 'components/LrDropzone';
import { LrFileItemList } from 'components/LrFileItems';
import { RESOURCE_DOCUMENT_TYPE } from 'constants/index';
import { DocumentType } from 'types/types.type';
import { Document2 } from 'types/document.type';
import { useSelector } from 'react-redux';
import { getDocumentTypesByCode } from 'application/redux/selectors';
import { forwardRef, useImperativeHandle } from 'react';
import { documents } from 'application/Providers/API';

interface Props {
    allowPdfOnly?: boolean;
    title?: string;
    fillHeight?: boolean;
    nested?: boolean;
    fileTypeOptions?: DocumentType[];
    fileTypeNeedsYear?: { [docType: string]: string };
    fileTypeNeedsProduct?: { [docType: string]: { productsAvailable: string[]; toolTip: string } };
    fileColorOptions?: any[];
    maxFileCount?: number;
    message?: string;
    initialDocs?: Document2[];
    onFilesValidate?: (areValid: boolean, files?: any[]) => void;
    onDocumentsSubmitted?: (documents: Document2[], error?: boolean) => void;
}

export interface FileUploadAPI {
    doSubmit: () => Promise<Document2[]>;
    clearFiles: () => void;
}

const FileUpload: React.RefForwardingComponent<FileUploadAPI, Props> = (props, ref) => {
    const {
        allowPdfOnly,
        fillHeight,
        fileTypeOptions,
        fileTypeNeedsYear,
        fileTypeNeedsProduct,
        maxFileCount,
        message,
        onDocumentsSubmitted,
        onFilesValidate,
        title,
        initialDocs,
        nested,
    } = props;

    // Global State
    const documentTypesByCode = useSelector(getDocumentTypesByCode);

    // Local State
    const currentYear = new Date().getFullYear();
    const [files, setFiles] = React.useState(
        (initialDocs &&
            initialDocs.map((doc) => {
                return { ...doc, name: doc.fileName, fileType: doc.documentType, fileYear: doc.year };
            })) ||
            ([] as any[])
    );
    const [action, setAction] = React.useState({ error: false, success: false, inProgress: false });
    const acceptedFileTypes = allowPdfOnly ? ['application/pdf'] : undefined;
    const fileTypeCaption = allowPdfOnly ? 'PDF Only' : undefined;
    const addFile = (addedFiles: any[]) => {
        fileTypeOptions && addedFiles.forEach((f) => (f.fileType = { code: '' } as DocumentType));
        fileTypeNeedsYear && addedFiles.forEach((f) => (f.fileYear = currentYear));
        fileTypeNeedsProduct && addedFiles.forEach((f) => (f.productLineCode = null)); // no default in general for product
        setFiles([...files, ...addedFiles]);
    };

    const validateYears = () => {
        if (!fileTypeNeedsYear) return true;
        return files.every((file, idx) => {
            // every added file type needs to have a corresponding year if required
            if (Object.keys(fileTypeNeedsYear).includes(file?.fileType?.code)) {
                // this filetype requires a year, make sure it has one
                return file.fileYear !== undefined;
            }
            return true;
        });
    };

    const validateProductLine = () => {
        if (!fileTypeNeedsProduct) return true;
        return files.every((file, idx) => {
            // every added file type needs to have a corresponding product line if required
            if (Object.keys(fileTypeNeedsProduct).includes(file?.fileType?.code)) {
                // this filetype requires a product, make sure it has a valid one
                return fileTypeNeedsProduct[file?.fileType?.code].productsAvailable.includes(file.productLineCode);
            }
            return true;
        });
    };

    const validateFileCount = () => {
        if (!maxFileCount || !files) return true;
        return files.length <= maxFileCount;
    };

    const validateTypeOptions = () => {
        if (!fileTypeOptions) return true;
        const areTypesSelected = files.every((f) => !!f.fileType && f.fileType.code !== '');
        return (
            areTypesSelected &&
            fileTypeOptions
                .filter((o) => o.isRequired)
                .every((o) => {
                    return files.find((f) => !!f.fileType && f.fileType.code === o.code) !== undefined;
                })
        );
    };

    const mapDocsToFiles = (docIds: any[]) =>
        files.map((f, idx) => {
            const docType = f.fileType
                ? documentTypesByCode[f.fileType.code]
                : documentTypesByCode[RESOURCE_DOCUMENT_TYPE.OTHER];
            return {
                uuid: docIds[idx].documentIds[0],
                fileName: f.name,
                title: f.title,
                description: f.description,
                documentTypeId: docType.documentTypeId,
                documentType: docType.code,
                year: f.fileYear || currentYear,
                productLineCode: f.productLineCode,
            };
        });

    const validateFiles = () => {
        if (!onFilesValidate) return;
        const areFilesValid = validateFileCount();
        const areOptionsValid = validateTypeOptions();
        const areYearsValid = validateYears();
        const areProductsValid = validateProductLine();
        onFilesValidate(areFilesValid && areOptionsValid && areYearsValid && areProductsValid, files);
    };

    const submitFiles = async () => {
        try {
            setAction({ ...action, inProgress: true });
            const filePromises = files.map((f) =>
                !f.uuid ? documents.sendFile(f) : new Promise((resolve) => resolve({ documentIds: [f.uuid] }))
            );
            const docIds = await Promise.all(filePromises);
            const documentList = mapDocsToFiles(docIds);
            setAction({ ...action, inProgress: false, success: true });
            onDocumentsSubmitted && onDocumentsSubmitted(documentList, false);
            return documentList;
        } catch (e) {
            setAction({ ...action, inProgress: false, error: true });
            onDocumentsSubmitted && onDocumentsSubmitted([], true);
        }
        return [];
    };

    const onTriggerSubmit = async () => {
        if (!action.inProgress) {
            return await submitFiles();
        }
        return [];
    };

    React.useEffect(validateFiles, [files]);

    useImperativeHandle(ref, () => ({
        doSubmit: async () => {
            return await onTriggerSubmit();
        },
        clearFiles: () => {
            setFiles([]);
        },
    }));

    return (
        <Page title={title} fillHeight={fillHeight}>
            <LrDropzone
                onFileDrop={addFile}
                nested={nested}
                acceptedFileTypes={acceptedFileTypes}
                caption={fileTypeCaption}
                height="150px"
            />
            <Spacing height="24px" />
            {message && !files.length && (
                <>
                    <Typography variant="body1" align="center">
                        {message}
                    </Typography>
                    <Spacing />
                </>
            )}
            {!!files.length && (
                <LrFileItemList
                    files={files}
                    removable
                    clickable
                    editable
                    disableIdFiles
                    direction="row"
                    fileTypeOptions={fileTypeOptions}
                    fileTypeNeedsYear={fileTypeNeedsYear}
                    fileTypeNeedsProduct={fileTypeNeedsProduct}
                    onFilesChange={(newFiles) => {
                        setFiles(newFiles);
                    }}
                />
            )}
        </Page>
    );
};

const ForwardedFileUpload = forwardRef(FileUpload);
export default React.memo(ForwardedFileUpload);
