import { Dispatch } from 'redux';

import { REDUX, DIALOG_NAMES } from 'constants/index';
import actionCreator from '../lib/actionCreator';

const dialogsAction = actionCreator(REDUX.DIALOGS);

export function openDialog(dialogName: string) {
    return (dispatch: Dispatch) => {
        dispatch(dialogsAction.loading());
        dispatch(dialogsAction.success(dialogName));
    };
}

export function closeDialog(errorString?: string) {
    return (dispatch: Dispatch) => {
        dispatch(dialogsAction.success(DIALOG_NAMES.NONE));
        if (!!errorString) {
            dispatch(dialogsAction.error(errorString));
        }
    };
}
