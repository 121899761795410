import { css } from 'styled-components';

export const COLORS = {
    BLACK: '#000000',
    GRAY_DARK: '#727270',
    GRAY_MED: '#969695',
    GRAY_DISABLED: 'rgba(0,0,0, .25)',
    GRAY: '#B9B9B8',
    GRAY_LIGHT: '#DCDCDC',
    AG_GRAY_LIGHT: '#BDC3C7',
    WHITE_DARK: '#F4F4FA',
    WHITE: '#FFFFFF',
    PRIMARY: '#FFDD00',
    PRIMARY_LIGHT: '#FFE622',
    PRIMARY_LIGHTER: '#FFF6BE',
    SECONDARY: '#54565A',
    SECONDARY_LIGHT: '#818184',
    SECONDARY_LIGHTER: '#D6D6D7',
    GOLD: '#FFCF5C',
    GOLD_LIGHT: '#FFE29D',
    GOLD_LIGHTER: '#FFF8E7',
    WARN: '#FFA26B',
    WARN_LIGHT: '#FFC7A6',
    WARN_LIGHTER: '#FFEW8DA',
    INFO: '#0084F4',
    INFO_LIGHT: '#66B5F8',
    INFO_LIGHTER: '#DFE9FA',
    INFO_HIGHLIGHT: '#d8efff',
    ERROR: '#FF647C',
    ERROR_LIGHT: '#FDAFBB',
    ERROR_LIGHTER: '#FBE4E8',
    SUCCESS: '#00C48C',
    SUCCESS_LIGHT: '#7DDFC3',
    SUCCESS_LIGHTER: '#D5F2EA',
};

export const GRADIENTS = {
    PRIMARY: css`linear-gradient(to right, ${COLORS.PRIMARY_LIGHTER} , ${COLORS.PRIMARY})`,
    SECONDARY: css`linear-gradient(to right, ${COLORS.SECONDARY_LIGHTER} , ${COLORS.SECONDARY})`,
};

export const FONTS = 'europa, sans-serif';
