import * as React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { AgGridReactProps } from 'ag-grid-react/lib/shared/interfaces';
import {
    FirstDataRenderedEvent,
    GridReadyEvent,
    SortChangedEvent,
    FilterChangedEvent,
} from 'ag-grid-community/dist/lib/events';

import { GRID_DATA_TYPES } from 'constants/index';
import { customStatusBar, customDefaultColDefs } from '../helpers/gridDefaults';
import { GridWrapper } from '../styled/Grid';
import { Page } from 'components/Layout';
import { GridApi } from 'ag-grid-community/dist/lib/gridApi';

interface Props extends AgGridReactProps {
    autosize?: boolean;
    gridClassName?: string;
    hideStatusBar?: boolean;
    columns?: any[];
    filters?: {};
    height?: string;
    sorts?: any[];
    sizeToFit?: boolean;
    selectFirstRow?: boolean;
    title?: string;
    disclaimer?: string | React.ReactNode;
    setGridApi?: (gridApi: GridApi) => void;
    setColumnApi?: (columnApi: any) => void;
    saveGridData?: (dataType: string, data?: any) => void;
}

const defaultProps = {
    height: '72vh',
    gridClassName: 'ag-theme-material',
};

const LrGrid: React.FunctionComponent<Props> = (props) => {
    const {
        aggFuncs,
        gridClassName,
        hideStatusBar,
        autosize,
        columns,
        filters,
        icons,
        sorts,
        sizeToFit,
        setGridApi,
        setColumnApi,
        selectFirstRow,
        height,
        defaultColDef,
        onFirstDataRendered,
        onGridReady,
        statusBar,
        saveGridData,
        title,
        disclaimer,
        gridOptions,
        treeData,
        getDataPath,
        ...restOfProps
    } = props;

    const autosizeCols = (gridParams: any) => {
        const { columnApi } = gridParams;
        columnApi.autoSizeAllColumns();
    };

    const restoreGrid = (gridParams: GridReadyEvent) => {
        const { api, columnApi } = gridParams;
        try {
            columns && !!columns.length && columnApi.setColumnState(columns);
        } catch (e) {
            /* Note:
             * nothing to do,
             * this strange error pops when a grid is mounted, then immediately unmounted, usually as a result of timing related issues involving a "loading" boolean controlling component inclusion
             **/
        }

        try {
            filters && api.setFilterModel(filters);
        } catch (e) {
            // see above
        }
        try {
            sorts && !!sorts.length && api.setSortModel(sorts);
        } catch (e) {
            // see above
        }
        // after setting the model on the API it can mess up the aggregations, the following refreshes any
        api.refreshClientSideRowModel('aggregate');
    };

    const handleFirstDataRendered = (gridParams: FirstDataRenderedEvent) => {
        selectFirstRow && gridParams.api.forEachNode((n) => (n.rowIndex ? 0 : n.setSelected(true)));
        autosize && !columns && autosizeCols(gridParams);
        sizeToFit && gridParams.api.sizeColumnsToFit();
        onFirstDataRendered && onFirstDataRendered(gridParams);
    };

    const handleGridReady = (gridParams: GridReadyEvent) => {
        setGridApi && setGridApi(gridParams.api);
        setColumnApi && setColumnApi(gridParams.columnApi);
        restoreGrid(gridParams);
        onGridReady && onGridReady(gridParams);
    };

    const handleColChange = (event: any) => {
        const { columnApi } = event;
        saveGridData && saveGridData(GRID_DATA_TYPES.COLUMNS, columnApi.getColumnState());
    };

    const handleFilterChange = (event: FilterChangedEvent) => {
        const { api } = event;
        saveGridData && saveGridData(GRID_DATA_TYPES.FILTERS, api.getFilterModel());
    };

    const dataPath = (data: any) => data.hierarchy.split('|');

    const theme = {
        height,
    };

    // Grid Customized Defaults
    const customDataPath = treeData ? getDataPath || dataPath : getDataPath;

    // TODO - the custom icons were causing parent organizations with no children to be indented,
    // making it look like they were children of the row above them.
    // Removing for now until this can be resolved.
    const customIcons = icons;
    // const customIcons = icons || {
    //     groupExpanded: '<i class="fa fa-caret-down" />',
    //     groupContracted: '<i class="fa fa-caret-right" />'
    // };

    const renderGrid = () => {
        return (
            <GridWrapper theme={theme} className={gridClassName}>
                <AgGridReact
                    defaultColDef={defaultColDef || customDefaultColDefs}
                    onGridReady={handleGridReady}
                    onFirstDataRendered={handleFirstDataRendered}
                    statusBar={hideStatusBar ? undefined : statusBar || customStatusBar}
                    scrollbarWidth={15}
                    icons={customIcons}
                    aggFuncs={aggFuncs}
                    gridOptions={customGridOptions}
                    treeData={treeData}
                    getDataPath={customDataPath}
                    {...restOfProps}
                />
            </GridWrapper>
        );
    };

    const customGridOptions = !saveGridData
        ? gridOptions
        : {
              ...gridOptions,
              onColumnVisible: handleColChange,
              onColumnPinned: handleColChange,
              onColumnResized: handleColChange,
              onColumnMoved: handleColChange,
              onFilterChanged: handleFilterChange,
              onSortChanged: handleColChange,
          };

    return (
        <>
            {title && (
                <Page title={title} disclaimer={disclaimer}>
                    {renderGrid()}
                </Page>
            )}
            {!title && renderGrid()}
        </>
    );
};

LrGrid.defaultProps = defaultProps;
export default React.memo(LrGrid);
