import * as React from 'react';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import NavItem from './NavItem';
import { SubmissionDetails } from 'types/submission.type';
import { Badge, Theme, Tooltip } from '@mui/material';
import { NavActionComponentProps } from 'types/nav.types';
import { useSelector } from 'react-redux';
import { getTopLevelUserGroup } from '../../../application/redux/selectors';
import { getIsAgentRole } from '../../../helpers/roles';
import { fetchClaimsCount } from '../../../services/losses';
import { createStyles, withStyles } from '@mui/styles';

interface SubmissionLossSummaryLinkProps extends NavActionComponentProps {
    submission: SubmissionDetails;
}

const SubmissionLossSummaryLink: FunctionComponent<SubmissionLossSummaryLinkProps> = (props) => {
    const { linkProps, key, submission } = { ...props };
    const [hideBadge, setHideBadge] = useState<boolean>(true);
    const group = useSelector(getTopLevelUserGroup);
    const isAgent = getIsAgentRole(group);

    const getClaimsCount = async () => {
        const response = await fetchClaimsCount(submission.id);
        if (response && response.claimsCount !== '0') {
            setHideBadge(false);
        }
    };

    useEffect(() => {
        getClaimsCount();
    }, []);

    const StyledBadge = withStyles((theme: Theme) =>
        createStyles({
            badge: {
                right: -10,
            },
        })
    )(Badge);
    return (
        <>
            <NavItem key={key} to={linkProps.to}>
                <>
                    {isAgent && <>{linkProps.label}</>}
                    {!isAgent && (
                        <Tooltip title={!hideBadge ? 'Claim Exists in Losses' : ''}>
                            <StyledBadge variant="dot" color={'secondary'} invisible={hideBadge}>
                                {linkProps.label}
                            </StyledBadge>
                        </Tooltip>
                    )}
                </>
            </NavItem>
        </>
    );
};

export default SubmissionLossSummaryLink;
