import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider as MuiThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';

import { store, persistor } from 'application/redux/store';
import theme from 'ui/theme';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';

import './index.css';
import { PersistGate } from 'redux-persist/integration/react';

import { LicenseManager } from 'ag-grid-enterprise';
import KurrentApp from 'KurrentApp';
import LrAuthentication from 'application/Providers/Authentication/index';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

declare module '@mui/styles/defaultTheme' {
    // tslint:disable-next-line:no-empty-interface
    interface DefaultTheme extends Theme {}
}

LicenseManager.setLicenseKey(
    'Using_this_AG_Grid_Enterprise_key_( AG-046676 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Lumen Risk Services, LLC )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Kurrent )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Kurrent )_need_to_be_licensed___( Kurrent )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 27 October 2024 )____[v2]_MTcyOTk4MzYwMDAwMA==b9714b436b1b8f019a75f8b32dcc74b8'
);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <LrAuthentication>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <StyledEngineProvider injectFirst>
                            <MuiThemeProvider theme={theme}>
                                <div>
                                    <KurrentApp />
                                </div>
                            </MuiThemeProvider>
                        </StyledEngineProvider>
                    </LocalizationProvider>
                </LrAuthentication>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root') as HTMLElement
);
