import { combineReducers, AnyAction } from 'redux';
import { REDUX } from 'constants/index';
import { UnderwriterReview } from 'types/underwriting.type';
import { UWReviewsData } from '../store/UwReviewsState';

const initialState = [] as UWReviewsData[];

const data = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case `${REDUX.UW_REVIEWS}/SUCCESS`: {
            return action.payload;
        }
        case `${REDUX.CLEAR_SELECTED_SUBMISSION}`:
        case `${REDUX.UW_REVIEWS}/ERROR`: {
            return initialState;
        }
        case `${REDUX.UW_REVIEWS}/LOADING`:
        default:
            return state;
    }
};

function error(state: string = '', action: AnyAction): string {
    switch (action.type) {
        case `${REDUX.UW_REVIEWS}/ERROR`: {
            return action.payload || 'Error Occurred';
        }
        case `${REDUX.UW_REVIEWS}/LOADING`: {
            return '';
        }
        default:
            return state;
    }
}

function isLoading(state: boolean = false, action: AnyAction): boolean {
    switch (action.type) {
        case `${REDUX.UW_REVIEWS}/LOADING`: {
            return true;
        }
        case `${REDUX.UW_REVIEWS}/SUCCESS`:
        case `${REDUX.UW_REVIEWS}/ERROR`: {
            return false;
        }
        default:
            return state;
    }
}

function hasResolved(state: boolean = false, action: AnyAction): boolean {
    switch (action.type) {
        case `${REDUX.FETCH_ONE_SUBMISSION}/LOADING`:
            return false;
        case `${REDUX.FETCH_ONE_SUBMISSION}/SUCCESS`:
        case `${REDUX.FETCH_ONE_SUBMISSION}/ERROR`: {
            return true;
        }
        default:
            return state;
    }
}

export default combineReducers({
    data,
    error,
    isLoading,
    hasResolved,
});
