import { FunctionComponent, default as React, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ROUTES } from 'constants/index';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import Routes from 'routes';
import { RouteComponentProps, withRouter } from 'react-router';
import TimeoutDialog from 'components/TimeoutDialog';

const idleTimeout = process.env.REACT_APP_IDLE_TIMEOUT_SECONDS
    ? +process.env.REACT_APP_IDLE_TIMEOUT_SECONDS * 1000
    : 1000 * 15 * 60; // if not define default to 15 minutes

const KurrentApp: FunctionComponent<RouteComponentProps> = ({ history, location }) => {
    const [showTimeout, setShowTimeout] = React.useState(false);

    // const [idleTimer, setIdleTimer] = React.useState<IdleTimer | null>(null);
    const timerEnabled = idleTimeout !== 0;

    const onIdle = () => {
        timerEnabled && setShowTimeout(true);
    };

    const idleTimer = useIdleTimer({
        onIdle: onIdle,
        debounce: 250,
        timeout: idleTimeout,
        stopOnIdle: !timerEnabled,
        startOnMount: timerEnabled,
        timers: workerTimers,
        crossTab: true,
    });

    const onLogout = () => history.push('/logout');
    const onClose = () => {
        idleTimer && idleTimer.reset();
        setShowTimeout(false);
    };

    useEffect(() => {
        if (idleTimer) {
            if (location.pathname === ROUTES.login()) {
                // we don't track the idle state if we are on the login screen
                idleTimer.pause();
            } else {
                idleTimer.resume();
            }
        }
    }, []);

    return (
        <>
            {/*<IdleTimer*/}
            {/*    ref={(ref) => {*/}
            {/*        setIdleTimer(ref);*/}
            {/*    }}*/}
            {/*    onIdle={onIdle}*/}
            {/*    debounce={250}*/}
            {/*    timeout={idleTimeout}*/}
            {/*    stopOnIdle={!timerEnabled}*/}
            {/*    startOnMount={timerEnabled}*/}
            {/*>*/}
            <CssBaseline />
            <Routes />
            {/*</IdleTimer>*/}
            {showTimeout && <TimeoutDialog handleLogout={onLogout} handleClose={onClose} />}
        </>
    );
};

export default withRouter(KurrentApp);
