import styled, { css } from 'styled-components';
import { COLORS } from 'constants/index';

interface WrapperProps {
    isDragActive: boolean;
    nested?: boolean;
    height?: string;
}

export const Wrapper = styled.div`
    margin-bottom: 8px;
    border-width: 2px;
    border-color: ${COLORS.GRAY_LIGHT};
    border-style: dashed;
    border-radius: 6px;
    border-top-left-radius: ${(props: WrapperProps) => (props.nested ? '0' : '6px')};
    border-bottom-left-radius: ${(props: WrapperProps) => (props.nested ? '0' : '6px')};
    height: ${(props: WrapperProps) => props.height || '100%'};

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
        border-color: ${COLORS.GRAY};
        border-width: 2px;
    }
    ${(props: WrapperProps) =>
        props.isDragActive &&
        css`
            border-color: ${COLORS.GRAY_LIGHT};
            border-width: 3px;
            background-color: ${COLORS.PRIMARY_LIGHTER};
        `}
`;
