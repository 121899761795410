import * as React from 'react';
import NumberFormat from 'react-number-format';
import { FormControl, Input, InputAdornment } from '@mui/material';
import styles from '../styles/InputStyles';
import { withStyles } from '@mui/styles';

interface InputProps {
    align?: 'left' | 'right' | 'center';
    classes: any;
    className?: string;
    defaultValue?: string;
    disabled?: boolean;
    inputId: string;
    isFormattedNumber?: boolean;
    isFormattedDate?: boolean;
    isPercentage?: boolean;
    isInteger?: boolean;
    placeholder: string;
    readonly?: boolean;
    maxDecimals?: number;
    value: string | undefined;
    onChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    onBlur?: (e: React.FocusEvent) => void;
}

function integerFormat(props: any) {
    const { inputRef, ...other } = props;
    return <NumberFormat {...other} getInputRef={inputRef} thousandSeparator={true} />;
}

function getNumberFormat(maxDecimals: number) {
    return (props: any) => {
        const { inputRef, ...other } = props;
        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                prefix={'$'}
                thousandSeparator={true}
                decimalScale={maxDecimals}
            />
        );
    };
}

function percentFormat(props: any) {
    const { inputRef, ...other } = props;
    return <NumberFormat {...other} getInputRef={inputRef} decimalScale={2} />;
}

function dateFormat(props: any) {
    const { inputRef, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            format={'##/##/####'}
            mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
        />
    );
}

function getInputComponent(
    isFormattedNumber?: boolean,
    isFormattedDate?: boolean,
    isPercentage?: boolean,
    isInteger?: boolean,
    maxDecimals: number = 2
) {
    if (isInteger) {
        return integerFormat;
    }
    if (isFormattedNumber) {
        return getNumberFormat(maxDecimals);
    }
    if (isFormattedDate) {
        return dateFormat;
    }
    if (isPercentage) {
        return percentFormat;
    }
    return 'input';
}

const CustomInput: React.FunctionComponent<InputProps> = (props) => {
    const {
        align,
        inputId,
        classes,
        className,
        disabled,
        placeholder,
        value,
        defaultValue,
        onChange,
        onBlur,
        readonly,
        maxDecimals,
        isPercentage,
        isFormattedNumber,
        isFormattedDate,
        isInteger,
    } = props;

    const adornment = isPercentage ? <InputAdornment position={'end'}>%</InputAdornment> : undefined;
    const inputComponent = getInputComponent(isFormattedNumber, isFormattedDate, isPercentage, isInteger, maxDecimals);
    const textAlign = align || 'right';

    return (
        <FormControl variant="standard" className={classes.formControl}>
            <Input
                key={inputId}
                style={{ textAlign }}
                readOnly={readonly}
                disableUnderline={readonly}
                disabled={disabled}
                className={className}
                classes={{ input: classes.input }}
                placeholder={placeholder}
                value={value || defaultValue}
                onChange={onChange}
                onBlur={onBlur}
                endAdornment={adornment}
                inputComponent={inputComponent}
            />
        </FormControl>
    );
};

export default withStyles(styles)(CustomInput);
