import { ActiveUserProducer } from 'types/types.type';
import { Organization } from 'types/organization.type';
import React from 'react';

// This is for lumen users who should not be producers so everything is empty
// TODO this should really extend NullProducer
export class LumenActiveUserProducer implements ActiveUserProducer {
    getAvailableProducers(): Organization[] {
        return [];
    }
    getSubHeader(): {} | React.ReactNodeArray | string {
        return '';
    }
    getEffectiveActiveProducer(): Organization | undefined {
        return undefined;
    }
    async fetchAvailableAgents(): Promise<void> {
        return new Promise<void>((res) => {
            res();
        });
    }

    getProducer(): Organization {
        return {} as Organization;
    }

    getId(): string | undefined {
        return undefined;
    }

    validateAssignedProducer(assignedProducer: Organization): Organization | undefined {
        return undefined;
    }

    getTemplateQualifier(fileType: string, availableQualifiers: string[] | undefined): string {
        return '';
    }
}
