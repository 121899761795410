import styled, { css } from 'styled-components';

interface Props {
    top?: string;
    left?: string;
    bottom?: string;
    right?: string;
}

export const Margin = styled.div`
    ${(props: Props) => {
        return css`
            margin-top: ${props.top || 0}px;
            margin-left: ${props.left || 0}px;
            margin-bottom: ${props.bottom || 0}px;
            margin-right: ${props.right || 0}px;
        `;
    }}
`;
