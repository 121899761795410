import { Dispatch } from 'redux';

import { REDUX } from 'constants/index';
import actionCreator from '../lib/actionCreator';
import { fetchLocationOptions, fetchTypes } from 'services/types';
import {
    AllTypesResponse,
    TypesData,
    AmType,
    LocationOptionsData,
    AllLocationOptionsResponse,
    LocationOption,
} from 'types/types.type';
import { initialState as initialTypesState } from '../reducers/types';

const typesAction = actionCreator(REDUX.TYPES);
const locationOptionsAction = actionCreator(REDUX.LOCATION_OPTIONS);

const mapTypes = (types: AllTypesResponse): TypesData => {
    return Object.keys(types).reduce((allTypes, key) => {
        // Note: reconstruct the type to be in the format { id: type }
        const typeByIdObj = types[key].reduce((acc: object, type: AmType) => {
            acc[type.id] = type;
            return acc;
        }, {});
        allTypes[key] = { ...typeByIdObj };
        return allTypes;
    }, initialTypesState);
};

export function fetchAllTypes() {
    return async (dispatch: Dispatch) => {
        dispatch(typesAction.loading());
        fetchTypes()
            .then((types: AllTypesResponse) => dispatch(typesAction.success(mapTypes(types))))
            .catch((reason) => dispatch(typesAction.error(reason)));
    };
}

function mapOptions(options: AllLocationOptionsResponse): LocationOptionsData {
    const opt = options.options;
    return Object.keys(opt).reduce((allOptions, key) => {
        const optionByCode = opt[key]
            .sort((o1, o2) => o1.orderNumber - o2.orderNumber)
            .reduce((o: object, option: LocationOption) => {
                o[option.code] = option.displayValue;
                return o;
            }, {});
        allOptions[key] = { ...optionByCode };
        return allOptions;
    }, {});
}

export function fetchAllLocationOptions() {
    return async (dispatch: Dispatch) => {
        dispatch(locationOptionsAction.loading());
        fetchLocationOptions()
            .then((options: AllLocationOptionsResponse) => dispatch(locationOptionsAction.success(mapOptions(options))))
            .catch((reason) => dispatch(locationOptionsAction.error(reason)));
    };
}
