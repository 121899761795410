import Login from './presenter';
import { Theme } from '@mui/material';
import { StyleRules } from '@mui/styles';
import { withStyles } from '@mui/styles';

const styles = (theme: Theme): StyleRules => ({
    // TODO: what are these styles here for?? login redirects to okta
    main: {
        width: 'auto',
        display: 'block',
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        [theme.breakpoints.up(400 + Number(theme.spacing(6).replace('px', '')))]: {
            width: 450,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)} `,
    },
    disabled: {},
});

export default withStyles(styles)(Login);
