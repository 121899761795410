import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { getDocumentTypesByCode } from 'application/redux/selectors';
import { Spacing } from 'components/Layout';
import { Document2 } from 'types/document.type';

import FileItem from './FileItem';
import { updateDocument } from '../../../services/documents';
import { DefaultTheme, WithTheme, withTheme } from '@mui/styles';

export interface DocsWithLabel {
    documents?: Document2[];
    label?: string;
    link?: string;
    level?: number;
}

interface Props {
    labeledDocs?: DocsWithLabel[];
    onLabelClick?: (label: string, link: string) => void;
    onDocUpdated?: (document: Document2) => void;
}

const LabeledFileItemList: React.FunctionComponent<Props & WithTheme<DefaultTheme>> = (props) => {
    const { labeledDocs, onLabelClick, onDocUpdated, theme } = props;

    // Global State
    const docTypesByCode = useSelector(getDocumentTypesByCode);
    const isSmallSize = useMediaQuery(theme.breakpoints.down('xl'));
    const getDisplayType = (document: Document2) => {
        // TODO the fact that documentType is a mixed type isn't good, need to understand the cases where it might be a string and fix that
        const docType = document.documentType as any;
        // @ts-ignore
        return docType && (docType.displayValue || docTypesByCode[docType].displayValue);
    };
    return (
        <>
            {labeledDocs &&
                labeledDocs.map(({ documents, label, link, level }: DocsWithLabel, idx: number) => (
                    <Grid
                        key={idx}
                        container
                        direction="column"
                        style={(level || 1) > 1 ? { paddingLeft: ((level || 1) - 1) * 15 + 'px' } : {}}
                    >
                        {label && (
                            <Grid xs={12}>
                                <Typography variant="caption" style={{ fontSize: '16px' }}>
                                    {link && (
                                        <Link
                                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                                            onClick={onLabelClick ? () => onLabelClick(label, link) : undefined}
                                            href={onLabelClick ? undefined : link}
                                            underline="hover"
                                        >
                                            {label}
                                        </Link>
                                    )}
                                    {!link && <>{label}</>}
                                </Typography>
                            </Grid>
                        )}
                        {documents &&
                            documents.map((document: Document2, docIdx: number) => (
                                <Grid container key={docIdx} xs={12} direction="row" alignItems="center">
                                    <Grid xs={8} lg={5}>
                                        <FileItem
                                            noMargin
                                            editable
                                            showDescriptionHover={isSmallSize}
                                            name={document.fileName}
                                            uuid={document.uuid}
                                            label={document.title}
                                            description={document.description}
                                            onFileChange={(fileTitle, fileDescription) => {
                                                documents[docIdx].title = fileTitle;
                                                documents[docIdx].description = fileDescription;
                                                updateDocument(documents[docIdx]).then(() => {
                                                    onDocUpdated?.(documents[docIdx]);
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid lg={5} sx={{ display: { xs: 'none', md: 'block' } }}>
                                        <Typography>{document.description}</Typography>
                                    </Grid>
                                    <Grid xs={4} lg={2}>
                                        <Typography align="right" style={{ margin: 0 }}>
                                            {getDisplayType(document)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        {(!documents || !documents.length) && (
                            <Grid>
                                <Typography style={{ padding: '6px 8px' }}>--</Typography>
                            </Grid>
                        )}
                        {/*{documents && idx !== labeledDocs.length - 1 && <Spacing height="16px" />}*/}
                    </Grid>
                ))}
        </>
    );
};

export default withTheme<DefaultTheme, typeof LabeledFileItemList>(LabeledFileItemList);
