import React, { FunctionComponent, useState } from 'react';
import NavItem from './NavItem';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ViewPDF from '../../ViewPDF';
import TableChart from '@mui/icons-material/TableChart';
import { useSelector } from 'react-redux';
import { getTopLevelUserGroup } from 'application/redux/selectors';
import { getIsLumenRole } from 'helpers/roles';
import UnderwriterAlertsInfo from './UnderwriterAlertsInfo';
import MarketIntelNotes from './MarketIntelNotes';

const UwGuidelinesInfo: FunctionComponent = () => {
    const group = useSelector(getTopLevelUserGroup);
    const isAdmin = getIsLumenRole(group);

    const [showUWGuidelines, setShowUWGuidelines] = useState(false);
    const [showUWGlGuidelines, setShowUWGlGuidelines] = useState(false);
    const [showUWGlScorecard, setShowUWGlScorecard] = useState(false);
    const [showAggGuidelines, setShowAggGuidelines] = useState(false);
    const [showUWRateTable, setShowUWRateTable] = useState(false);

    return (
        <>
            {isAdmin && <UnderwriterAlertsInfo />}
            <MarketIntelNotes />
            <NavItem
                onClick={() => {
                    setShowUWGuidelines(true);
                }}
                icon={<AssignmentIcon />}
            >
                UW Guidelines
            </NavItem>
            <ViewPDF
                isOpen={showUWGuidelines}
                fileName={'Lumen - UW Guidelines 110124.pdf'}
                fileTitle={'UW Guidelines'}
                onClose={() => setShowUWGuidelines(false)}
            />
            <NavItem
                onClick={() => {
                    setShowAggGuidelines(true);
                }}
                icon={<AssignmentIcon />}
            >
                Aggregation Guidelines
            </NavItem>
            <ViewPDF
                isOpen={showAggGuidelines}
                fileName={'Lumen - UW Guidelines 110124.pdf'}
                fileTitle={'Aggregation Guidelines'}
                initialPageNumber={8}
                onClose={() => setShowAggGuidelines(false)}
            />
            <NavItem
                onClick={() => {
                    setShowUWRateTable(true);
                }}
                icon={<TableChart />}
            >
                UW Rate Table
            </NavItem>
            <ViewPDF
                isOpen={showUWRateTable}
                fileName={'Lumen - UW Rates 091322.pdf'}
                fileTitle={'UW Rate Table'}
                onClose={() => setShowUWRateTable(false)}
            />
            <NavItem
                onClick={() => {
                    setShowUWGlGuidelines(true);
                }}
                icon={<AssignmentIcon />}
            >
                UW GL Guidelines
            </NavItem>
            <ViewPDF
                isOpen={showUWGlGuidelines}
                fileName={'Lumen - UW GL Guidelines 011325.pdf'}
                fileTitle={'UW GL Guidelines'}
                onClose={() => setShowUWGlGuidelines(false)}
            />
            <NavItem
                onClick={() => {
                    setShowUWGlScorecard(true);
                }}
                icon={<TableChart />}
            >
                UW GL Scorecard
            </NavItem>
            <ViewPDF
                isOpen={showUWGlScorecard}
                fileName={'Lumen - UW GL Scorecard 111124.pdf'}
                fileTitle={'UW GL Scorecard'}
                onClose={() => setShowUWGlScorecard(false)}
            />
        </>
    );
};

export default UwGuidelinesInfo;
