import { createSelector } from 'reselect';

export interface GridState {
    columns: any[];
    filters: {};
    error: string;
}

const grid = (state: any) => state.grid;
const columns = (g: GridState) => g.columns;
const filters = (g: GridState) => g.filters;
const error = (g: GridState) => g.error;

export const getGridSelectors = (state: any) => {
    const getGridData = createSelector(state, grid);
    return {
        getGridData,
        getGridColumns: createSelector(getGridData, columns),
        getGridFilters: createSelector(getGridData, filters),
        getGridError: createSelector(getGridData, error),
    };
};
