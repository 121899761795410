import { getHeaders } from '../../../services/config';
import { checkStatus, getData } from '../../../services/normalizer';

export default class DocumentsAPI {
    host?: string;

    constructor(host: string | undefined) {
        this.host = host;
    }

    retrieveDocumentWithFileName(uuid: string, filename: string): string {
        return `${this.host}/document/${uuid}?filename=${filename}`;
    }

    async getDocument(uuid: string, filename: string) {
        const url = this.retrieveDocumentWithFileName(uuid, encodeURIComponent(filename));
        const options = {
            method: 'GET',
            headers: getHeaders(),
        };

        return fetch(url, options).then(checkStatus);
    }

    async sendFile(payload: File) {
        return fetch(`${this.host}/document`, {
            method: 'POST',
            headers: getHeaders(),
            body: payload,
        })
            .then(checkStatus)
            .then(getData);
    }
}
