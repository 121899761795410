import { FONTS, COLORS } from 'constants/index';
import styled from 'styled-components';

export const LogoWrapper = styled.div`
    padding: 16px;
    font-family: ${FONTS};
    color: ${COLORS.SECONDARY};
    cursor: pointer;
`;

export const MenuWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
