import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { useState } from 'react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

const issuer = process.env.REACT_APP_OKTA_ISSUER;
const redirectUri = process.env.REACT_APP_OKTA_REDIRECT_URI;
const clientId = process.env.REACT_APP_OKTA_CLIENT_ID;
const clientSecret = process.env.REACT_APP_OKTA_CLIENT_SECRET;

//
// const authProvider: OAuthProvider = new AuthService({
//     issuer,
//     redirect_uri: redirectUri,
//     client_id: clientId,
//     responseType: ['id_token', 'token'],
//     pkce: false,
//     onAuthRequired: onAuthRequired()
// });

// export const logout = async (history: any) => {
//     authProvider._history = history;
//     const authState = authProvider.getAuthState();
//     const authenticated = !authState.isPending && authState.isAuthenticated;
//     if (authenticated) {
//         await authProvider.logout();
//     }
//     else{
//         history.push(ROUTES.login());
//     }
// };

// export default authProvider;

const LrAuthentication: React.FunctionComponent = (props) => {
    const { children } = props;
    const history = useHistory();
    const [oktaAuth, setOktaAuth] = useState(
        new OktaAuth({
            issuer: issuer,
            redirectUri: redirectUri,
            clientId: clientId,
            scopes: ['openid', 'profile', 'offline_access'],
        })
    );

    const onAuthRequired = () => {
        history.push('/login');
    };

    const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired}>
            {children}
        </Security>
    );
};

export default LrAuthentication;
