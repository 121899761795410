import { dateSort, numberSort } from 'components/LrGrid';
import React from 'react';
import { LrFileItem } from 'components/LrFileItems/';
import { ICellRendererParams } from 'ag-grid-community';
import { formatDollar } from 'helpers/formatters';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';

export const detailColumnDefs = [
    {
        headerName: 'Document title',
        cellRendererFramework: (params: ICellRendererParams) => {
            return (
                <LrFileItem
                    label={params.data.title}
                    name={params.data.fileName}
                    uuid={params.data.storageGuid}
                    noMargin
                />
            );
        },
    },
];

export const columnDefs = [
    {
        headerName: 'Location Name',
        field: 'locationName',
        cellStyle: { textAlign: 'left' },
        cellRenderer: 'agGroupCellRenderer',
    },
    {
        headerName: 'Date of Loss',
        field: 'dateOfLoss',
        comparator: dateSort,
    },
    {
        headerName: 'Lumen Program Loss',
        field: 'inPolicyLoss',
        cellRenderer: (params: any) => {
            return params.data.isClaim ? '<i class="fa fa-check" />' : '';
        },
    },
    {
        headerName: 'Loss Description',
        field: 'narrative',
    },
    {
        headerName: 'Type',
        valueGetter: (params: any) => params.data.perilType?.displayValue,
    },
    {
        headerName: 'Open or Closed',
        valueGetter: (params: any) => {
            return params.data.isClosed === undefined ? '' : !!params.data.isClosed ? 'Closed' : 'Open';
        },
    },
    {
        headerName: 'Paid',
        field: 'paid',
        comparator: numberSort,
        valueFormatter: ({ value }: any) => (value ? formatDollar(value, 0) : ''),
    },
    {
        headerName: 'Reserved',
        field: 'reserved',
        comparator: numberSort,
        valueFormatter: ({ value }: any) => (value ? formatDollar(value, 0) : ''),
    },
    {
        headerName: 'Total Loss',
        field: 'totalLoss',
        comparator: numberSort,
        valueFormatter: (params: ValueFormatterParams) => {
            const dollarValue = params.value ? formatDollar(params.value as string, 0) : '$0';
            return params.node?.rowPinned ? 'Total: ' + dollarValue : dollarValue;
        },
    },
];
