import { StyleRules } from '@mui/styles';

const styles = (): StyleRules => ({
    formControl: {
        opacity: 0.87,
        width: '100%',
    },
    input: {
        fontSize: '13px',
        paddingRight: '0px',
        textAlign: 'right',
    },
});

export default styles;
