import ApplicationState from '../store/ApplicationState';
import { createSelector } from 'reselect';
import SubmissionUserPreferenceState, {
    EmptySubmissionUserPreferenceStateUserData,
    SubmissionUserPreferenceStateData,
    SubmissionUserPreferenceStateUserData,
} from '../store/SubmissionUserPreferenceState';
import { getActiveUser } from './users';
import { User } from 'types/users.types';

const userPreferenceState = (state: ApplicationState) => state.submissionUserPreferences;
const data = (state: SubmissionUserPreferenceState) => state.data;
const currentUserPreferences = (userPrefState: SubmissionUserPreferenceStateData, activeUserState: User) => {
    return activeUserState && activeUserState.id && userPrefState && userPrefState[activeUserState.id]
        ? userPrefState[activeUserState.id]
        : EmptySubmissionUserPreferenceStateUserData;
};
const assignedProducer = (state: SubmissionUserPreferenceStateUserData) => {
    return state.assignedProducer;
};

export const getSubmissionUserPrefs = createSelector(userPreferenceState, data);

export const getCurrentUserPrefs = createSelector(getSubmissionUserPrefs, getActiveUser, currentUserPreferences);

// The AssignedProducer is the producer that has been chosen as "active" from the drop-down.  The assigned producer represents the producer context used when creating new submissions or viewing already submitted submissions
export const getAssignedProducer = createSelector(getCurrentUserPrefs, assignedProducer);
