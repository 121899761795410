import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { logoutSuccess } from 'application/redux/actions';

import Logout from './presenter';

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators(
        {
            logoutSuccess,
        },
        dispatch
    );
};

export default connect(null, mapDispatchToProps)(Logout);
