import * as React from 'react';

import Header from 'components/Header';
import Grid from '@mui/material/Unstable_Grid2';
import SideNav from 'components/SideNav';
import EmbeddedPowerBIReport from '../../components/Reporting';

const GeoFuse: React.FunctionComponent = () => {
    // Local State

    const REPORTINGMAP_ID = '432d43c2-f4dc-4cba-9414-4b801ceedbfd';
    const REPORTINGMAP_WORKSPACE_ID = '5969b83e-5d39-4154-ad43-a016b71cd9e0';

    return (
        <>
            <Header label="GeoFuse" />
            <Grid container spacing={2} style={{ margin: 0, width: '100%' }}>
                <SideNav showNav={true} />
                <Grid xs={10}>
                    <EmbeddedPowerBIReport reportId={REPORTINGMAP_ID} workspaceId={REPORTINGMAP_WORKSPACE_ID} />
                </Grid>
            </Grid>
        </>
    );
};

export default GeoFuse;
