import * as React from 'react';

interface Props {
    height?: string;
}

const defaultProps = {
    height: '16px',
};

const Spacing: React.FunctionComponent<Props> = (props) => {
    const { height } = props;

    return <div style={{ height }} />;
};

Spacing.defaultProps = defaultProps;
export default Spacing;
