import * as React from 'react';
import { InputLabel, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { GridSpacing } from '@mui/material/Grid';
import { COLORS } from 'constants/index';

interface Props {
    spacing?: GridSpacing;
    error?: string | boolean;
    title?: React.ReactNode;
    children: React.ReactNode;
    fullHeight?: boolean;
    sx?: any;
}

const InputWrapper: React.FunctionComponent<Props> = (props) => {
    const { error, fullHeight, title, spacing, children, sx } = props;

    const gridSpacing = spacing || spacing === 0 ? spacing : 1;
    const height = fullHeight ? '100%' : 'auto';

    return (
        <Grid
            container
            direction="column"
            spacing={gridSpacing}
            style={{ margin: 0, width: '100%', height: height, ...sx }}
        >
            {title && (
                <Grid xs={12}>
                    <InputLabel>{title}</InputLabel>
                </Grid>
            )}
            <Grid xs={12} style={{ width: '100%' }}>
                {children}
            </Grid>
            {error && (
                <Grid xs={12}>
                    <Typography variant="body2" style={{ color: COLORS.ERROR }}>
                        {error}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};

export default InputWrapper;
