import * as React from 'react';
import NotesInput from './NotesInput';
import NotesList, { LrNotesProps } from './NotesList';
import { Spacing } from 'components/Layout';
import { useSelector } from 'react-redux';
import { getLogTypes } from '../../../application/redux/selectors';

interface Props extends LrNotesProps {
    noteCreateTypeId: string | string[];
    canCreate: boolean;
    notesActionType?: string;
}

const Notes: React.FunctionComponent<Props> = (props) => {
    const { options, noteCreateTypeId, canCreate, notesActionType } = props;
    const logTypes = useSelector(getLogTypes);
    let defaultNoteType = noteCreateTypeId;
    let noteTypeOptions: Record<number, string> | undefined;
    if (Array.isArray(noteCreateTypeId)) {
        defaultNoteType = noteCreateTypeId[0];
        noteTypeOptions = {};
        for (const id of noteCreateTypeId) {
            noteTypeOptions[Number(id)] = logTypes[id];
        }
    }

    // Local State
    const [notesNeedRefresh, setNotesNeedRefresh] = React.useState(true);

    if (!options || !Object.keys(options).length) return <></>;
    return (
        <>
            {canCreate && (
                <>
                    <NotesInput
                        doCreate
                        onNoteSubmitted={() => setNotesNeedRefresh(true)}
                        options={{ ...options, logTypeId: defaultNoteType }}
                        notesActionType={notesActionType}
                        noteTypeOptions={noteTypeOptions}
                    />
                    <Spacing height="24px" />
                </>
            )}
            <NotesList {...props} needsRefresh={notesNeedRefresh} onNotesRefreshed={() => setNotesNeedRefresh(false)} />
        </>
    );
};

export default React.memo(Notes);
