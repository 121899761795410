import { Dispatch } from 'redux';

import { REDUX } from 'constants/index';
import actionCreator from '../lib/actionCreator';
import { getCurrentTreatiesData, getAllTreatiesData } from 'services/carrierTreatyYear';

const currentTreatyAction = actionCreator(REDUX.BUSINESS_DATA_CURRENT_TREATIES);
const allTreatyAction = actionCreator(REDUX.BUSINESS_DATA_ALL_TREATIES);

export function fetchCurrentTreaties() {
    return async (dispatch: Dispatch) => {
        dispatch(currentTreatyAction.loading());
        getCurrentTreatiesData()
            .then((treaties) => dispatch(currentTreatyAction.success(treaties)))
            .catch((reason) => dispatch(currentTreatyAction.error(reason || 'Treaty Error')));
    };
}

export function fetchAllTreaties() {
    return async (dispatch: Dispatch) => {
        dispatch(currentTreatyAction.loading());
        getAllTreatiesData()
            .then((treaties) => dispatch(allTreatyAction.success(treaties)))
            .catch((reason) => dispatch(allTreatyAction.error(reason || 'Treaty Error')));
    };
}
