import LrDialog, { LrDialogBaseProps } from 'components/LrDialog';
import * as React from 'react';
import { Claim } from 'types/losses.type';
import { Form, Formik, Field } from 'formik';
import Grid from '@mui/material/Unstable_Grid2';
import { TextInput } from 'components/Input';
import { currencyMask, dateMask } from 'helpers/masks';
import { Spacing } from 'components/Layout';
import LrButton from 'components/LrButton/components/Button';
import { claimDataSchema } from './ValidationSchema';
import { Typography } from '@mui/material';
import { formatDollar } from 'helpers/formatters';

// lossId: number;

interface Props extends LrDialogBaseProps {
    claimData?: Claim;
    onSubmit?: (submittedClaim: Claim) => void;
    readonly: boolean;
}

const defaultProps: Partial<Props> = {
    title: 'Claim Data',
};

const ClaimDataDialog: React.FunctionComponent<Props> = (props) => {
    const { claimData, caption, readonly, isOpen, title, onCancel, onSubmit } = props;

    const onDialogSubmit = (data: Claim) => {
        onSubmit && onSubmit(data);
    };

    const onDialogCancel = () => {
        onCancel && onCancel();
    };

    return (
        <LrDialog isOpen={isOpen} caption={caption} onCancel={onDialogCancel} closeButton title={title} width="800px">
            <Typography variant={'h2'}>
                <b>
                    {`Total Incurred: ${formatDollar(claimData?.totalIncurred || 0, 0)}`}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {`Total Paid: ${formatDollar(claimData?.totalPaid || 0, 0)}`}
                </b>
            </Typography>
            <Formik
                initialValues={claimData || ({} as Claim)}
                isInitialValid={false}
                onSubmit={onDialogSubmit}
                validationSchema={readonly ? undefined : claimDataSchema}
                enableReinitialize
            >
                {({ errors, isValid, isSubmitting, touched, values }) => (
                    <Form>
                        <Grid container spacing={1}>
                            <Grid xs={4}>
                                <Field
                                    as={TextInput}
                                    name="claimNumber"
                                    title="Claim Number"
                                    readOnly={readonly}
                                    errorMessage={touched.claimNumber && errors.claimNumber}
                                />
                            </Grid>
                            <Grid xs={2}>
                                <Field
                                    as={TextInput}
                                    name="claimStatus"
                                    title="Status"
                                    readOnly={readonly}
                                    errorMessage={touched.claimStatus && errors.claimStatus}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <Field
                                    as={TextInput}
                                    name="cause"
                                    title="Cause"
                                    readOnly={readonly}
                                    errorMessage={touched.cause && errors.cause}
                                />
                            </Grid>
                            <Grid xs={3}>
                                <Field
                                    as={TextInput}
                                    name="catCode"
                                    title="CAT#"
                                    readOnly={readonly}
                                    errorMessage={touched.catCode && errors.catCode}
                                />
                            </Grid>
                            <Grid xs={3}>
                                <Field
                                    as={TextInput}
                                    name="adjusterName"
                                    title="Adjuster Name"
                                    readOnly={readonly}
                                    errorMessage={touched.adjusterName && errors.adjusterName}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <Field
                                    as={TextInput}
                                    name="adjusterEmail"
                                    title="Adjuster Email"
                                    readOnly={readonly}
                                    errorMessage={touched.adjusterEmail && errors.adjusterEmail}
                                />
                            </Grid>
                            <Grid xs={3}>
                                <Field
                                    as={TextInput}
                                    name="dateClaimReported"
                                    title="Date Reported"
                                    placeholder="MM/DD/YYYY"
                                    readOnly={readonly}
                                    errorMessage={touched.dateClaimReported && errors.dateClaimReported}
                                    mask={dateMask}
                                />
                            </Grid>
                            <Grid xs={3}>
                                <Field
                                    as={TextInput}
                                    name="dateOpened"
                                    title="Date Opened"
                                    placeholder="MM/DD/YYYY"
                                    readOnly={readonly}
                                    errorMessage={touched.dateOpened && errors.dateOpened}
                                    mask={dateMask}
                                />
                            </Grid>
                            <Grid xs={3}>
                                <Field
                                    as={TextInput}
                                    name="dateClosed"
                                    title="Date Closed"
                                    placeholder="MM/DD/YYYY"
                                    readOnly={readonly}
                                    errorMessage={touched.dateClosed && errors.dateClosed}
                                    mask={dateMask}
                                />
                            </Grid>
                            <Grid xs={3}>
                                <Field
                                    as={TextInput}
                                    name="dateReopened"
                                    title="Date Re-Opened"
                                    placeholder="MM/DD/YYYY"
                                    readOnly={readonly}
                                    errorMessage={touched.dateReopened && errors.dateReopened}
                                    mask={dateMask}
                                />
                            </Grid>
                            <Grid xs={3}>
                                <Field
                                    as={TextInput}
                                    name="incurredIndemnity"
                                    title="Incurred Indemnity"
                                    readOnly={readonly}
                                    errorMessage={touched.incurredIndemnity && errors.incurredIndemnity}
                                    mask={currencyMask}
                                />
                            </Grid>
                            <Grid xs={3}>
                                <Field
                                    as={TextInput}
                                    name="incurredExpense"
                                    title="IncurredExpense"
                                    readOnly={readonly}
                                    errorMessage={touched.incurredExpense && errors.incurredExpense}
                                    mask={currencyMask}
                                />
                            </Grid>
                            <Grid xs={3}>
                                <Field
                                    as={TextInput}
                                    name="paidIndemnity"
                                    title="Paid Indemnity"
                                    readOnly={readonly}
                                    errorMessage={touched.paidIndemnity && errors.paidIndemnity}
                                    mask={currencyMask}
                                />
                            </Grid>
                            <Grid xs={3}>
                                <Field
                                    as={TextInput}
                                    name="paidExpense"
                                    title="Paid Expense"
                                    readOnly={readonly}
                                    errorMessage={touched.paidExpense && errors.paidExpense}
                                    mask={currencyMask}
                                />
                            </Grid>
                            <Grid xs={3}>
                                <Field
                                    as={TextInput}
                                    value={values.incurredIndemnity - values.paidIndemnity}
                                    title="Reserved Indemnity"
                                    readOnly
                                    mask={currencyMask}
                                />
                            </Grid>
                            <Grid xs={3}>
                                <Field
                                    as={TextInput}
                                    value={values.incurredExpense - values.paidExpense}
                                    title="Reserved Expense"
                                    readOnly
                                    mask={currencyMask}
                                />
                            </Grid>
                            <Grid xs={3}>
                                <Field
                                    as={TextInput}
                                    name="recoveredIndemnity"
                                    title="Recovered Indemnity"
                                    readOnly={readonly}
                                    errorMessage={touched.recoveredIndemnity && errors.recoveredIndemnity}
                                    mask={currencyMask}
                                />
                            </Grid>
                            <Grid xs={3}>
                                <Field
                                    as={TextInput}
                                    name="recoveredExpense"
                                    title="Recovered Expense"
                                    readOnly={readonly}
                                    errorMessage={touched.recoveredExpense && errors.recoveredExpense}
                                    mask={currencyMask}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <Field
                                    as={TextInput}
                                    name="descriptionOfEvent"
                                    multiline
                                    rows={5}
                                    title="Description"
                                    readOnly={readonly}
                                    placeholder="Add a message"
                                    errorMessage={touched.descriptionOfEvent && errors.descriptionOfEvent}
                                />
                            </Grid>
                        </Grid>

                        <Spacing height="32px" />
                        <Grid container spacing={0} alignItems="center">
                            <LrButton
                                lrType="primary"
                                type="submit"
                                disabled={!readonly && !isValid}
                                isLoading={isSubmitting}
                            >
                                {readonly ? 'OK' : 'Submit'}
                            </LrButton>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </LrDialog>
    );
};

ClaimDataDialog.defaultProps = defaultProps;
export default ClaimDataDialog;
