import * as React from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { MessagingClient } from 'components/Messaging';

import { useSelector } from 'react-redux';
import { getTopLevelUserGroup } from 'application/redux/selectors';
import { getIsAgentRole } from 'helpers/roles';
import { createSubmissionMessage, getSubmissionMessages } from 'services/messaging';
import { MessagingChannelMessage } from '../../types/messaging.type';
import { MessagingClientAPI } from '../Messaging/components/MessagingClient';

interface SubmissionMessagingClientProps {
    submissionId: string;
    submissionName: string;
}

const SubmissionMessagingClient: React.RefForwardingComponent<MessagingClientAPI, SubmissionMessagingClientProps> = (
    props,
    ref
) => {
    const { submissionId, submissionName } = { ...props };
    const group = useSelector(getTopLevelUserGroup);
    const isLumen = !getIsAgentRole(group);
    const [initialSubmissionMessages, setInitialSubmissionMessages] = useState<MessagingChannelMessage[]>([]);

    const messagingClientAPI = useRef<MessagingClientAPI>(null);

    const onMessageEntered = (msg: string) => {
        const postMsg: MessagingChannelMessage = {
            message: msg,
            isLumen: isLumen,
        };
        createSubmissionMessage(submissionId, postMsg).then(() => {
            fetchSubmissionMessages();
        });
    };

    useEffect(() => {
        fetchSubmissionMessages();
    }, []);

    const fetchSubmissionMessages = async () => {
        const msgs = await getSubmissionMessages(submissionId);
        setInitialSubmissionMessages(msgs);
    };

    useImperativeHandle(ref, () => ({
        toggleShowClient: () => {
            messagingClientAPI.current && messagingClientAPI.current.toggleShowClient();
        },
    }));

    return (
        <MessagingClient
            ref={messagingClientAPI}
            onMessageEntered={onMessageEntered}
            messages={initialSubmissionMessages}
            isLumen={isLumen}
            transcriptHeaderText={`For ${submissionName}`}
        />
    );
};

const ForwardedSubmissionMessagingClient = forwardRef(SubmissionMessagingClient);
export default React.memo(ForwardedSubmissionMessagingClient);
