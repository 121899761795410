import { COLORS } from 'constants/index';

const MuiCard = {
    root: {
        backgroundColor: COLORS.WHITE,
        borderRadius: '8px',
        width: '100%',
        padding: '24px',
        boxShadow: '0 7px 20px 0 rgba(0,0,0,0.08)',
    },
};

export default MuiCard;
