import { createSelector } from 'reselect';

import ApplicationState from '../store/ApplicationState';
import CreateEndorsementContextState from '../store/CreateEndorsementContextState';

const createEndorsementContextState = (state: ApplicationState) => state.createEndorsementContext;
const data = (state: CreateEndorsementContextState) => state.data;
const error = (state: CreateEndorsementContextState) => state.error;
const isLoading = (state: CreateEndorsementContextState) => state.isLoading;
const hasResolved = (state: CreateEndorsementContextState) => state.hasResolved;

export const getCreateEndorsementContext = createSelector(createEndorsementContextState, data);

export const getCreateEndorsementContextLoading = createSelector(createEndorsementContextState, isLoading);

export const getCreateEndorsementContextHasResolved = createSelector(createEndorsementContextState, hasResolved);

export const getCreateEndorsementContextError = createSelector(createEndorsementContextState, error);
