import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import NavItem from './NavItem';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import { useSelector } from 'react-redux';
import { getLogTypesByCode, getTopLevelUserGroup } from '../../../application/redux/selectors';
import { getIsAdminRole } from '../../../helpers/roles';
import LrDialog from '../../LrDialog';
import Grid from '@mui/material/Unstable_Grid2';
import { Book } from '../../Layout';
import { LrNotes } from '../../LrNotes';
import { LogOptions } from '../../../types/logs.types';

// interface MarketIntelligenceNotesProps {
//     submission?: SubmissionDetails;
// }

const MarketIntelNotes: FunctionComponent = (props) => {
    const [showMINotes, setShowMINotes] = useState<boolean>(false);
    const group = useSelector(getTopLevelUserGroup);
    const isAdmin = getIsAdminRole(group);
    const logTypes = useSelector(getLogTypesByCode);
    const notesOptions = { logTypeId: logTypes.MARKET_INTEL.id } as LogOptions;

    const handleLinkClick = () => {
        setShowMINotes(true);
    };
    const getIconComponent = () => (
        <>
            <PsychologyAltIcon />
        </>
    );

    return (
        <>
            <NavItem onClick={handleLinkClick} icon={getIconComponent()}>
                {'Market Intel'}
            </NavItem>
            <LrDialog
                isOpen={showMINotes}
                title={'Market Intel Notes'}
                fullWidth
                maxWidth={'xl'}
                onCancel={() => setShowMINotes(false)}
                closeButton
            >
                <Grid xs={12}>
                    <Book>
                        <LrNotes
                            showChildTitles
                            canCreate
                            emptyText={'There are no Market Intel Notes yet.'}
                            noteCreateTypeId={logTypes.MARKET_INTEL.id}
                            options={notesOptions}
                        />
                    </Book>
                </Grid>
            </LrDialog>
        </>
    );
};

export default MarketIntelNotes;
