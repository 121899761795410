import { getHeaders, SubmissionURL } from './config';
import { checkStatus, getAll, getData } from './normalizer';
import { UserPayload } from '../types/users.types';

export async function getUsers() {
    return fetch(`${SubmissionURL}/users`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getAll);
}

export async function getOneUser(id: string) {
    return fetch(`${SubmissionURL}/users/${id}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getData);
}

export async function createUser(payload: UserPayload) {
    return fetch(`${SubmissionURL}/users`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    })
        .then(checkStatus)
        .then(getData);
}

export async function suspendUser(id: string) {
    return fetch(`${SubmissionURL}/users/${id}/suspend`, {
        method: 'PUT',
        headers: getHeaders(),
    }).then(checkStatus);
}

export async function unsuspendUser(id: string) {
    return fetch(`${SubmissionURL}/users/${id}/unsuspend`, {
        method: 'PUT',
        headers: getHeaders(),
    }).then(checkStatus);
}

export async function getUserOrganizations() {
    return fetch(`${SubmissionURL}/user/organizations`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getAll);
}

export async function getCurrentUser() {
    return fetch(`${SubmissionURL}/user`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getData);
}
