import { AmType } from 'types/types.type';

export interface TemplateTypesResponse {
    documentTypes: DocumentType[];
    documentTypeQualifiers: DocumentTypeQualifier[];
    templateTypes: AmType[];
    outputTypes: AmType[];
}

export interface FileNameResponse {
    fileName: string;
}

export interface DocumentType extends AmType {
    qualifierRequired: boolean;
    variables: Variable[];
    collectionVariables: CollectionVariable[];
    templates: Template[];
}

export interface DocumentTypeQualifier extends AmType {
    documentTypeId: string;
}

export interface CollectionVariable {
    variableName: string;
    variables: Variable[];
}

export interface Variable {
    variableName: string;
    formatType: string;
}

export interface Template {
    id?: string;
    storageGuid: string;
    fileName: string;
    isActive: boolean;
    documentTypeId: string;
    templateTypeId: string;
    documentTypeQualifier?: DocumentTypeQualifier | null;
    productLineType?: AmType | null;
    outputTypeId: string;
}

export interface GetTypesResponse {
    documentTypes: DocumentType[];
    templateTypes: AmType[];
    outputTypes: AmType[];
}

export enum OutputDocFileTypes {
    PDF,
    DOCX,
    TEXT,
}
