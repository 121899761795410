import { getHeaders, SubmissionURL } from './config';
import { checkStatus, getData, getAll } from './normalizer';
import { setTypes } from 'helpers/typeHelper';

export async function fetchLosses(submissionId: string, productLineCode?: string) {
    const url = `${SubmissionURL}/submission/${submissionId}/losses?productLineCode=${productLineCode}`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus).then(getAll).then(setTypes);
}

export async function fetchClaimsCount(submissionId: string) {
    const url = `${SubmissionURL}/claims/count?submissionId=${submissionId}`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus).then(getData);
}
