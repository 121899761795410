export const REDUX = {
    AUTH_LOGIN: 'AUTH_LOGIN',
    AUTH_LOGOUT: 'AUTH_LOGOUT',
    AUTH_LOGOUT_NEEDED: 'AUTH_LOGOUT_NEEDED',
    CLEAR_SELECTED_SUBMISSION: 'CLEAR_SELECTED_SUBMISSION',
    DIALOGS: 'DIALOGS',
    FETCH_ONE_SUBMISSION: 'FETCH_ONE_SUBMISSION',
    FETCH_SUBMISSIONS: 'FETCH_SUBMISSIONS',
    FETCH_REFERRAL_METADATA: 'FETCH_REFERRAL_METADATA',
    ENDORSEMENT_CONTEXT_SAVE: 'ENDORSEMENT_CONTEXT_SAVE',
    ENDORSEMENT_CONTEXT_CLEAR: 'ENDORSEMENT_CONTEXT_CLEAR',
    LOSSES: 'LOSSES',
    LOCATION_OPTIONS: 'LOCATION_OPTIONS',
    ORGANIZATIONS: 'ORGANIZATIONS',
    LR_GRID: 'LR_GRID',
    POLICY: 'POLICY',
    QUOTES: 'QUOTES',
    QUOTES_REVIEW: 'QUOTES_REVIEW',
    QUOTES_BIND_REVIEW: 'QUOTES_BIND_REVIEW',
    RESET_SUBMISSIONS: 'RESET_SUBMISSION',
    SUBMISSION_SAVE_UNDERWRITING_STATUS: 'SUBMISSION_SAVE_UNDERWRITING_STATUS',
    ASSIGN_AGENT_TO_SUBMISSION: 'ASSIGN_AGENT_TO_SUBMISSION',
    SOV: 'SOV',
    SOV_LOCATION_UW_NOTES: 'SOV_LOCATION_UW_NOTES',
    SOV_SAVE_PRODUCT_AVAILABLE_LOCATIONS: 'SOV_SAVE_PRODUCT_AVAILABLE_LOCATIONS',
    USERS: 'USERS',
    ACTIVE_USER: 'ACTIVE_USER',
    UW_REVIEW: 'UW_REVIEW',
    UW_REVIEW_SAVE_NOTES: 'UW_REVIEW_SAVE_NOTES',
    UW_REVIEW_SAVE_ONE_PROPERTY: 'UW_REVIEW_SAVE_ONE_PROPERTY',
    UW_REVIEW_SAVE_ALL_PROPERTIES: 'UW_REVIEW_SAVE_ALL_PROPERTIES',
    UW_REVIEW_SAVE_ALL_SUBJECTIVITIES: 'UW_REVIEW_SAVE_ALL_SUBJECTIVITIES',
    UW_REVIEW_SAVE_UW_REVIEW: 'UW_REVIEW_SAVE_UW_REVIEW',
    UW_REVIEW_SAVE_UW_GL_REVIEW: 'UW_REVIEW_SAVE_UW_GL_REVIEW',
    UW_REVIEW_SAVE_ONE_GL_PROPERTY: 'UW_REVIEW_SAVE_ONE_GL_PROPERTY',
    UW_REVIEW_SAVE_ALL_GL_PROPERTIES: 'UW_REVIEW_SAVE_ALL_GL_PROPERTIES',
    UW_REVIW_GL_INITIALIZED: 'UW_REVIW_GL_INITIALIZED',
    UW_REVIEWS: 'UW_REVIEWS',
    TYPES: 'TYPES',
    SUBMISSION_USER_PREFERENCES: 'SUBMISSION_USER_PREFERENCES',
    REPORTING_LOCATIONS: 'REPORTING_LOCATIONS',
    REPORTING_PRIMARY_LOADED: 'REPORTING_PRIMARY_LOADED',
    REPORTING_SELECTED_REPORT: 'REPORTING_SELECTED_REPORT',
    BUSINESS_DATA_CURRENT_TREATIES: 'BUSINESS_DATA_CURRENT_TREATIES',
    BUSINESS_DATA_ALL_TREATIES: 'BUSINESS_DATA_ALL_TREATIES',
};

export const REDUX_PERSIST = {
    ROOT: 'root',
    AUTH: 'auth',
    CLEARING: 'clearing',
    SOV: 'sov',
    SUBMISSIONS: 'submissions',
    SUBMISSION_USER_PREFERENCES: 'submission_user_preferences',
};
