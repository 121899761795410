import * as React from 'react';
import { Dialog, DialogContent, DialogActions, Typography, DialogTitle, IconButton, Theme } from '@mui/material';
import LrButton from 'components/LrButton';
import { Spacing } from 'components/Layout';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

export interface LrDialogBaseProps {
    caption?: string;
    content?: React.ReactNode;
    fullWidth?: boolean;
    isOpen?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    title?: string;
    width?: string;
    onSubmit?: (result?: any) => void;
    onCancel?: () => void;
    closeButton?: boolean;
}

interface LrDialogProps extends LrDialogBaseProps {
    cancelButtonText?: string;
    children?: React.ReactNode;
    isLoading?: boolean;
    isSubmitEnabled?: boolean;
    submitButtonText?: string;
}

const defaultProps = {
    width: '400px',
    isSubmitEnabled: true,
};

const useStyles = makeStyles((theme: Theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const LrDialog: React.FunctionComponent<LrDialogProps> = (props) => {
    const {
        cancelButtonText,
        caption,
        children,
        content,
        fullWidth,
        isLoading,
        isOpen,
        isSubmitEnabled,
        maxWidth,
        onCancel,
        onSubmit,
        closeButton,
        submitButtonText,
        title,
        width,
    } = props;

    const classes = useStyles();

    if (!isOpen) return <></>;
    const areActionsVisible = () => !!cancelButtonText || !!submitButtonText;
    const contentWidth = fullWidth ? '100%' : width;
    const spaceAfterTitle = children && !content ? '24px' : '8px';

    return (
        <Dialog open={isOpen} maxWidth={maxWidth || 'lg'} fullWidth={fullWidth} onClose={onCancel}>
            <div style={{ width: contentWidth }}>
                <DialogContent>
                    {caption && <Typography variant="body2">{caption}</Typography>}
                    {title && <Typography variant="h2">{title}</Typography>}
                    {onCancel && closeButton ? (
                        <IconButton className={classes.closeButton} onClick={onCancel} size="large">
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                    {title && (content || children) && <Spacing height={spaceAfterTitle} />}
                    {content && <Typography variant="body1">{content}</Typography>}
                    {content && children && <Spacing height="8px" />}
                    {children && children}
                </DialogContent>
                {areActionsVisible() && (
                    <DialogActions>
                        <LrButton lrType="text" hidden={!cancelButtonText} size="large" onClick={onCancel}>
                            {cancelButtonText}
                        </LrButton>
                        <LrButton
                            lrType="primary"
                            type="submit"
                            disabled={!isSubmitEnabled}
                            isLoading={isLoading}
                            hidden={!submitButtonText}
                            size="large"
                            onClick={onSubmit}
                        >
                            {submitButtonText}
                        </LrButton>
                    </DialogActions>
                )}
            </div>
        </Dialog>
    );
};

LrDialog.defaultProps = defaultProps;
export default LrDialog;
