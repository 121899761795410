import moment from 'moment';
import { formatDollar } from 'helpers/formatters';
import { store } from 'application/redux/store';
import { signInError } from 'application/redux/actions';
import HTTPError from '../helpers/error';

function compareCreateDate(a: any, b: any) {
    // TODO: fix - change createDate to createdAt for consistency
    if (moment(a.createDate).isBefore(b.createDate)) return 1;
    if (moment(a.createDate).isAfter(b.createDate)) return -1;
    return 0;
}

function compareCreatedAt(a: any, b: any) {
    if (moment(a.createdAt).isBefore(b.createdAt)) return 1;
    if (moment(a.createdAt).isAfter(b.createdAt)) return -1;
    return 0;
}

// base normalizer functions
async function throwError(res: Response, msg?: string) {
    return res.json().then((err) => {
        throw new HTTPError(res.status, msg || err.message);
    });
}

// TODO: move formatting somewhere else
function formatValues(data: any) {
    const formattedData = {};
    Object.keys(data).map((key) => {
        // Logic copied from application/Providers/UWSubmission/FetchSubmissionsProvider/UWSubmissionFetchSubmissionsJSONSerialization.tsx
        if (
            (key === 'dateSubmitted' ||
                key === 'effectiveDate' ||
                key === 'expirationDate' ||
                key === 'requestedEffectiveDate' ||
                key === 'submissionEffectiveDate') &&
            typeof data[key] === 'string'
        ) {
            return (formattedData[key] = moment(data[key]).format('MM/DD/YYYY'));
        } else if (key === 'insuranceToValue' || key === 'averageRents') {
            return (formattedData[key] = formatDollar(data[key]));
        } else if (key === 'totalInsurableValue' || key === 'maxTivPerBuilding') {
            return (formattedData[key] = formatDollar(data[key], 0));
        } else if (typeof data[key] === 'number') {
            return (formattedData[key] = data[key].toString());
            // } else if (Array.isArray(data[key]) && key === 'quotes'){
            //     return (formattedData[key] = data[key].map((item: any) => formatValues(item)));
        } else if (data[key] === null) {
            return '';
        } else {
            return (formattedData[key] = data[key]);
        }
    });
    return formattedData;
}

export function checkStatus(response: Response): Response | any {
    const status = response.status;
    switch (true) {
        case status === 401:
            // TODO: (future) figure out session timeout
            return signInError(`401 returned from ${response.url}`)(store.dispatch);
        case status >= 200 && status < 300:
            return response;
        default:
            return throwError(response);
    }
}

export const getText = (resp: Response): Promise<string> => resp.text();

export const getData = (resp: Response): any => resp.json().then((data) => formatValues(data));
export const getRawData = (resp: Response): any => resp.json();

export const getAll = (resp: Response) => resp.json().then((data) => data.map((obj: any) => formatValues(obj)));

export const sortByCreatedAt = (data: any[], compareFn?: any) => data.sort(compareFn || compareCreatedAt);
export const sortByCreateDate = (data: any[]) => data.sort(compareCreateDate);
