import { COLORS } from 'constants/index';
import { radioClasses } from '@mui/material/Radio';

const MuiRadio = {
    colorPrimary: {
        color: COLORS.INFO,
        [`& .${radioClasses.checked}`]: {
            color: COLORS.INFO,
        },
    },
};

export default MuiRadio;
