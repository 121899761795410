import { combineReducers, AnyAction } from 'redux';
import { REDUX } from 'constants/index';
import ReportingState, { ReportingStateLocationData } from '../store/ReportingState';
import { ReportingLocation } from '../../../types/reporting.type';

const initialLocationState: ReportingStateLocationData = {
    data: [] as ReportingLocation[],
    isLoading: false,
    error: '',
};

const initialState: ReportingState = {
    reportingLocations: initialLocationState,
    reportingPrimaryLoaded: false,
    reportingSelectedReport: '',
};

const reportingLocations = (state = initialState.reportingLocations, action: AnyAction): ReportingStateLocationData => {
    switch (action.type) {
        case `${REDUX.REPORTING_LOCATIONS}/SUCCESS`: {
            return {
                ...state,
                data: action.payload,
            };
        }
        case `${REDUX.REPORTING_LOCATIONS}/LOADING`: {
            return { ...initialLocationState, isLoading: true };
        }
        case `${REDUX.REPORTING_LOCATIONS}/ERROR`: {
            return { ...initialLocationState, error: action.payload || 'Error Occurred' };
        }
        default:
            return state;
    }
};

const reportingPrimaryLoaded = (state = initialState.reportingPrimaryLoaded, action: AnyAction): boolean => {
    switch (action.type) {
        case `${REDUX.REPORTING_PRIMARY_LOADED}`: {
            return action.payload;
        }
        default:
            return state;
    }
};

const reportingSelectedReport = (state = initialState.reportingSelectedReport, action: AnyAction): string => {
    switch (action.type) {
        case `${REDUX.REPORTING_SELECTED_REPORT}`: {
            return action.payload;
        }
        default:
            return state;
    }
};

export default combineReducers({
    reportingLocations,
    reportingPrimaryLoaded,
    reportingSelectedReport,
});
