import React, { useEffect, useState } from 'react';
import LrDialog from '../../LrDialog';
import { Link, Table, TableBody, TableRow, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import logo from 'assets/logo_footer.png';
import { getDocumentServiceVersion, getSubmissionServiceVersion } from '../../../services/about';
import MuiTableCell from '@mui/material/TableCell';
import ViewPDF from '../../ViewPDF';
import { withStyles } from '@mui/styles';

const TableCell = withStyles({
    root: {
        borderBottom: 'none',
        padding: 0,
    },
})(MuiTableCell);

interface LrAboutDialogProps {
    show: boolean;
    onClose: () => void;
}

const LrAboutDialog: React.FunctionComponent<LrAboutDialogProps> = (props) => {
    const { show, onClose } = { ...props };
    const [submissionVersion, setSubmissionVersion] = useState<string>('');
    const [documentVersion, setDocumentVersion] = useState<string>('');
    const [showHelp, setShowHelp] = useState<boolean>(false);

    const getversions = async () => {
        try {
            const sVer = await getSubmissionServiceVersion();
            if (sVer) {
                setSubmissionVersion(sVer);
            }
        } catch (e) {
            // do nothing
        }
        try {
            const dVer = await getDocumentServiceVersion();
            if (dVer) {
                setDocumentVersion(dVer);
            }
        } catch (e) {
            // do nothing
        }
    };

    useEffect(() => {
        getversions();
    }, []);

    return (
        <>
            <ViewPDF
                isOpen={showHelp}
                fileName={'Lumen Portal Instructions.pdf'}
                fileTitle={'Kurrent Help'}
                onClose={() => setShowHelp(false)}
            />
            <LrDialog
                title={'About Kurrent™'}
                submitButtonText={'OK'}
                width={'600px'}
                isOpen={show}
                onSubmit={() => onClose && onClose()}
                onCancel={() => onClose && onClose()}
            >
                <Grid container>
                    <Grid container justifyContent={'center'} xs={12}>
                        <Grid>
                            <img width={'225px'} src={logo} />
                        </Grid>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            For general help please reference this{' '}
                            <Link
                                underline={'always'}
                                onClick={() => setShowHelp(true)}
                                href={'#'}
                                // href={'https://lumenrisk.com/docs/Lumen%20Portal%20Instructions.pdf'}
                                // target={'_blank'}
                            >
                                document
                            </Link>{' '}
                            or contact us at{' '}
                            <Link href={'mailto:help@lumenrisk.com'} underline="hover">
                                help@lumenrisk.com
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography variant={'subtitle1'}>System Component Versions:</Typography>
                        <Table size={'small'}>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ width: '24px' }}>
                                        <Typography variant={'subtitle1'}>K:</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant={'subtitle1'}>
                                            {process.env.REACT_APP_BUILD_VERSION ||
                                                process.env.REACT_APP_PACKAGE_VERSION ||
                                                '1.0.0'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {submissionVersion && (
                                    <TableRow>
                                        <TableCell style={{ width: '24px' }}>
                                            <Typography variant={'subtitle1'}>S:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={'subtitle1'}>{submissionVersion}</Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {documentVersion && (
                                    <TableRow>
                                        <TableCell style={{ width: '24px' }}>
                                            <Typography variant={'subtitle1'}>D:</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant={'subtitle1'}>{documentVersion}</Typography>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </LrDialog>
        </>
    );
};

export default LrAboutDialog;
