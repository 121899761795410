import { NAV_ACTIONS, getAvailableActions } from './actions';
import { DETAIL_LINKS, getAvailableDetailLinks } from './links';
import { SubmissionDetails } from 'types/submission.type';
import { ROUTES } from '../../constants';

const getLinkForSubmissionPage = (page: string) => {
    switch (page) {
        case ROUTES.notes():
            return NAV_ACTIONS.NOTES;
        case ROUTES.clearing():
            return DETAIL_LINKS.CLEARING;
        case ROUTES.quotes():
            return DETAIL_LINKS.PREPARE_QUOTES;
        case ROUTES.quoteReview():
            return DETAIL_LINKS.QUOTE_REVIEW;
        case ROUTES.quotesView():
            return DETAIL_LINKS.QUOTES_VIEW;
        case ROUTES.bindRequest():
            return DETAIL_LINKS.BIND_REQUEST_REVIEW;
        case ROUTES.endorsememtReview():
            return DETAIL_LINKS.ENDORSEMENT_REVIEW;
        case ROUTES.issuance():
            return DETAIL_LINKS.ISSUANCE;
        case ROUTES.overview():
            return DETAIL_LINKS.OVERVIEW;
        case ROUTES.financial():
            return DETAIL_LINKS.FINANCIAL;
        case ROUTES.SOV():
            return DETAIL_LINKS.SOV;
        case ROUTES.sovMap():
            return DETAIL_LINKS.SOV_MAP;
        case ROUTES.lossSummary():
            return DETAIL_LINKS.LOSS_SUMMARY;
        case ROUTES.submissionAgentDashboard():
            return DETAIL_LINKS.AGENT_DASHBOARD;
        case ROUTES.uwReview():
            return DETAIL_LINKS.UW_REVIEW;
        case ROUTES.location():
            return DETAIL_LINKS.LOCATION;
        case ROUTES.referrals():
            return DETAIL_LINKS.REFERRALS;
        default:
            return '';
    }
};

export const isPageAvailable = (page: string, group: string, submission: SubmissionDetails) => {
    if (!submission) return false;

    const link = getLinkForSubmissionPage(page);
    // TODO why is "notes" here so special? refactor to remove this special case
    const links =
        page === ROUTES.notes() ? getAvailableActions(group, submission) : getAvailableDetailLinks(group, submission);
    return links.includes(link);
};
