import { getHeaders, SubmissionURL } from './config';
import { checkStatus, getData } from './normalizer';

export async function fetchTypes() {
    const url = `${SubmissionURL}/types`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus).then(getData);
}

export async function fetchLocationOptions() {
    const url = `${SubmissionURL}/locationOptions`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus).then(getData);
}
