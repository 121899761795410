import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import {
    Checkbox,
    ClickAwayListener,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popper,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

interface Props {
    defaultFilterValues: string[];
    filterOptions: string[];
    onFilterOptionsChanged: (filtered: string[]) => void;
    singleSelect?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            boxShadow: '0 7px 20px 0 rgba(0,0,0,0.08)',
            borderRadius: '8px',
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper,
        },
    })
);

const FilterOptionPopper: FunctionComponent<Props> = (props) => {
    const { defaultFilterValues, filterOptions, onFilterOptionsChanged, singleSelect } = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [checked, setChecked] = React.useState<string[]>(defaultFilterValues);

    useEffect(() => {
        onFilterOptionsChanged && onFilterOptionsChanged(checked);
    }, [checked]);

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = singleSelect ? [] : [...checked];

        if (currentIndex === -1 || singleSelect) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleToggleAll = () => {
        if (checked.length === filterOptions.length) {
            setChecked([]);
        } else {
            setChecked([...filterOptions]);
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <>
            <IconButton onClick={handleClick}>
                <FilterAltOutlinedIcon />
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl}>
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <div className={classes.paper}>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {!singleSelect && (
                                <ListItem key={'checkAllItem'} disablePadding>
                                    <ListItemButton role={undefined} onClick={handleToggleAll} dense>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={checked.length === filterOptions.length}
                                                disableRipple
                                                indeterminate={
                                                    checked.length > 0 && checked.length < filterOptions.length
                                                }
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                style: { marginTop: '4px', marginBottom: '4px' },
                                            }}
                                            primary={'All Types'}
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )}
                            {filterOptions.map((value) => {
                                const labelId = `checkbox-list-label-${value}`;

                                return (
                                    <ListItem key={value} disablePadding>
                                        <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(value) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    style: { marginTop: '4px', marginBottom: '4px' },
                                                }}
                                                id={labelId}
                                                primary={value}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </div>
                </ClickAwayListener>
            </Popper>
        </>
    );
};

export default FilterOptionPopper;
