import { createSelector } from 'reselect';

import ApplicationState from 'application/redux/store/ApplicationState';
import { LocationOptionsState } from 'types/types.type';

const locationOptionsState = (state: ApplicationState) => state.locationOptions;
const data = (state: LocationOptionsState) => state.data;
const error = (state: LocationOptionsState) => state.error;
const loading = (state: LocationOptionsState) => state.isLoading;
const hasResolved = (state: LocationOptionsState) => state.hasResolved;

export const getLocationOptionsError = createSelector(locationOptionsState, error);

export const getLocationOptionsLoading = createSelector(locationOptionsState, loading);

export const getLocationOptionsResolved = createSelector(locationOptionsState, hasResolved);

export const getLocationOptionsData = createSelector(locationOptionsState, data);
