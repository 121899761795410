const MuiDialogContent = {
    root: {
        padding: '36px',
        '&:first-child': {
            paddingTop: '36px',
        },
    },
};

export default MuiDialogContent;
