import { getReasonPhrase } from 'http-status-codes';

class HTTPError extends Error {
    public statusCode: number;
    constructor(code: number, message: string, extras?: any) {
        super(message || getReasonPhrase(code));
        if (arguments.length >= 3 && extras) {
            Object.assign(this, extras);
        }
        this.name = this.toName(code);
        this.statusCode = code;
    }

    toName(code: number) {
        const suffix = Math.floor(code / 100) === 4 || Math.floor(code / 100) === 5 ? 'error' : '';
        return String(getReasonPhrase(code)).replace(/error$/i, '') + suffix;
    }
}
export default HTTPError;
