import { Dispatch } from 'redux';
import { getSubmissions, getOneSubmission } from 'services/submission';
import actionCreator from '../lib/actionCreator';
import { getGridAction } from '../lib/gridActionCreator';
import { REDUX, GRID_NAMES } from 'constants/index';
import { SubmissionUnderwritingStatus } from '../../../types/submission.type';

export const fetchOneSubmissionAction = actionCreator(REDUX.FETCH_ONE_SUBMISSION);
export const fetchSubmissionsAction = actionCreator(REDUX.FETCH_SUBMISSIONS);

export const saveSubmissionsGrid = getGridAction(GRID_NAMES.SUBMISSIONS);

export function fetchSubmissions(producerId?: string, downloadAll: boolean = true) {
    return async (dispatch: Dispatch) => {
        dispatch(fetchSubmissionsAction.loading());

        getSubmissions(producerId, !downloadAll)
            .then((submissions) => {
                dispatch(fetchSubmissionsAction.success(submissions));
            })
            .catch((reason) => {
                dispatch(fetchSubmissionsAction.error(reason));
            });
    };
}

export function fetchOneSubmission(id: string) {
    return async (dispatch: Dispatch) => {
        dispatch(fetchOneSubmissionAction.loading());

        getOneSubmission(id)
            .then((submission) => {
                dispatch(fetchOneSubmissionAction.success(submission));
            })
            .catch((reason) => {
                dispatch(fetchOneSubmissionAction.error(reason));
            });
    };
}

export function saveSubmissionUnderwritingStatus(newStatus: SubmissionUnderwritingStatus) {
    return (dispatch: Dispatch) => {
        dispatch({ type: REDUX.SUBMISSION_SAVE_UNDERWRITING_STATUS, payload: newStatus });
    };
}

export function saveSubmissionUWNotes(notes: string) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: REDUX.UW_REVIEW_SAVE_NOTES, payload: notes });
    };
}

export function clearSelectedSubmission() {
    return (dispatch: Dispatch) => {
        dispatch({ type: REDUX.CLEAR_SELECTED_SUBMISSION });
    };
}
