import * as React from 'react';
import ExpireIcon from '@mui/icons-material/VisibilityOff';
import UnExpireIcon from '@mui/icons-material/Visibility';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import UpdateIcon from '@mui/icons-material/Update';
import EditIcon from '@mui/icons-material/Edit';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { OKTA_GROUPS, STATUS_TYPES, DIALOG_NAMES, ROUTES } from 'constants/index';
import { AmType } from 'types/types.type';
import { SubmissionDetails } from 'types/submission.type';
import SubmissionMessagingAction from 'components/SideNav/components/SubmissionMessagingAction';
import CreateClaimAction from 'components/SideNav/components/CreateClaimAction';
import { NavAction } from 'types/nav.types';
import SubmissionNotesAction from 'components/SideNav/components/SubmissionNotesAction';

export const NAV_ACTIONS = {
    NOTES: 'Notes',
    VOID: 'Void Submission',
    RESTORE: 'Reactivate Submission',
    LOCS_ADD: 'Add Locations',
    LOCS_REMOVE: 'Remove Locations',
    EDIT_POLICY: 'Edit Policy Info',
    CANCEL: 'Cancel Policy',
    RENEW: 'Renew Policy',
    SUBMISSION_MESSAGES: 'Messages',
    CREATE_CLAIM: 'Create a Claim',
    NOTICES: 'Generate Notices',
    EXTEND: 'Offer Extension',
};

const nonAgentActions = [
    NAV_ACTIONS.NOTES,
    NAV_ACTIONS.SUBMISSION_MESSAGES,
    NAV_ACTIONS.CREATE_CLAIM,
    NAV_ACTIONS.VOID,
    NAV_ACTIONS.RESTORE,
    NAV_ACTIONS.CANCEL,
    NAV_ACTIONS.NOTICES,
    NAV_ACTIONS.EXTEND,
];
const managerActions = [...nonAgentActions];
const agents = [
    NAV_ACTIONS.NOTES,
    NAV_ACTIONS.SUBMISSION_MESSAGES,
    NAV_ACTIONS.LOCS_ADD,
    NAV_ACTIONS.LOCS_REMOVE,
    NAV_ACTIONS.EDIT_POLICY,
    NAV_ACTIONS.CANCEL,
    NAV_ACTIONS.RENEW,
];

const canBeVoided = (statusType: AmType) => {
    return (
        statusType.code !== STATUS_TYPES.BOUND &&
        statusType.code !== STATUS_TYPES.ISSUED &&
        statusType.code !== STATUS_TYPES.VOIDED
    );
};
const canBeRestored = (statusType: AmType) => {
    return statusType.code === STATUS_TYPES.VOIDED;
};
const canBeRenewed = (submission: SubmissionDetails) => {
    return submission.isExpiring;
};

const getActionsForGroup = (group: string) => {
    switch (group) {
        case OKTA_GROUPS.AGENT:
            return agents;
        case OKTA_GROUPS.ACCOUNT_MANAGER:
            return managerActions;
        case OKTA_GROUPS.ADMIN:
        case OKTA_GROUPS.UNDERWRITER:
            return nonAgentActions;
        default:
            return [];
    }
};

const isActionAvailable = (action: string, submission: SubmissionDetails) => {
    const { statusType } = submission;
    switch (action) {
        case NAV_ACTIONS.LOCS_ADD:
        case NAV_ACTIONS.LOCS_REMOVE:
        case NAV_ACTIONS.EDIT_POLICY:
        case NAV_ACTIONS.CANCEL:
        case NAV_ACTIONS.CREATE_CLAIM:
        case NAV_ACTIONS.NOTICES:
        case NAV_ACTIONS.EXTEND:
            return statusType.code === STATUS_TYPES.ISSUED && !submission.isEndorsement;
        case NAV_ACTIONS.VOID:
            return canBeVoided(statusType);
        case NAV_ACTIONS.RESTORE:
            return canBeRestored(statusType);
        case NAV_ACTIONS.NOTES:
            return true;
        case NAV_ACTIONS.SUBMISSION_MESSAGES:
            return statusType.code !== STATUS_TYPES.VOIDED;
        case NAV_ACTIONS.RENEW:
            return canBeRenewed(submission);
        default:
            return false;
    }
};

const getActionDetails = (action: string, submission: SubmissionDetails, openDialog?: (name: string) => void) => {
    switch (action) {
        case NAV_ACTIONS.VOID:
            return {
                onClick: () => {
                    openDialog && openDialog(DIALOG_NAMES.VOID);
                },
                icon: <ExpireIcon fontSize="small" />,
            };
        case NAV_ACTIONS.RESTORE:
            return {
                onClick: () => {
                    openDialog && openDialog(DIALOG_NAMES.RESTORE);
                },
                icon: <UnExpireIcon fontSize="small" />,
            };
        case NAV_ACTIONS.SUBMISSION_MESSAGES:
            return {
                actionComponent: (key: string | number, props: NavAction) => (
                    <SubmissionMessagingAction submission={submission} key={key} linkProps={props} />
                ),
            };
        case NAV_ACTIONS.CREATE_CLAIM:
            return {
                actionComponent: (key: string | number, props: NavAction) => (
                    <CreateClaimAction key={key} linkProps={props} />
                ),
            };
        case NAV_ACTIONS.NOTES:
            return {
                to: ROUTES.notes(submission.id),
                actionComponent: (key: string | number, props: NavAction) => (
                    <SubmissionNotesAction submission={submission} key={key} linkProps={props} />
                ),
            };
        case NAV_ACTIONS.LOCS_ADD:
            return {
                to: `/submission/form/new/details?endorsingId=${submission.id}`,
                icon: <AddCircleOutlineIcon fontSize="small" />,
            };
        case NAV_ACTIONS.LOCS_REMOVE:
            return {
                onClick: () => openDialog && openDialog(DIALOG_NAMES.REMOVAL),
                icon: <RemoveCircleOutlineIcon fontSize="small" />,
            };
        case NAV_ACTIONS.EDIT_POLICY:
            return {
                onClick: () => openDialog && openDialog(DIALOG_NAMES.EDIT),
                icon: <EditIcon fontSize="small" />,
            };
        case NAV_ACTIONS.CANCEL:
            return {
                onClick: () => openDialog && openDialog(DIALOG_NAMES.CANCEL),
                icon: <CancelPresentationIcon fontSize="small" />,
            };
        case NAV_ACTIONS.NOTICES:
            return {
                onClick: () => openDialog && openDialog(DIALOG_NAMES.NOTICES),
                icon: <NoteAddIcon fontSize="small" />,
            };
        case NAV_ACTIONS.RENEW:
            return {
                onClick: () => openDialog && openDialog(DIALOG_NAMES.RENEW),
                icon: <AutorenewIcon fontSize="small" />,
            };
        case NAV_ACTIONS.EXTEND:
            return {
                onClick: () => openDialog && openDialog(DIALOG_NAMES.EXTEND),
                icon: <UpdateIcon fontSize="small" />,
            };
        default:
            return false;
    }
};

export const getAvailableActions = (group: string, submission: SubmissionDetails) => {
    const baseActions = getActionsForGroup(group);
    return baseActions.filter((a: string) => isActionAvailable(a, submission));
};

export const getNavActions = (group: string, submission?: SubmissionDetails, openDialog?: (name: string) => void) => {
    if (!submission) return [];
    const actions = getAvailableActions(group, submission);
    return actions.map((a: string) => ({
        label: a,
        ...getActionDetails(a, submission, openDialog),
    }));
};
