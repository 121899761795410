import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

export const commissionMask = [/\d/, /\d/];

export const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
export const dateMaskPipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
export const yearMask = (input: string) =>
    createNumberMask({
        prefix: '',
        allowDecimal: false,
        includeThousandsSeparator: false,
        integerLimit: 4,
        allowNegative: false,
    })(input);

export const phoneMask = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const zipCodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];

export const numberMaskWhole = (input: string) =>
    createNumberMask({ prefix: '', allowDecimal: false, includeThousandsSeparator: false, integerLimit: 2 })(input);

export const negativeNumberMask = (input: string) =>
    createNumberMask({
        prefix: '',
        allowDecimal: true,
        includeThousandsSeparator: false,
        integerLimit: 3,
        allowNegative: true,
    })(input);

export const numberMask = (input: string) =>
    createNumberMask({ prefix: '', allowDecimal: true, includeThousandsSeparator: true })(input);

export const currencyMask = (input: string, allowNegative?: boolean) =>
    createNumberMask({ prefix: '$', allowDecimal: true, includeThousandsSeparator: true, allowNegative })(input);

export const negativeCurrencyMask = (input: string) =>
    createNumberMask({ prefix: '-$', allowDecimal: true, includeThousandsSeparator: true })(input);

export const currencyMaskWithNegatives = (input: string) => currencyMask(input, true);
