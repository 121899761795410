import * as React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';

import { getTopLevelUserGroup, getUserGroups } from 'application/redux/selectors';
import { OKTA_GROUPS } from 'constants/index';
import { Spacing, Margin } from 'components/Layout';

import { BackLabel, BackLabelWrapper } from '../styled/SideNav';
import NavItem from './NavItem';
import { getIsAgentRole, getIsUnderwriterRole } from 'helpers/roles';
import { ReactFragment } from 'react';
import UwGuidelinesInfo from './UWGuidelinesInfo';
import { getConfigNavLinks, getNavLinks } from '../../../helpers/permissions';
import { NavAction, NavLink } from '../../../types/nav.types';

interface Props extends RouteComponentProps {
    backLabel?: string;
    backPath?: string;
    onBackClick?: () => void;
    isLoading?: boolean;
    showNav?: boolean;
    links?: NavLink[];
    actions?: NavAction[];
}

const SideNav: React.FunctionComponent<Props> = (props) => {
    const { actions, backLabel, backPath, onBackClick, children, history, isLoading, links, showNav } = props;

    // Global State
    const userGroups = useSelector(getUserGroups);
    const topGroup = useSelector(getTopLevelUserGroup);
    const isAdmin = userGroups.includes(OKTA_GROUPS.ADMIN);
    const isAgent = getIsAgentRole(topGroup);
    const isUnderwriter = getIsUnderwriterRole(topGroup);

    const renderHomeLink = () => (
        <>
            {isAgent && (
                <NavItem to="/agentdashboard" icon={<DashboardIcon />}>
                    Dashboard
                </NavItem>
            )}
            {!isAgent && (
                <NavItem to="/lumendashboard" icon={<DashboardIcon />}>
                    Dashboard
                </NavItem>
            )}
        </>
    );

    const navLinks = getNavLinks(topGroup);
    const configNavLinks = getConfigNavLinks(topGroup);
    const renderNavLinks = () => (
        <div data-testid="nav-items">
            {navLinks &&
                navLinks.map((l, idx) => {
                    return l.actionComponent ? (
                        l.actionComponent(idx, l)
                    ) : (
                        <NavItem key={idx} to={l.to} icon={l.icon}>
                            {l.label}
                        </NavItem>
                    );
                })}
        </div>
    );

    const renderConfigNavLinks = () => (
        <div data-testid="nav-items">
            {configNavLinks &&
                configNavLinks.map((l, idx) => {
                    return l.actionComponent ? (
                        l.actionComponent(idx, l)
                    ) : (
                        <NavItem key={idx} to={l.to} icon={l.icon}>
                            {l.label}
                        </NavItem>
                    );
                })}
        </div>
    );

    const renderActions = () => (
        <div data-testid="nav-actions">
            <Spacing height="24px" />
            <Divider variant="middle" />
            <Spacing height="24px" />
            {actions &&
                actions.map((a, idx) =>
                    a.actionComponent ? (
                        a.actionComponent(idx, a)
                    ) : (
                        <NavItem key={idx} to={a.to} icon={a.icon} onClick={a.onClick}>
                            {a.label}
                        </NavItem>
                    )
                )}
        </div>
    );

    const renderLinks = () => (
        <div data-testid="detail-links">
            {links &&
                links.map((l, idx) => {
                    const step = l.isStep ? { isComplete: !!l.isStepComplete, number: idx + 1 } : undefined;
                    return l.actionComponent ? (
                        l.actionComponent(idx, l)
                    ) : (
                        <NavItem key={idx} to={l.to} nested={l.isNested} icon={l.icon} step={step}>
                            {l.label}
                        </NavItem>
                    );
                })}
        </div>
    );

    const renderNav = () => (
        <>
            {showNav && renderNavLinks()}
            {showNav && configNavLinks.length > 0 && (
                <>
                    <Spacing height={'8px'} />
                    <Divider variant="middle" />
                    <Spacing height={'8px'} />
                    {renderConfigNavLinks()}
                </>
            )}
            {links && renderLinks()}
            {showNav && children && <Divider variant="middle" />}
            {children}
            {actions && actions.length !== 0 && renderActions()}
        </>
    );

    const getInfoItems = (): ReactFragment[] => {
        const ret: ReactFragment[] = [];
        if (isUnderwriter || isAdmin) {
            ret.push(<UwGuidelinesInfo key={'UwGuidelinesInfo'} />);
        }
        return ret;
    };

    const infoItems = getInfoItems();

    const renderInfo = () => (
        <>
            {infoItems?.length > 0 && (
                <Grid style={{ marginBottom: '144px' }}>
                    <Divider variant="middle" />
                    <Spacing height={'12px'} />
                    {infoItems}
                </Grid>
            )}
        </>
    );

    const backClick =
        onBackClick ??
        (backPath
            ? () => {
                  history.push(backPath);
              }
            : undefined);
    return (
        <Grid container direction={'column'} justifyContent={'space-between'} xs={'auto'}>
            <Grid sx={{ minWidth: '350px' }}>
                {backLabel && backClick && (
                    <>
                        <BackLabelWrapper onClick={backClick}>
                            <KeyboardArrowLeft color="primary" />
                            <BackLabel>{backLabel}</BackLabel>
                        </BackLabelWrapper>
                        <Divider variant="middle" />
                    </>
                )}
                <Spacing height="24px" />
                {!isLoading && renderNav()}
            </Grid>
            {renderInfo()}
        </Grid>
    );
};

export default withRouter(SideNav);
