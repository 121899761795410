import { User } from 'types/users.types';
import { ActiveUserProducer } from 'types/types.type';
import { NullActiveProducer } from 'helpers/ActiveUserProducer/NullActiveProduder';
import { AgentActiveProducer } from 'helpers/ActiveUserProducer/AgentActiveProducer';
import { SupportActiveProducer } from 'helpers/ActiveUserProducer/SupportActiveProducer';
import { Organization } from 'types/organization.type';
import { getIsCarrierRepRole, getIsLumenRole } from 'helpers/roles';
import { LumenActiveUserProducer } from 'helpers/ActiveUserProducer/LumenActiveUserProducer';
import { CarrierRepActiveProducer } from 'helpers/ActiveUserProducer/CarrierRepActiveProducer';

function getIsProducerAgent(activeProducer: Organization | undefined) {
    return activeProducer && activeProducer.isLicensedAgent;
}

export function createActiveUserProducer(activeUser: User, topUserGroup: string): ActiveUserProducer {
    // TODO: if/when we support users associated with more than one producer OU this becomes some sort of UI selection
    const activeProducer =
        activeUser.organizationalUnits &&
        activeUser.organizationalUnits.find((o) => o.id === activeUser.organizationId);

    let ret: ActiveUserProducer = new NullActiveProducer();

    if (!activeProducer) {
        // return an ActiveUserProducer object that represents a user not associated with an org
        return ret;
    }

    if (getIsLumenRole(topUserGroup)) {
        // any non-agents are currently Lumen Users
        ret = new LumenActiveUserProducer();
    } else if (getIsCarrierRepRole(topUserGroup)) {
        ret = new CarrierRepActiveProducer();
    } else if (getIsProducerAgent(activeProducer)) {
        ret = new AgentActiveProducer(activeProducer);
    } else {
        ret = new SupportActiveProducer(activeProducer);
    }
    return ret;
}

export function getProducer(orgProducer: Organization) {
    let ret: ActiveUserProducer = new NullActiveProducer();
    if (getIsProducerAgent(orgProducer)) {
        ret = new AgentActiveProducer(orgProducer);
    } else {
        ret = new SupportActiveProducer(orgProducer);
    }
    return ret;
}
