import { DocumentURL, SubmissionURL } from './config';
import { checkStatus, getText } from './normalizer';

export async function getSubmissionServiceVersion() {
    const url = `${SubmissionURL}/version`;
    const options = {
        method: 'GET',
    };
    return fetch(url, options).then(checkStatus).then(getText);
}

export async function getDocumentServiceVersion() {
    const url = `${DocumentURL}/version`;
    const options = {
        method: 'GET',
    };
    return fetch(url, options).then(checkStatus).then(getText);
}
