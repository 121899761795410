const MuiInputBase = {
    root: {
        width: '100%',
    },
    inputMultiline: {
        border: 'none',
    },
};

export default MuiInputBase;
