import * as React from 'react';

import LrDialog, { LrDialogBaseProps } from 'components/LrDialog';
import { LogOptions } from 'types/logs.types';
import NotesInput from './NotesInput';

interface Props extends LrDialogBaseProps {
    doCreate?: boolean;
    options: LogOptions;
    defaultNoteText?: string;
}

const defaultProps: Partial<Props> = {
    doCreate: true,
};

const NotesDialog: React.FunctionComponent<Props> = (props) => {
    const { caption, content, isOpen, onSubmit, onCancel, doCreate, options, title, defaultNoteText } = props;

    return (
        <LrDialog isOpen={isOpen} caption={caption} content={content} title={title} width="800px">
            <NotesInput
                options={options}
                doCreate={doCreate}
                showCancel
                onCancel={onCancel}
                onNoteSubmitted={onSubmit}
                suppressSuccessMessage
                flat
                defaultNoteText={defaultNoteText || ''}
            />
        </LrDialog>
    );
};

NotesDialog.defaultProps = defaultProps;
export default NotesDialog;
