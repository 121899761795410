import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FunctionComponent, useEffect } from 'react';

interface TimeoutDialogProps {
    handleLogout: () => void;
    handleClose: () => void;
}

const TimeoutDialog: FunctionComponent<TimeoutDialogProps> = (props) => {
    const { handleLogout, handleClose } = props;

    const [secondsRemaining, setSecondsRemaining] = React.useState(30);
    const [dialogOpen, setDialogOpen] = React.useState(true);

    const _closeDialog = () => {
        setDialogOpen(false);
        handleClose && handleClose();
    };

    const _handleLogout = () => {
        if (dialogOpen) {
            setDialogOpen(false);
            handleLogout && handleLogout();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (secondsRemaining <= 0) {
                _handleLogout();
            } else {
                setSecondsRemaining(secondsRemaining - 1);
            }
        }, 1000);
    });

    return (
        <Dialog PaperProps={{ style: { backgroundColor: 'white' } }} open={dialogOpen} onClose={_closeDialog}>
            <DialogTitle>Inactivity Detected</DialogTitle>
            <DialogContent>
                <Typography paragraph={true}>You will be logged off in {secondsRemaining} seconds.</Typography>

                <Typography paragraph={true}>Do you want to continue your session?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={_handleLogout} color={'secondary'}>
                    Logout
                </Button>

                <Button onClick={_closeDialog} color={'primary'}>
                    Stay
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TimeoutDialog;
