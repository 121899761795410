import typography from '../typography';

const MuiFormLabel = {
    root: {
        ...typography.caption,
        fontSize: '16px',
        overflow: 'visible',
        '&.MuiInputLabel-root': {
            overflow: 'visible',
        },
    },
};

export default MuiFormLabel;
