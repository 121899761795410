import * as React from 'react';
import InputWrapper from './InputWrapper';
import { GridSpacing } from '@mui/material/Grid';
import { CircularProgress, Link, Typography, Tooltip } from '@mui/material';
import FileItem from 'components/LrFileItems/components/FileItem';
import { formatModString, formatMultiLineString } from 'helpers/formatters';
import { Document2 } from '../../../types/document.type';
import { ReactNode } from 'react';

interface Props {
    spacing?: GridSpacing;
    title?: React.ReactNode;
    value?: any;
    modValue?: any;
    documents?: Document2[];
    multiline?: boolean;
    loading?: boolean;
    tooltipText?: string | ReactNode;
    onClick?: () => void;
}

const LabeledData: React.FunctionComponent<Props> = (props) => {
    const { title, spacing, value, modValue, documents, multiline, onClick, loading, tooltipText } = props;

    const getText = () => {
        let ret = value;
        if (!value) ret = '--';
        else if (modValue) ret = formatModString(value, modValue);
        else if (multiline) ret = formatMultiLineString(value);
        if (onClick) {
            ret = (
                <Link href="#" color="inherit" underline="always" onClick={handleClick}>
                    {ret}
                </Link>
            );
        }
        return ret;
    };

    const handleClick = () => {
        onClick && onClick();
    };

    return (
        <InputWrapper title={title} spacing={spacing}>
            {loading ? (
                <CircularProgress />
            ) : !!documents?.length ? (
                documents.map((d) => (
                    <FileItem
                        label={d.title}
                        key={d.fileName}
                        description={d.description}
                        name={d.fileName}
                        uuid={d.uuid}
                        showDescriptionHover
                        noMargin
                    />
                ))
            ) : (
                <Tooltip title={tooltipText}>
                    <Typography variant="body1">{getText()}</Typography>
                </Tooltip>
            )}
        </InputWrapper>
    );
};

export default LabeledData;
