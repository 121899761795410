import * as React from 'react';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import NavItem from './NavItem';
import { SubmissionDetails } from 'types/submission.type';
import { Badge, Theme, Tooltip } from '@mui/material';
import { NavActionComponentProps } from 'types/nav.types';
import { useSelector } from 'react-redux';
import {
    getProductAvailableLocationsState,
    getProductLineTypes,
    getSovLocations,
} from '../../../application/redux/selectors';
import { FilterOptionPopper } from '../../Input';
import { useActions } from '../../../hooks/useActions';
import * as Actions from '../../../application/redux/actions';
import { getProductFilteredLocations } from '../../../helpers/submissions';

interface UWReviewLinkProps extends NavActionComponentProps {
    submission: SubmissionDetails;
}

const UWReviewLink: FunctionComponent<UWReviewLinkProps> = (props) => {
    const { linkProps, key, submission } = { ...props };

    const { sovSaveProductAvailableLocations } = useActions(Actions);

    const sovAvailableLocationsState = useSelector(getProductAvailableLocationsState);
    const productLineTypes = useSelector(getProductLineTypes);
    const sovLocations = useSelector(getSovLocations);

    const handleFilterChanged = (filtered: string[]) => {
        const filteredCode =
            Object.values(productLineTypes).find((plt) => plt.displayValue === filtered[0])?.code || 'ALL';
        sovSaveProductAvailableLocations({
            productAvailableLocations:
                filteredCode === 'ALL'
                    ? sovLocations
                    : getProductFilteredLocations(filteredCode, sovLocations, submission),
            filteredProductCode: filteredCode,
        });
    };

    return (
        <>
            <NavItem key={key} to={linkProps.to}>
                <>
                    <Tooltip title={'Filter Locations By Product Availability'}>
                        <>
                            <span>{linkProps.label}</span>
                            <span style={{ display: 'inline' }}>
                                <FilterOptionPopper
                                    singleSelect
                                    filterOptions={[
                                        'ALL',
                                        ...Object.values(productLineTypes)
                                            .map((t: any) => t.displayValue)
                                            .sort(),
                                    ]}
                                    defaultFilterValues={[sovAvailableLocationsState.filteredProductCode]}
                                    onFilterOptionsChanged={(filtered) => handleFilterChanged(filtered)}
                                />
                            </span>
                        </>
                    </Tooltip>
                </>
            </NavItem>
        </>
    );
};

export default UWReviewLink;
