import { DocumentURL, getHeaders } from './config';
import { checkStatus, getData } from './normalizer';
import { Template, OutputDocFileTypes } from 'types/templates.type';
import { GeneratedDocumentFileName } from '../types/document.type';

export async function getTypes() {
    const url = `${DocumentURL}/templateTypes`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };

    return fetch(url, options).then(checkStatus).then(getData);
}

export async function getGeneratedInvoice(
    submissionId: string,
    underwriterReviewId: string,
    productLineCode: string,
    docFileType: OutputDocFileTypes = OutputDocFileTypes.DOCX,
    invoiceTemplateQualifier: string = ''
) {
    // const url = `${DocumentURL}/generated/invoice/${submissionId}${
    //     !!invoiceTemplateQualifier ? `?documentTypeQualifier=${invoiceTemplateQualifier}` : ''
    // }`;
    const params = [
        ...(!!invoiceTemplateQualifier ? [`documentTypeQualifier=${invoiceTemplateQualifier}`] : []),
        ...(!!underwriterReviewId ? [`underwriterReviewId=${underwriterReviewId}`] : []),
        ...(!!productLineCode ? [`productLineCode=${productLineCode}`] : []),
    ];
    const url = `${DocumentURL}/generated/invoice/${submissionId}${params ? '?' + params.join('&') : ''}`;
    const options = {
        method: 'GET',
        headers: getHeaders(
            docFileType === OutputDocFileTypes.PDF ? { Accept: 'application/pdf' } : { Accept: 'application/docx' }
        ),
    };

    return fetch(url, options).then(checkStatus);
}

export async function getGeneratedPolicy(
    submissionId: string,
    underwriterReviewId: string,
    productLineCode: string,
    docFileType: OutputDocFileTypes = OutputDocFileTypes.DOCX,
    policyTemplateQualifier: string = ''
) {
    // TODO add in the ability to select which optional endorsements to include, for now just $all
    const params = [
        ...(!!policyTemplateQualifier ? [`documentTypeQualifier=${policyTemplateQualifier}`] : []),
        ...(!!underwriterReviewId ? [`underwriterReviewId=${underwriterReviewId}`] : []),
        ...(!!productLineCode ? [`productLineCode=${productLineCode}`] : []),
    ];
    const url = `${DocumentURL}/generated/policy/${submissionId}?OPTIONAL_ENDORSEMENT=$all${
        params ? '&' + params.join('&') : ''
    }`;
    const options = {
        method: 'GET',
        headers: getHeaders(
            docFileType === OutputDocFileTypes.PDF ? { Accept: 'application/pdf' } : { Accept: 'application/docx' }
        ),
    };

    return fetch(url, options).then(checkStatus);
}

export async function getGeneratedBinder(
    submissionId: string,
    underwriterReviewId: string,
    productLineCode: string,
    docFileType: OutputDocFileTypes = OutputDocFileTypes.DOCX,
    binderTemplateQualifier: string = ''
) {
    const params = [
        ...(!!binderTemplateQualifier ? [`documentTypeQualifier=${binderTemplateQualifier}`] : []),
        ...(!!underwriterReviewId ? [`underwriterReviewId=${underwriterReviewId}`] : []),
        ...(!!productLineCode ? [`productLineCode=${productLineCode}`] : []),
    ];
    const url = `${DocumentURL}/generated/binder/${submissionId}${params ? '?' + params.join('&') : ''}`;
    const options = {
        method: 'GET',
        headers: getHeaders(
            docFileType === OutputDocFileTypes.PDF ? { Accept: 'application/pdf' } : { Accept: 'application/docx' }
        ),
    };

    return fetch(url, options).then(checkStatus);
}

export async function getGeneratedLossRun(
    submissionId: string,
    productLineCode: string,
    docFileType: OutputDocFileTypes = OutputDocFileTypes.DOCX,
    lossRunTemplateQualifier: string = ''
) {
    const url = `${DocumentURL}/generated/lossRun/${submissionId}${
        !!lossRunTemplateQualifier ? `?documentTypeQualifier=${lossRunTemplateQualifier}` : ''
    }`;
    const options = {
        method: 'GET',
        headers: getHeaders(
            docFileType === OutputDocFileTypes.PDF ? { Accept: 'application/pdf' } : { Accept: 'application/docx' }
        ),
    };

    return fetch(url, options).then(checkStatus);
}

export async function getGeneratedNoticeOfCancellation(
    submissionId: string,
    noticeDate: string,
    noticeNarrative: string,
    docFileType: OutputDocFileTypes = OutputDocFileTypes.DOCX,
    nocTemplateQualifier: string = '',
    productLineCode: string
) {
    const url = `${DocumentURL}/generated/NOC/${submissionId}?$UIDate=${noticeDate}&$UINarrative=${noticeNarrative}
    ${!!nocTemplateQualifier ? `&documentTypeQualifier=${nocTemplateQualifier}` : ''}
    ${!!productLineCode ? `&productLineCode=${productLineCode}` : ''}`;
    const options = {
        method: 'GET',
        headers: getHeaders(
            docFileType === OutputDocFileTypes.PDF ? { Accept: 'application/pdf' } : { Accept: 'application/docx' }
        ),
    };

    return fetch(url, options).then(checkStatus);
}

export async function getGeneratedNoticeOfNonRenewal(
    submissionId: string,
    noticeNarrative: string,
    docFileType: OutputDocFileTypes = OutputDocFileTypes.DOCX,
    productLineCode: string,
    nonrTemplateQualifier: string = ''
) {
    const url = `${DocumentURL}/generated/NONR/${submissionId}?$UINarrative=${noticeNarrative}${
        !!nonrTemplateQualifier ? `?documentTypeQualifier=${nonrTemplateQualifier}` : ''
    }${!!productLineCode ? `&productLineCode=${productLineCode}` : ''}`;
    const options = {
        method: 'GET',
        headers: getHeaders(
            docFileType === OutputDocFileTypes.PDF ? { Accept: 'application/pdf' } : { Accept: 'application/docx' }
        ),
    };

    return fetch(url, options).then(checkStatus);
}

export async function getGeneratedQuote(
    underwriterReviewIds: string[],
    docFileType: OutputDocFileTypes = OutputDocFileTypes.DOCX,
    quoteTemplateQualifier: string = ''
) {
    const url = `${DocumentURL}/generated/quote?underwriterReviewIds=${underwriterReviewIds.join(',')}${
        !!quoteTemplateQualifier ? `&documentTypeQualifier=${quoteTemplateQualifier}` : ''
    }`;
    const options = {
        method: 'GET',
        headers: getHeaders(
            docFileType === OutputDocFileTypes.PDF ? { Accept: 'application/pdf' } : { Accept: 'application/docx' }
        ),
    };

    return fetch(url, options).then(checkStatus);
}

export async function getStaticDocument(fileName: string) {
    const url = `${DocumentURL}/static/${fileName}`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus);
}

export async function getDocument(fileUrl: string) {
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(fileUrl, options).then(checkStatus);
}

export async function getGeneratedEndorsement(
    endorsementId: string,
    underwriterReviewId: string,
    docFileType: OutputDocFileTypes = OutputDocFileTypes.DOCX,
    productLineCode: string,
    endorsementType: string = ''
) {
    const params = [
        ...(!!underwriterReviewId ? [`underwriterReviewId=${underwriterReviewId}`] : []),
        ...(!!productLineCode ? [`productLineCode=${productLineCode}`] : []),
    ];
    const url = `${DocumentURL}/generated/${endorsementType.toLowerCase()}/${endorsementId}${
        params ? '?' + params.join('&') : ''
    }`;
    const options = {
        method: 'GET',
        headers: getHeaders(
            docFileType === OutputDocFileTypes.PDF ? { Accept: 'application/pdf' } : { Accept: 'application/docx' }
        ),
    };

    return fetch(url, options).then(checkStatus);
}

export async function getGeneratedDocumentFileName(
    docType: string,
    submissionId: string,
    productLineCode?: string
): Promise<GeneratedDocumentFileName> {
    const url = `${DocumentURL}/generated/filename/${docType}?submissionId=${submissionId}${
        productLineCode ? `&productLineCode=${productLineCode}` : ''
    }`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };

    return fetch(url, options).then(checkStatus).then(getData);
}

export async function updateTemplate(payload: Template) {
    return fetch(`${DocumentURL}/template`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    })
        .then(checkStatus)
        .then(getData);
}
