import { combineReducers, AnyAction } from 'redux';
import { REDUX, REDUX_PERSIST, GRID_NAMES } from 'constants/index';
import { getGridReducer } from '../lib/gridReducerCreator';
import { SubmissionData } from '../store/SubmissionsState';

export const initialState = {
    submissions: [],
} as SubmissionData;

const grid = getGridReducer(GRID_NAMES.SUBMISSIONS, REDUX_PERSIST.SUBMISSIONS);

const data = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case `${REDUX.FETCH_SUBMISSIONS}/SUCCESS`: {
            return {
                submissions: action.payload,
            };
        }
        case `${REDUX.FETCH_SUBMISSIONS}/LOADING`:
        case `${REDUX.FETCH_SUBMISSIONS}/ERROR`: {
            return initialState;
        }
        default:
            return state;
    }
};

function error(state: string = '', action: AnyAction): string {
    switch (action.type) {
        case `${REDUX.FETCH_SUBMISSIONS}/ERROR`: {
            return action.payload || '';
        }
        case `${REDUX.FETCH_SUBMISSIONS}/LOADING`: {
            return '';
        }
        default:
            return state;
    }
}

function isLoading(state: boolean = false, action: AnyAction): boolean {
    switch (action.type) {
        case `${REDUX.FETCH_SUBMISSIONS}/LOADING`: {
            return true;
        }
        case `${REDUX.FETCH_SUBMISSIONS}/SUCCESS`:
        case `${REDUX.FETCH_SUBMISSIONS}/ERROR`: {
            return false;
        }
        default:
            return state;
    }
}

export default combineReducers({
    grid,
    data,
    error,
    isLoading,
});
