const root = () => '/';
const callback = () => '/implicit/callback';
const login = (fromPath?: string) => `/login${fromPath ? '?referer=' + fromPath : ''}`;
const logout = () => '/logout';
const authLanding = () => '/auth';
const templateManagement = () => '/templatemanagement';
const reporting = () => '/reporting';
const geoFuse = () => '/geofuse';
const users = () => '/users';
const usersCreate = () => '/users/create';
const usersEdit = () => '/users/edit/:editId';
const organizations = () => '/organizations';
const submissions = () => `/submissions`;
const submissionDetail = (id?: string) => `/submission/${id || ':id'}`;
const submissionForm = (id?: string) => `/submission/form/${id || ':id'}/`;
const submissionFormDetails = (id?: string) => `/submission/form/${id || ':id'}/details`;
const overview = (id?: string) => `${submissionDetail(id)}/overview`;
const notes = (id?: string) => `${submissionDetail(id)}/notes`;
const financial = (id?: string) => `${submissionDetail(id)}/financial`;
const clearing = (id?: string) => `${submissionDetail(id)}/clearing`;
const SOV = (id?: string) => `${submissionDetail(id)}/sov`;
const lossSummary = (id?: string) => `${submissionDetail(id)}/losssummary`;
const sovMap = (id?: string) => `${submissionDetail(id)}/sovmap`;
const uwReview = (id?: string) => `${submissionDetail(id)}/review`;
const agentDashboard = () => '/agentdashboard';
const lumenDashboard = () => '/lumendashboard';
const submissionAgentDashboard = (id?: string) => `${submissionDetail(id)}/agentDashboard`;
const quotes = (id?: string) => `${submissionDetail(id)}/quotes/prepare`;
const quotesView = (id?: string) => `${submissionDetail(id)}/quotes/view`;
const quoteReview = (id?: string) => `${submissionDetail(id)}/quotes/review`;
const endorsememtReview = (id?: string) => `${submissionDetail(id)}/endorsement/review`;
const bindRequest = (id?: string) => `${submissionDetail(id)}/quotes/bind`;
const issuance = (id?: string) => `${submissionDetail(id)}/issuance`;
const location = (id?: string, locationId?: number) =>
    `${submissionDetail(id)}/location/${locationId || ':locationId'}`;
const referrals = (id?: string) => `${submissionDetail(id)}/referrals`;

export default {
    root,
    callback,
    login,
    logout,
    authLanding,
    templateManagement,
    reporting,
    users,
    usersCreate,
    usersEdit,
    organizations,
    submissions,
    submissionDetail,
    submissionForm,
    submissionFormDetails,
    overview,
    notes,
    financial,
    clearing,
    SOV,
    lossSummary,
    sovMap,
    uwReview,
    agentDashboard,
    lumenDashboard,
    submissionAgentDashboard,
    quotes,
    quotesView,
    quoteReview,
    bindRequest,
    issuance,
    location,
    endorsememtReview,
    referrals,
    geoFuse,
};
