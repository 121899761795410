import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import NavItem from './NavItem';
import LrDialog from '../../LrDialog';
import { LossForm } from '../../Losses';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { NavActionComponentProps } from 'types/nav.types';

const CreateClaimAction: FunctionComponent<NavActionComponentProps> = (props) => {
    const { linkProps, key } = { ...props };
    const [showLossDialog, setShowLossDialog] = useState<boolean>();

    const getIconComponent = () => <AssignmentLateIcon fontSize="small" />;

    return (
        <>
            <NavItem key={key} to={linkProps.to} icon={getIconComponent()} onClick={() => setShowLossDialog(true)}>
                {linkProps.label}
            </NavItem>
            <LrDialog isOpen={showLossDialog} title={'Create a Claim'} maxWidth="sm" fullWidth>
                <LossForm
                    onCancel={() => setShowLossDialog(false)}
                    onSubmit={() => setShowLossDialog(false)}
                    allowCancel
                    showReason
                    isClaim
                />
            </LrDialog>
        </>
    );
};

export default CreateClaimAction;
