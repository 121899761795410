import React, { FunctionComponent, useEffect, useRef } from 'react';

import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import logo from 'assets/logo_footer.png';
import { useOktaAuth } from '@okta/okta-react';

interface Props {
    baseUrl: string;
    onSuccess: any;
    onError: any;
}

const OktaSignInWidget: FunctionComponent<Props> = ({ baseUrl, onSuccess, onError }) => {
    const widgetRef = useRef<any>();
    const { oktaAuth } = useOktaAuth();
    useEffect(() => {
        const widget = new OktaSignIn({
            baseUrl: baseUrl,
            authClient: oktaAuth,
            clientId: oktaAuth.options.clientId,
            redirectUri: oktaAuth.options.redirectUri,
            language: 'en',
            logo: logo,
            brandName: 'Lumen Risk',
            colors: {
                brand: '#54565A',
            },
        });
        widget.showSignInToGetTokens({ el: widgetRef.current }).then(onSuccess).catch(onError);

        return () => {
            try {
                widget.remove();
            } catch (e) {
                // this for some reason throws on occasion
            }
        };
    }, [onSuccess, onError]);

    return <span ref={widgetRef} />;
};

export default OktaSignInWidget;
