import { getHeaders, SubmissionURL } from './config';
import { checkStatus, getData } from './normalizer';
import { Document2 } from '../types/document.type';

// export async function sendFile(payload: File) {
//     return fetch(`${DocumentURL}/document`, {
//         method: 'POST',
//         headers: getHeaders(),
//         body: payload,
//     })
//         .then(checkStatus)
//         .then(getData);
// }
//
// export const getDocumentDowloadUrl = (uuid: string, filename: string) =>
//     `${DocumentURL}/document/${uuid}/filename/${filename}`;

// export async function sendAllFiles(payload: File[]) {
//     const formData = new FormData();
//     payload.forEach(file => formData.append(file.type, file.file));
//     return fetch(`${DocumentURL}/documents`, {
//         method: 'POST',
//         headers: getHeaders({ 'Content-Type': 'multiplart/form-data' }),
//         body: JSON.stringify(formData)
//     })
//         .then(checkStatus)
//         .then(getData);
// }

export async function updateDocument(payload: Document2) {
    return fetch(`${SubmissionURL}/documents`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    })
        .then(checkStatus)
        .then(getData);
}
