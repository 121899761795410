import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { REDUX_PERSIST } from 'constants/index';

/* Note:
 * Code based on github comment: https://github.com/rt2zz/redux-persist/issues/277#issuecomment-478965157
 **/
export const persist = (key: string, whitelist: string[], reducer: any) =>
    persistReducer(
        {
            key: `${REDUX_PERSIST.ROOT}::${key}`,
            storage,
            whitelist,
        },
        reducer
    );
