import { combineReducers, AnyAction } from 'redux';

/* Note:
    data, isLoading, and error are not normal reducers.
    these are to be used to generate reusable reducers,
    so they take another param to dynamically create the same reducer for different actions

*/
const data = (actionName: string, initialState: any) => (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case `${actionName}/SUCCESS`: {
            return action.payload || state;
        }
        case `${actionName}/LOADING`:
        case `${actionName}/ERROR`: {
            return initialState;
        }
        default:
            return state;
    }
};

const isLoading = (actionName: string, initialState: any) => (state: boolean = false, action: AnyAction): boolean => {
    switch (action.type) {
        case `${actionName}/LOADING`: {
            return true;
        }
        case `${actionName}/SUCCESS`:
        case `${actionName}/ERROR`: {
            return false;
        }
        default:
            return state;
    }
};

const error = (actionName: string, initialState: any) => (state: string = '', action: AnyAction) => {
    switch (action.type) {
        case `${actionName}/ERROR`: {
            return action.payload;
        }
        case `${actionName}/LOADING`: {
            return '';
        }
        default:
            return state;
    }
};

const hasResolved = (actionName: string, initialState: any) => (state: boolean = false, action: AnyAction) => {
    switch (action.type) {
        case `${actionName}/LOADING`:
            return false;
        case `${actionName}/SUCCESS`:
        case `${actionName}/ERROR`: {
            return true;
        }
        default:
            return state;
    }
};

export const getReducer = (actionName: string, _initialState?: any) => {
    return {
        data: data(actionName, _initialState),
        isLoading: isLoading(actionName, _initialState),
        error: error(actionName, _initialState),
        hasResolved: hasResolved(actionName, _initialState),
    };
};

export const getCombinedReducer = (actionName: string, _initialState?: any) => {
    const reducers = getReducer(actionName, _initialState);
    return combineReducers(reducers);
};
