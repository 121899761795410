import { Dispatch } from 'redux';
import { REDUX } from '../../../constants';
import actionCreator from '../lib/actionCreator';
import { getOneSubmission } from '../../../services/submission';

export const saveCreateEndorsementContextAction = actionCreator(REDUX.ENDORSEMENT_CONTEXT_SAVE);

export function fetchCreateEndorsementContext(id: string) {
    return async (dispatch: Dispatch) => {
        dispatch(saveCreateEndorsementContextAction.loading());

        getOneSubmission(id)
            .then((submission) => {
                dispatch(saveCreateEndorsementContextAction.success(submission));
            })
            .catch((reason) => {
                dispatch(saveCreateEndorsementContextAction.error(reason));
            });
    };
}

export function createEndorsementContextClear() {
    return async (dispatch: Dispatch) => {
        dispatch({ type: REDUX.ENDORSEMENT_CONTEXT_CLEAR });
    };
}
