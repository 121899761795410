import * as React from 'react';
import { Redirect } from 'react-router';

class Root extends React.Component {
    async componentDidMount() {
        // await authProvider.login('/auth');
    }

    public render() {
        return <Redirect to="/home" />;
        // return <div />;
    }
}

export default Root;
