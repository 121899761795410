import * as React from 'react';
import moment from 'moment';
import { LogOptions, Log } from 'types/logs.types';
import { fetchLogs } from 'services/logs';
import { Card, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LrFileItemList } from 'components/LrFileItems';
import { CircularLoader } from 'components/Loader';
import { Spacing } from 'components/Layout';
import { formatMultiLineString } from 'helpers/formatters';
import { useSelector } from 'react-redux';
import { getLogTypes } from '../../../application/redux/selectors';
import { FilterOptionPopper } from '../../Input';

export interface LrNotesProps {
    needsRefresh?: boolean;
    options: LogOptions;
    title?: string;
    emptyHeader?: string;
    emptyText?: string;
    hideIfEmpty?: boolean;
    showChildTitles?: boolean;
    showNoteTypes?: boolean;
    showCreatedByRole?: boolean;
    canFilter?: boolean;
    defaultFilterTypes?: string[];
    filterOptions?: string[];
    onNotesRefreshed?: () => void;
}

const defaultProps = {
    showChildTitles: false,
    showCreatedByRole: true,
    emptyHeader: 'Nothing to see here.',
    emptyText: 'There are no notes for this location yet.',
    canFilter: false,
    defaultFilterTypes: [
        'Annotation',
        'Bind Request',
        'Blocked by Clearing',
        'Correspondence',
        'Inspection Report',
        'Notice of Cancellation',
        'Notice of Non-Renewal',
        'Producer Entered',
        'Quote Withdrawal',
        'Referral',
        'Requote Request',
        'Underwriting Declined',
    ],
};

const NotesList: React.FunctionComponent<LrNotesProps> = (props) => {
    const {
        needsRefresh,
        onNotesRefreshed,
        options,
        title,
        showChildTitles,
        showNoteTypes,
        showCreatedByRole,
        emptyHeader,
        emptyText,
        hideIfEmpty,
        canFilter,
        defaultFilterTypes,
        filterOptions,
    } = props;

    // Global State
    const logTypes = useSelector(getLogTypes);

    // Local State
    const [isLoading, setIsLoading] = React.useState(false);
    const [notes, setNotes] = React.useState([] as Log[]);
    const [error, setError] = React.useState(false);
    const [filteredTypes, setFilteredTypes] = React.useState<string[]>([]);

    const isMountedRef = React.useRef(true);
    const isEmpty = !error && !isLoading && !notes.length;
    const hide = hideIfEmpty && isEmpty;
    React.useEffect(
        () => () => {
            isMountedRef.current = false;
        },
        []
    );

    const fetchNotes = async () => {
        try {
            setIsLoading(true);
            const response = await fetchLogs(options);
            if (!isMountedRef.current) {
                return;
            }
            setNotes(response);
            onNotesRefreshed && onNotesRefreshed();
        } catch (e) {
            setError(true);
        }
        setIsLoading(false);
    };

    const refresh = () => {
        !isLoading && needsRefresh && fetchNotes();
    };
    const reload = () => {
        !isLoading && !needsRefresh && fetchNotes();
    };
    React.useEffect(reload, [JSON.stringify(options)]);
    React.useEffect(refresh, [needsRefresh]);

    const renderMessage = (heading?: string, text?: string) => (
        <Grid container direction="column" spacing={0} alignItems="center" justifyContent="center">
            <Spacing height="32px" />
            <Typography variant="h2">{heading}</Typography>
            <Typography variant="body1">{text}</Typography>
            <Spacing height="32px" />
        </Grid>
    );

    const renderNote = (note: Log, idx: number) => (
        <Grid key={idx} container direction="column" spacing={0} wrap="nowrap">
            <Grid container direction="row" spacing={0}>
                <Grid xs={6}>
                    {showNoteTypes && (
                        <Typography display="inline" variant="body2">
                            {logTypes[note.logTypeId as string].displayValue}
                        </Typography>
                    )}
                    <Typography display="inline" variant="caption">
                        {`${showNoteTypes ? ' - ' : ''}${note.createdBy}` +
                            (showCreatedByRole ? `: ${note.createdByRole}` : '')}
                    </Typography>
                </Grid>
                <Grid xs={6}>
                    <Typography variant="caption" align="right">
                        {moment(note.createdAt).format('MM/DD/YYYY h:mm a')}
                    </Typography>
                </Grid>
            </Grid>
            {showChildTitles && note.childTitle && (
                <Grid container direction="row" spacing={0}>
                    <Grid xs={6}>
                        <Typography variant="caption" align="left">
                            {note.childTitle}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {note.message && note.message !== '' && (
                <Grid>
                    <Typography variant="body1">{formatMultiLineString(note.message)}</Typography>
                </Grid>
            )}
            {note.documents && !!note.documents.length && (
                <Grid>
                    <LrFileItemList files={note.documents} clickable />
                </Grid>
            )}
        </Grid>
    );

    return (
        <>
            {!hide && (
                <Card>
                    <Grid container direction="column" spacing={4}>
                        <Grid style={{ marginTop: '0' }}>
                            <Typography variant="h4" display="inline">
                                {title || 'Notes'}
                            </Typography>
                            {canFilter && (
                                <div style={{ display: 'inline' }}>
                                    <FilterOptionPopper
                                        filterOptions={
                                            filterOptions ||
                                            Object.values(logTypes)
                                                .map((t: any) => t.displayValue)
                                                .sort()
                                        }
                                        defaultFilterValues={defaultFilterTypes || []}
                                        onFilterOptionsChanged={(filtered) => setFilteredTypes(filtered)}
                                    />
                                </div>
                            )}
                        </Grid>
                        {isLoading && <CircularLoader fillHeight="100%" />}
                        {error &&
                            renderMessage('Oh no!', 'Something went wrong loading the notes. Refresh to try again.')}
                        {!error &&
                            (!isLoading || needsRefresh) &&
                            !!notes.length &&
                            notes
                                .filter(
                                    (note: Log) =>
                                        !canFilter ||
                                        filteredTypes.includes(logTypes[note.logTypeId as string].displayValue)
                                )
                                .map(renderNote)}
                        {isEmpty && renderMessage(emptyHeader, emptyText)}
                    </Grid>
                </Card>
            )}
        </>
    );
};

NotesList.defaultProps = defaultProps;

export default React.memo(NotesList);
