import { getHeaders, SubmissionURL } from './config';
import { checkStatus } from './normalizer';
import { setTypes } from 'helpers/typeHelper';
import moment, { Moment } from 'moment';
import {
    AgentSummaryTableResponse,
    FinancialControlTotalResponse,
    LocationAccumulationSummary,
    LocationClusterResource,
    LocationInspectionDataResponse,
    LocationInspectionOrderedDataResponse,
    PowerBIEmbedInfo,
    UnderwritingTrendResult,
} from '../types/reporting.type';

export async function fetchReportingLocations() {
    const url = `${SubmissionURL}/reportingLocations`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json())
        .then(setTypes);
}

export async function fetchReportingAccumulationLocations() {
    const url = `${SubmissionURL}/reportingAccumulationLocations`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json());
}

export interface FetchBordereauOptions {
    startDate?: Moment;
    endDate?: Moment;
    reportEntity?: string;
    contentType?: string;
    qualifier?: string;
    removeEmptyColumns?: boolean;
}

export interface FetchControlTotalsOptions {
    startDate?: Moment;
    endDate?: Moment;
}

export interface FetchInspectionLocationDataOptions {
    startDate?: Moment;
    endDate?: Moment;
}

export interface FetchAgentSummaryDataOptions {
    startDate?: Moment;
    endDate?: Moment;
}

function parameterizeFetchOptions(fetchOptions: object) {
    return Object.entries(fetchOptions)
        .filter(([key, val]) => val != null)
        .map(([key, val]) => {
            if (moment.isMoment(val)) {
                return `${key}=${val.format('YYYY-MM-DD')}`;
            } else {
                return `${key}=${val}`;
            }
        });
}

export async function fetchMonthlyBordereau(fetchOptions: FetchBordereauOptions) {
    const contentType = fetchOptions.contentType;
    delete fetchOptions.contentType;
    const paramStr = parameterizeFetchOptions(fetchOptions).join('&');
    const url = `${SubmissionURL}/reportingBordereau?` + paramStr;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    if (contentType) {
        options.headers.set('Accept', contentType);
    }
    return fetch(url, options).then(checkStatus);
}

export async function fetchFinancialControlTotals(
    fetchOptions: FetchControlTotalsOptions
): Promise<FinancialControlTotalResponse> {
    const paramStr = parameterizeFetchOptions(fetchOptions).join('&');
    const url = `${SubmissionURL}/reportingFinancialControlTotals?` + paramStr;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json());
}

export async function fetchInspectionLocationData(
    fetchOptions: FetchInspectionLocationDataOptions
): Promise<LocationInspectionDataResponse[]> {
    const paramStr = parameterizeFetchOptions(fetchOptions).join('&');
    const url = `${SubmissionURL}/reportingLocationInspectionData?` + paramStr;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json());
}

export async function fetchInspectionOrderedData(
    fetchOptions: FetchInspectionLocationDataOptions
): Promise<LocationInspectionOrderedDataResponse[]> {
    const paramStr = parameterizeFetchOptions(fetchOptions).join('&');
    const url = `${SubmissionURL}/reportingOrderedInspectionData?` + paramStr;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json());
}

export async function fetchUnderwritingTrendResult(
    submissionId: string,
    locationId: number
): Promise<UnderwritingTrendResult> {
    const url = `${SubmissionURL}/submission/${submissionId}/zoneUnderwritingTrend?locationId=${locationId}`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json());
}

export async function fetchLocationAccumulationsSummary(
    submissionId: string,
    locationIds: number[]
): Promise<LocationAccumulationSummary[]> {
    const url = `${SubmissionURL}/submission/${submissionId}/accumulationSummary?locationIds=${locationIds.join(',')}`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json());
}
export async function fetchAgentSummaryTable(
    fetchOptions: FetchAgentSummaryDataOptions
): Promise<AgentSummaryTableResponse[]> {
    const paramStr = parameterizeFetchOptions(fetchOptions).join('&');
    const url = `${SubmissionURL}/reporting/agentSummaryTable?` + paramStr;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json());
}

export async function fetchLocationClusterData(jobId: string): Promise<LocationClusterResource[]> {
    const url = `${SubmissionURL}/reporting/reportingLocationClusterJobs/${jobId}/data`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json());
}

export async function getClusterStatus(jobId: string): Promise<string> {
    const url = `${SubmissionURL}/reporting/reportingLocationClusterJobs/${jobId}`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.text());
}

export async function deleteClusterJob(jobId: string): Promise<string> {
    const url = `${SubmissionURL}/reporting/reportingLocationClusterJobs/${jobId}`;
    const options = {
        method: 'DELETE',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.text());
}

export async function startLocationClusterOperation(clusterRadius: string): Promise<{ jobId: string }> {
    const url = `${SubmissionURL}/reporting/reportingLocationClusterJobs?clusterRadius=${clusterRadius}`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json());
}

export async function fetchPowerBIEmbedInfo(reportId: string, workspaceId: string): Promise<PowerBIEmbedInfo> {
    const url = `${SubmissionURL}/reporting/getpowerbiembedinfo?reportId=${reportId}&workspaceId=${workspaceId}`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json());
}
