import { StyleRules } from '@mui/styles';

const styles = (): StyleRules => ({
    root: {
        height: '100vh',
        background: '#fafafa',
    },
    content: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '100px 24px',
    },
    card: {
        width: '400px',
        height: '600px',
    },
    cardHeader: {
        padding: '40px 80px',
        borderBottom: '1px solid #e0e0e0',
    },
    cardActions: {
        justifyContent: 'center',
    },
    progress: {
        color: '#ffdd00',
    },
});

export default styles;
