import { COLORS } from 'constants/index';

const MuiAlert = {
    root: {
        minWidth: '300px',
    },
    outlinedSuccess: {
        color: COLORS.SECONDARY,
        borderColor: COLORS.PRIMARY,
        backgroundColor: 'white',
        '& .MuiAlert-icon': {
            color: COLORS.PRIMARY,
        },
    },
};

export default MuiAlert;
