import { createSelector } from 'reselect';

import ApplicationState from 'application/redux/store/ApplicationState';
import { TypesData, TypesState } from 'types/types.type';

const typesState = (state: ApplicationState) => state.types;
const data = (state: TypesState) => state.data;
const error = (state: TypesState) => state.error;
const loading = (state: TypesState) => state.isLoading;
const hasResolved = (state: TypesState) => state.hasResolved;

const actionTypes = (state: TypesData) => state.actionTypes;
const constructionTypes = (state: TypesData) => state.constructionTypes;
const documentActionTypes = (state: TypesData) => state.documentActionTypes;
const endorsementTypes = (state: TypesData) => state.endorsementTypes;
const floodZoneTypes = (state: TypesData) => state.floodZoneTypes;
const locationClassTypes = (state: TypesData) => state.locationClassTypes;
const logTypes = (state: TypesData) => state.logTypes;
const perilTypes = (state: TypesData) => state.perilTypes;
const propertyTypes = (state: TypesData) => state.propertyTypes;
const roofCoveringTypes = (state: TypesData) => state.roofCoveringTypes;
const roofTypes = (state: TypesData) => state.roofTypes;
const roleTypes = (state: TypesData) => state.roleTypes;
const statusTypes = (state: TypesData) => state.statusTypes;
const wiringTypes = (state: TypesData) => state.wiringTypes;
const organizationTypes = (state: TypesData) => state.organizationTypes;
const uwStatusTypes = (state: TypesData) => state.submissionUnderwritingStatusTypes;
const referralReasonsTypes = (state: TypesData) => state.referralReasonTypes;
const referralStatusTypes = (state: TypesData) => state.referralStatusTypes;
const productLineTypes = (state: TypesData) => state.productLineTypes;

const actionAndDocTypes = (state: TypesData) => ({
    actionTypes: actionTypes(state),
    documentActionTypes: documentActionTypes(state),
});

const typeToList = (types: object) => Object.keys(types).map((key: string) => types[key]);

const typeToByCode = (types: object) =>
    Object.keys(types).reduce((acc: any, key: string) => {
        acc[types[key].code] = types[key];
        return acc;
    }, {});

const typeToByCodeToId = (types: object) =>
    Object.keys(types).reduce((acc: any, key: string) => {
        acc[types[key].code] = types[key].id.toString();
        return acc;
    }, {});

const docsByAction = (results: any, actionCode: string) => {
    const { actionTypes: aTypes, documentActionTypes: dTypes } = results;
    if (!dTypes || !aTypes) return [];
    return typeToList(dTypes).filter(({ actionTypeId }: any) => {
        if (!actionTypeId || !aTypes[actionTypeId]) return false;
        const code = aTypes[actionTypeId].code;
        return code === actionCode;
    });
};

export const getTypesError = createSelector(typesState, error);

export const getTypesLoading = createSelector(typesState, loading);

export const getTypesResolved = createSelector(typesState, hasResolved);

export const getTypesData = createSelector(typesState, data);

export const getPerilTypes = createSelector(getTypesData, perilTypes);

export const getPerilTypesList = createSelector(getPerilTypes, typeToList);

export const getActionTypes = createSelector(getTypesData, actionTypes);

export const getConstructionTypes = createSelector(getTypesData, constructionTypes);

export const getDocumentTypes = createSelector(getTypesData, documentActionTypes);

export const getDocumentTypesList = createSelector(getDocumentTypes, typeToList);

export const getDocumentTypesByCode = createSelector(getDocumentTypes, typeToByCode);

export const getDocumentTypesByAction = (actionCode: string) =>
    createSelector(getTypesData, actionAndDocTypes, (result: object) => docsByAction(result, actionCode));

export const getEndorsementTypes = createSelector(getTypesData, endorsementTypes);

export const getFloodZoneTypes = createSelector(getTypesData, floodZoneTypes);

export const getLocationClassTypes = createSelector(getTypesData, locationClassTypes);

export const getLogTypes = createSelector(getTypesData, logTypes);

export const getLogTypesByCode = createSelector(getLogTypes, typeToByCode);

export const getPropertyTypes = createSelector(getTypesData, propertyTypes);

export const getRoofCoveringTypes = createSelector(getTypesData, roofCoveringTypes);

export const getRoofTypes = createSelector(getTypesData, roofTypes);

export const getStatusTypes = createSelector(getTypesData, statusTypes);

export const getStatusTypesByCode = createSelector(getStatusTypes, typeToByCodeToId);

export const getWiringTypes = createSelector(getTypesData, wiringTypes);

export const getRoleTypes = createSelector(getTypesData, roleTypes);

export const getRoleTypesList = createSelector(getRoleTypes, typeToList);

export const getRoleTypesByCode = createSelector(getRoleTypes, typeToByCode);

export const getOrganizationTypes = createSelector(getTypesData, organizationTypes);

export const getOrganizationTypesList = createSelector(getOrganizationTypes, typeToList);

export const getOrganizationTypesByCode = createSelector(getOrganizationTypes, typeToByCode);

export const getUWStatusTypes = createSelector(getTypesData, uwStatusTypes);

export const getUWStatusTypesByCode = createSelector(getUWStatusTypes, typeToByCode);

export const getReferralReasonTypes = createSelector(getTypesData, referralReasonsTypes);

export const getReferralReasonTypesByCode = createSelector(getReferralReasonTypes, typeToByCode);

export const getReferralStatusTypes = createSelector(getTypesData, referralStatusTypes);

export const getReferralStatusTypesByCode = createSelector(getReferralStatusTypes, typeToByCode);

export const getProductLineTypes = createSelector(getTypesData, productLineTypes);

export const getProductLineTypesByCode = createSelector(getProductLineTypes, typeToByCode);

export const getProductLineTypesList = createSelector(getProductLineTypes, typeToList);
