import * as React from 'react';
import { FunctionComponent, useRef, useState } from 'react';
import NavItem from './NavItem';
import SubmissionMessagingClient from 'components/SubmissionDetails/SubmissionMessagingClient';
import { MessagingClientAPI } from 'components/Messaging/components/MessagingClient';
import styled from 'styled-components';
import { SubmissionDetails } from 'types/submission.type';
import { Badge } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import { markSubmissionMessagesRead } from '../../../services/messaging';
import { useSelector } from 'react-redux';
import { getTopLevelUserGroup } from '../../../application/redux/selectors';
import { getIsAgentRole } from '../../../helpers/roles';
import { COLORS } from 'constants/index';
import { NavActionComponentProps } from 'types/nav.types';

interface SubmissionMessagingActionProps extends NavActionComponentProps {
    submission: SubmissionDetails;
}

const Wrapper = styled.div`
    position: relative;

    & .rcw-messages-container {
        height: 50vh;
    }
    & .rcw-widget-container {
        bottom: unset;
        right: unset;
        top: 20%;
        left: 10%;
        position: absolute;
        max-width: 500px;
    }
    & .rcw-conversation-container {
        box-shadow: 0px 2px 10px 1px ${COLORS.SECONDARY};
    }
`;

const SubmissionMessagingAction: FunctionComponent<SubmissionMessagingActionProps> = (props) => {
    const { linkProps, key, submission } = { ...props };
    const messagingClientAPI = useRef<MessagingClientAPI>(null);
    const group = useSelector(getTopLevelUserGroup);
    const isAgent = getIsAgentRole(group);
    const [hideBadge, setHideBadge] = useState<boolean>(
        isAgent ? !submission.hasLumenMessages : !submission.hasAgentMessages
    );

    const getIconComponent = () => (
        <Badge variant="dot" overlap={'circular'} color={'secondary'} invisible={hideBadge}>
            <MailIcon fontSize="small" />
        </Badge>
    );

    const onActionClick = () => {
        messagingClientAPI.current && messagingClientAPI.current.toggleShowClient();
        setHideBadge(true);
        markSubmissionMessagesRead(submission.id);
    };

    return (
        <>
            <NavItem key={key} to={linkProps.to} icon={getIconComponent()} onClick={onActionClick}>
                {linkProps.label}
            </NavItem>
            <Wrapper>
                <SubmissionMessagingClient
                    ref={messagingClientAPI}
                    submissionId={submission.id}
                    submissionName={submission.namedInsured}
                />
            </Wrapper>
        </>
    );
};

export default SubmissionMessagingAction;
