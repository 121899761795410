import ApplicationState from '../store/ApplicationState';
import { getSelectors } from '../lib/selectorCreator';
import { createSelector } from 'reselect';
import { UserState } from '../store/UserState';

const userState = (state: ApplicationState) => {
    return state.users;
};
const activeUser = (state: UserState) => state.activeUser;
const activeUserProducer = (state: UserState) => state.activeUserProducer;
const activeUserIsLoading = (state: UserState) => state.activeUserIsLoading;

const selectors = getSelectors(userState);

export const getUsers = selectors.getData;
export const getUsersLoading = selectors.getLoading;
export const getUsersError = selectors.getError;

export const getActiveUser = createSelector(userState, activeUser);

// The activeUserProducer is a user context wrapping around a producer
export const getActiveUserProducer = createSelector(userState, activeUserProducer);

export const getActiveUserIsLoading = createSelector(userState, activeUserIsLoading);
