import * as React from 'react';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import MaskedInput from 'react-text-mask';
import InputWrapper from './InputWrapper';
import { GridSpacing } from '@mui/material/Grid';
import { Input, InputBaseComponentProps } from '@mui/material';
import { MutableRefObject, Ref, RefObject, useEffect, useState } from 'react';

interface Props {
    inputStyle?: any;
    errorMessage?: string | boolean;
    pipe?: any;
    spacing?: GridSpacing;
    fullheight?: boolean;
    title?: string;
    value?: any;
    variant?: string;
    mask?: any;
}

const defaultProps = {
    variant: 'outlined',
};

const MaskedInputWithRef = React.forwardRef<HTMLInputElement, InputBaseComponentProps & Props>((topLevelProps, ref) => {
    const { inputRef, mask, ...restOfProps } = topLevelProps;
    const defaultMask = (value: any) => value.split('').map(() => /./);
    return (
        <MaskedInput
            mask={mask || false}
            render={(textMaskRef, props) => (
                <input
                    {...props}
                    ref={(node) => {
                        if (node) {
                            textMaskRef(node);
                            if (inputRef) {
                                if (typeof inputRef === 'function') {
                                    inputRef(node);
                                } else {
                                    (inputRef as MutableRefObject<HTMLInputElement>).current = node;
                                }
                            }
                            if (ref) {
                                (ref as MutableRefObject<HTMLInputElement>).current = node;
                            }
                        }
                    }}
                />
            )}
            {...restOfProps}
        />
    );
});

const TextInput: React.FunctionComponent<Props & Partial<OutlinedInputProps>> = (props) => {
    const {
        errorMessage,
        error,
        inputStyle,
        inputProps,
        mask,
        pipe,
        title,
        spacing,
        fullheight,
        variant,
        value,
        onChange,
        ...restOfProps
    } = props;
    const { id } = restOfProps;
    // TODO: fix, these are causing console error, not effecting flow though
    const maskedInputProps =
        mask && !!mask.length
            ? { keepCharPositions: true, guide: !!pipe, mask, pipe, inputRef: restOfProps.inputRef }
            : {};
    const inputComponent = mask && !!mask.length ? MaskedInputWithRef : undefined;

    const [myValue, setMyValue] = useState<any>(value);

    useEffect(() => {
        // tslint:disable-next-line:no-console
        console.log(`${id} mounted`);
        return () => {
            // tslint:disable-next-line:no-console
            console.log(`${id} un-mounted`);
        };
    }, []);

    useEffect(() => {
        setMyValue(value);
    }, [value]);

    // there are instances where react can lose cursor position if the state isn't kept directly with the input component
    // so we do this in order to keep the cursor from jumping to the end after a change
    const myChange = (e: any) => {
        setMyValue(e.target.value);
        onChange && onChange(e);
    };

    const renderOutlined = () => (
        <OutlinedInput
            value={myValue}
            label={''}
            id={title}
            inputProps={{ style: inputStyle, ...(inputProps || {}), ...maskedInputProps }}
            inputComponent={inputComponent}
            onChange={myChange}
            error={!!errorMessage || error}
            {...restOfProps}
        />
    );

    const renderUnderlined = () => (
        <Input
            id={title}
            value={myValue}
            onChange={myChange}
            inputProps={{ style: inputStyle, ...(inputProps || {}), ...maskedInputProps }}
            inputComponent={inputComponent}
            error={!!errorMessage || error}
            {...restOfProps}
        />
    );

    return (
        <InputWrapper title={title} spacing={spacing} error={errorMessage} fullHeight={fullheight}>
            {variant === 'underlined' && renderUnderlined()}
            {variant === 'outlined' && renderOutlined()}
        </InputWrapper>
    );
};

TextInput.defaultProps = defaultProps;
export default TextInput;
