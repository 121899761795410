import * as React from 'react';
import { useSelector } from 'react-redux';
import { RouteProps, Redirect } from 'react-router-dom';

import { getTopLevelUserGroup } from 'application/redux/selectors';
import { getIsAccountManagerRole, getIsAgentRole, getIsCarrierRepRole, getIsUnderwriterRole } from 'helpers/roles';
import { ROUTES } from '../../constants';

const HomeRoute: React.FunctionComponent<RouteProps> = () => {
    // Global State
    const group = useSelector(getTopLevelUserGroup);

    if (getIsAgentRole(group)) {
        return <Redirect to={'/agentdashboard'} />;
    }
    if (getIsCarrierRepRole(group)) {
        return <Redirect to={ROUTES.submissions()} />;
    }
    if (getIsUnderwriterRole(group) || getIsAccountManagerRole(group)) {
        return <Redirect to={'/submissions'} />;
    }

    return <Redirect to={'/lumendashboard'} />;
};

export default HomeRoute;
