import * as React from 'react';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import NavItem from './NavItem';
import { SubmissionDetails } from 'types/submission.type';
import { Badge } from '@mui/material';
import { NavActionComponentProps } from 'types/nav.types';
import NotesIcon from '@mui/icons-material/Chat';
import { fetchLogsCount } from '../../../services/logs';
import { LogOptions } from '../../../types/logs.types';
import { useSelector } from 'react-redux';
import { getTopLevelUserGroup } from '../../../application/redux/selectors';
import { getIsAgentRole } from '../../../helpers/roles';

interface SubmissionNotesActionProps extends NavActionComponentProps {
    submission: SubmissionDetails;
}

const SubmissionMessagingAction: FunctionComponent<SubmissionNotesActionProps> = (props) => {
    const { linkProps, key, submission } = { ...props };
    const notesOptions = { submissionId: submission.id } as LogOptions;
    const [hideBadge, setHideBadge] = useState<boolean>(true);
    const group = useSelector(getTopLevelUserGroup);
    const isAgent = getIsAgentRole(group);

    const fetchNotesCount = async () => {
        const response = await fetchLogsCount(notesOptions);
        if (response && response.logsCount !== '0') {
            setHideBadge(false);
        }
    };

    useEffect(() => {
        fetchNotesCount();
    }, []);
    const getIconComponent = () => (
        <>
            {isAgent && <NotesIcon fontSize="small" />}
            {!isAgent && (
                <Badge variant="dot" overlap={'circular'} color={'secondary'} invisible={hideBadge}>
                    <NotesIcon fontSize="small" />
                </Badge>
            )}
        </>
    );

    return (
        <>
            <NavItem key={key} to={linkProps.to} icon={getIconComponent()}>
                {linkProps.label}
            </NavItem>
        </>
    );
};

export default SubmissionMessagingAction;
