import actionCreator from '../lib/actionCreator';
import { REDUX } from '../../../constants';
import { Dispatch } from 'redux';
import { User } from 'types/users.types';
import { Organization } from '../../../types/organization.type';

export const setAssignedProducerAction = actionCreator(REDUX.SUBMISSION_USER_PREFERENCES);

export function setAssignedProducer(currentUser: User, agent: Organization) {
    return (dispatch: Dispatch) => {
        dispatch(setAssignedProducerAction.success({ [currentUser.id]: { assignedProducer: agent } }));
    };
}
