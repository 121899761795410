import MuiButton from './MuiButton';
import MuiButtonGroup from './MuiButtonGroup';
import MuiCard from './MuiCard';
import MuiCardActions from './MuiCardActions';
import MuiCardHeader from './MuiCardHeader';
import MuiCheckbox from './MuiCheckbox';
import MuiCircularProgress from './MuiCircularProgress';
import MuiDialogActions from './MuiDialogActions';
import MuiDialogContent from './MuiDialogContent';
import MuiFab from './MuiFab';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiFormLabel from './MuiFormLabel';
import MuiInputBase from './MuiInputBase';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiRadio from './MuiRadio';
import MuiSelect from './MuiSelect';
import MuiSwitch from './MuiSwitch';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTypography from './MuiTypography';
import MuiAlert from './MuiAlert';

const overrides = {
    MuiButton,
    MuiButtonGroup,
    MuiCard,
    MuiCardActions,
    MuiCardHeader,
    MuiCircularProgress,
    MuiCheckbox,
    MuiDialogActions,
    MuiDialogContent,
    MuiFab,
    MuiFormControlLabel,
    MuiFormLabel,
    MuiInputBase,
    MuiOutlinedInput,
    MuiRadio,
    MuiSelect,
    MuiSwitch,
    MuiSvgIcon,
    MuiTypography,
    MuiAlert,
};

export default overrides;
