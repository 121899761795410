const MuiCircularProgress = {
    colorPrimary: {
        color: '#ffdd00',
        fill: '#ffdd00',
    },
    colorSecondary: {
        color: '#fff',
        fill: '#fff',
    },
};

export default MuiCircularProgress;
