import { createSelector } from 'reselect';

import ApplicationState from '../store/ApplicationState';
import AuthenticationState from '../store/AuthenticationState';
import { AuthenticatedUser } from '../../Models/User';
import { getRole } from 'helpers/roles';

const authenticationState = (state: ApplicationState) => state.authentication;
const token = (state: AuthenticationState) => state.token;
const user = (state: AuthenticationState) => state.user;
const isAuthenticated = (state: AuthenticationState) => state.isAuthenticated;
const signInError = (state: AuthenticationState) => state.signInError;
const userGroups = (userState: AuthenticatedUser) => userState.groups || [];
const userName = (userState: AuthenticatedUser) => userState.name || '';
const topUserGroup = (userState: string[]) => {
    return getRole(userState);
};

export const getToken = createSelector(authenticationState, token);

export const getIsAuthenticated = createSelector(authenticationState, isAuthenticated);

export const getIsSignInError = createSelector(authenticationState, signInError);

export const getUser = createSelector(authenticationState, user);

export const getUserName = createSelector(getUser as any, userName);

export const getUserGroups = createSelector(getUser as any, userGroups);

export const getTopLevelUserGroup = createSelector(getUserGroups, topUserGroup);
