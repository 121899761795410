import ApplicationState from '../store/ApplicationState';
import { getSelectors } from '../lib/selectorCreator';

const dialogsState = (state: ApplicationState) => state.dialogs;

const selectors = getSelectors(dialogsState);

export const getDialog = selectors.getData;
export const getDialogLoading = selectors.getLoading;
export const getDialogError = selectors.getError;
