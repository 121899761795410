import styled from 'styled-components';
import { COLORS, FONTS } from 'constants/styles';

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 6px;
`;

export const Text = styled.div`
    font-family: ${FONTS};
    margin-left: 8px;
`;

export const BackLabelWrapper = styled(TextWrapper)`
    cursor: pointer;
    padding-left: 16px;
`;

export const BackLabel = styled(Text)`
    padding-top: 0;
    color: ${COLORS.SECONDARY};
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 29px;
`;
