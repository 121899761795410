import moment from 'moment';
import { getHeaders, SubmissionURL } from './config';
import { checkStatus, getData, getAll, sortByCreatedAt } from './normalizer';
import {
    AdjustmentGLReviewData,
    AdjustmentReviewData,
    DedBuyBackData,
    UnderwritingReviewRequest,
    UwReview,
} from 'types/underwriting.type';

function sortUwReviews(a: UwReview, b: UwReview) {
    if (moment(a.underwriterReview.createdAt).isBefore(b.underwriterReview.createdAt)) return 1;
    if (moment(a.underwriterReview.createdAt).isAfter(b.underwriterReview.createdAt)) return -1;
    return 0;
}

// TODO remove this method call altogether or add uwreview_id as a key to get a single review
// export async function fetchUWReview(submissionId: string) {
//     const url = `${SubmissionURL}/submission/${submissionId}/uw-review`;
//     const options = {
//         method: 'GET',
//         headers: getHeaders(),
//     };
//     return fetch(url, options).then(checkStatus).then(getData);
// }

export async function fetchAdjustmentReviewData(submissionId: string): Promise<AdjustmentReviewData> {
    const url = `${SubmissionURL}/submission/${submissionId}/adjustment-review`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus).then(getData);
}

export async function fetchAdjustmentGLReviewData(submissionId: string): Promise<AdjustmentGLReviewData> {
    const url = `${SubmissionURL}/submission/${submissionId}/adjustment-gl-review`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus).then(getData);
}

export async function fetchCatScore(underwriterReviewId: string) {
    const url = `${SubmissionURL}/aon/catscore?underwriter_review_id=${underwriterReviewId}`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus).then(getAll);
}

export async function fetchAonScore(submissionId: string, locationReviewIds?: string[]) {
    let url = `${SubmissionURL}/aon/aon_location?submission_id=${submissionId}`;
    url = url + (locationReviewIds ? `&location_review_ids=${locationReviewIds?.filter((lr) => !!lr).join(',')}` : '');
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus).then(getAll);
}

export async function fetchAllUwReviews(submissionId: string) {
    const url = `${SubmissionURL}/submission/${submissionId}/uw-reviews`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then(getAll)
        .then((data: UwReview[]) => sortByCreatedAt(data, sortUwReviews));
}

export async function postUWReview(submissionId: string, payload: UnderwritingReviewRequest[]) {
    const url = `${SubmissionURL}/submission/${submissionId}/uw-review`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };
    return fetch(url, options).then(checkStatus).then(getAll);
}

export async function postUWReviewDecline(submissionId: string, payload: {}) {
    const url = `${SubmissionURL}/submission/${submissionId}/uw-review-decline`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };
    return fetch(url, options).then(checkStatus);
}
