import { getHeaders, SubmissionURL } from './config';
import { checkStatus, getAll, getData } from './normalizer';
import { OrganizationPayload, Organization } from 'types/organization.type';
import { ProducerDocument } from '../types/document.type';

export function updateProducerDocument(organizationId: string, payload: ProducerDocument[]) {
    return fetch(`${SubmissionURL}/organizations/${organizationId}/documents`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ producerDocuments: payload }),
    })
        .then(checkStatus)
        .then(getData);
}

export function transformOne(org: Organization): Organization {
    if (org.commissionPercentage && Number(org.commissionPercentage)) {
        org.commissionPercentage = Number(org.commissionPercentage) * 100;
    }
    return org;
}

export function transformData(data: Organization[]) {
    return data.map((org: Organization) => {
        return transformOne(org);
    });
}

export async function getOrganizations() {
    return fetch(`${SubmissionURL}/organizations`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getAll)
        .then(transformData);
}

export async function getAllLicencedAgentsAndParents() {
    return getOrganizations().then((ret) => ret.filter((a) => a.isLicensedAgent || a.organizationType === 'AGENCY'));
}

export async function getAllLicensedAgents() {
    return fetch(`${SubmissionURL}/organizations/licensed_agents`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getAll)
        .then(transformData);
}

export async function getOrganizationTreeByMember(id: string) {
    return fetch(`${SubmissionURL}/organizations/${id}/organizationTree`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getAll)
        .then(transformData);
}

export async function getOrganizationTreeByRoot(id: string) {
    return fetch(`${SubmissionURL}/organizations/${id}/childOrganizationTree`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getAll)
        .then(transformData);
}

export async function getOrganizationTreeByGrantors(id: string) {
    return fetch(`${SubmissionURL}/organizations/${id}/childGrantorsOrganizationTree`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getAll)
        .then(transformData);
}

export async function getOneOrganization(id: string) {
    return fetch(`${SubmissionURL}/organizations/${id}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getData)
        .then(transformOne);
}

export async function createOrganization(payload: OrganizationPayload) {
    return fetch(`${SubmissionURL}/organizations`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    })
        .then(checkStatus)
        .then(getData);
}
