import { StyleRules } from '@mui/styles';
import styled from 'styled-components';
import { COLORS } from 'constants/index';

interface Props {
    noMargin?: boolean;
}

export const Wrapper = styled.div`
    margin-top: ${(props: Props) => (props.noMargin ? '0' : '12')}px;
    margin-bottom: ${(props: Props) => (props.noMargin ? '0' : '12')}px;
    border: 1px solid transparent;
    &:hover {
        border: 1px solid ${COLORS.GRAY_LIGHT};
        border-radius: 6px;
    }
`;

const styles = (): StyleRules => ({
    editIcon: {
        display: 'none',
        marginLeft: '5px',
    },
    editInput: {
        '& .MuiOutlinedInput-input': {
            padding: '5px',
        },
    },
    wrapperDiv: {
        '&:hover': {
            '& $editIcon': {
                display: 'inline-block',
            },
        },
    },
    iconButton: {
        '&:hover': {
            borderRadius: '6px',
        },
    },
    fileButton: {
        textTransform: 'none',
        '&:hover': {
            background: 'none',
        },
        '&.Mui-disabled': {
            color: 'inherit',
            background: 'inherit',
        },
    },
});

export default styles;
