import { createSelector } from 'reselect';
import ApplicationState from '../store/ApplicationState';

import { getSelectors } from '../lib/selectorCreator';
import { Quote } from 'types/quote.type';

const quotesState = (state: ApplicationState) => state.quotes;
const latestQuote = (quotes: Quote[]) => (quotes ? quotes[0] : undefined);

const selectors = getSelectors(quotesState);

export const getQuotesLoading = selectors.getLoading;
export const getQuotesError = selectors.getError;
export const getQuotesData = selectors.getData;
export const getQuotesHasResolved = selectors.getHasResolved;

export const getQuotesLatest = createSelector(getQuotesData, latestQuote);
