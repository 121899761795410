import { Dispatch } from 'redux';
import { fetchAllUwReviews } from 'services/uwReview';
import actionCreator from '../lib/actionCreator';
import { REDUX } from 'constants/index';
import PropertyTableRowModel from 'components/Underwriting/components/PropertyTable/PropertyTableRowModel';
import { UnderwriterReview } from '../../../types/underwriting.type';
import { UwSummarySubjectivity } from '../../Models/UwSummarySubjectivity';

const uwReviewFetchAction = actionCreator(REDUX.UW_REVIEW);
const uwReviewsFetchAction = actionCreator(REDUX.UW_REVIEWS);

// TODO remove this method call altogether or add uwreview_id as a key to get a single review
// export function uwReviewFetchData(submissionId: string) {
//     return async (dispatch: Dispatch) => {
//         dispatch(uwReviewFetchAction.loading());
//         fetchUWReview(submissionId)
//             .then((response) => {
//                 dispatch(uwReviewFetchAction.success(response));
//             })
//             .catch((reason) => {
//                 dispatch(uwReviewFetchAction.error(reason));
//             });
//     };
// }

export function uwReviewsFetchData(submissionId: string) {
    return async (dispatch: Dispatch) => {
        dispatch(uwReviewsFetchAction.loading());
        fetchAllUwReviews(submissionId)
            .then((response) => {
                dispatch(uwReviewsFetchAction.success(response));
            })
            .catch((reason) => {
                dispatch(uwReviewsFetchAction.error(reason));
            });
    };
}

export function uwReviewSaveReview(uwReview: UnderwriterReview) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: REDUX.UW_REVIEW_SAVE_UW_REVIEW, payload: uwReview });
    };
}

export function uwReviewSaveOneProperty(data: PropertyTableRowModel) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: REDUX.UW_REVIEW_SAVE_ONE_PROPERTY, payload: { data } });
    };
}

export function uwReviewSaveAllProperties(data: PropertyTableRowModel[]) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: REDUX.UW_REVIEW_SAVE_ALL_PROPERTIES, payload: data });
    };
}

export function uwReviewSaveAllSubjectivities(data: UwSummarySubjectivity[]) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: REDUX.UW_REVIEW_SAVE_ALL_SUBJECTIVITIES, payload: data });
    };
}

export function uwReviewSaveGLReview(uwReview: UnderwriterReview) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: REDUX.UW_REVIEW_SAVE_UW_GL_REVIEW, payload: uwReview });
    };
}

export function uwReviewSaveOneGLProperty(data: PropertyTableRowModel) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: REDUX.UW_REVIEW_SAVE_ONE_GL_PROPERTY, payload: { data } });
    };
}

export function uwReviewSetGLInitialized(initialized: boolean) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: REDUX.UW_REVIW_GL_INITIALIZED, payload: initialized });
    };
}

export function uwReviewSaveAllGLProperties(data: PropertyTableRowModel[]) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: REDUX.UW_REVIEW_SAVE_ALL_GL_PROPERTIES, payload: data });
    };
}
