import * as React from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Margin } from 'components/Layout';
import { StyledNavLink, StyledNavAction, NavStep } from '../styled/NavItem';
import CheckIcon from '@mui/icons-material/Check';

interface Step {
    number: number;
    isComplete: boolean;
}

export interface NavItemProps {
    icon?: any;
    step?: Step;
    nested?: boolean;
    to?: string;
    disabled?: boolean;
    children: React.ReactNode;
    onClick?: () => void;
}

const NavItem: React.FunctionComponent<NavItemProps> = (props) => {
    const { children, icon, nested, onClick, step, to, disabled } = props;

    const StyledNavItem = (onClick ? StyledNavAction : StyledNavLink) as any;

    const ignoreClick = (e: Event) => e.preventDefault();

    const renderStep = () => (
        <NavStep isComplete={!!step && step.isComplete}>
            {step && !step.isComplete && (
                <Typography variant="body2" align="center" style={{ marginBottom: 0, color: 'inherit' }}>
                    {step.number}
                </Typography>
            )}
            {step && step.isComplete && <CheckIcon style={{ fontSize: '13px' }} />}
        </NavStep>
    );

    return (
        // @ts-ignore
        <StyledNavItem
            to={to}
            $nested={nested || undefined}
            onClick={(!!step && !step.isComplete) || disabled ? ignoreClick : onClick}
            $step={!!step || undefined}
            $disabled={disabled}
        >
            <Grid container alignItems="center" spacing={0}>
                {icon && <Margin right="13">{icon}</Margin>}
                {step && renderStep()}
                <Typography variant="body2" style={{ margin: 0 }}>
                    {children}
                </Typography>
            </Grid>
        </StyledNavItem>
    );
};

export default NavItem;
