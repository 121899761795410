import * as React from 'react';
import { forwardRef, PropsWithChildren, useEffect, useImperativeHandle } from 'react';

interface BaseProps {
    blob?: Blob;
    onDownloadProgress?: (progress: DownloadButtonProgress, msg?: string) => void;
    maxWidth?: string;
    hide?: boolean;
    children?: React.ReactNode;
    disabled?: boolean;
}

interface SingleProps extends BaseProps {
    dataFetchFn?: () => Promise<any>;
    fileName: string;
    files?: never;
}

interface MultiProps extends BaseProps {
    fileName?: never;
    dataFetchFn?: never;
    files: DownloadableFile[];
}

export interface DownloadableFile {
    dataFetchFn?: () => Promise<any>;
    fileName: string;
}

type Props = SingleProps | MultiProps;

export enum DownloadButtonProgress {
    IN_PROGRESS,
    SUCCESS,
    ERROR,
}

export interface LrDownloadButtonAPI {
    doDownload: () => void;
}

const LrDownloadButton: React.RefForwardingComponent<LrDownloadButtonAPI, PropsWithChildren<Props>> = (props, ref) => {
    const { dataFetchFn, blob, onDownloadProgress, fileName, children, maxWidth, hide, files, disabled } = { ...props };

    const [clickAnchor, setClickAnchor] = React.useState({} as HTMLAnchorElement);
    const [downloadFiles, setDownloadFiles] = React.useState<
        { dataFetchFn?: () => Promise<any>; fileName: string }[] | undefined
    >(fileName ? [{ dataFetchFn: dataFetchFn, fileName: fileName }] : undefined);

    useEffect(() => {
        if (files) {
            setDownloadFiles(files);
        }
    }, [files]);

    useImperativeHandle(ref, () => ({
        doDownload: () => {
            downloadFile();
        },
    }));

    function executeDownload(tmpBlob: Blob, downloadfileName: string) {
        const url = URL.createObjectURL(tmpBlob);
        clickAnchor.href = url;
        clickAnchor.download = downloadfileName;
        clickAnchor.click();
        URL.revokeObjectURL(url);
    }

    const downloadFile = async () => {
        if (!disabled && ((!!downloadFiles && downloadFiles[0].dataFetchFn) || blob)) {
            try {
                onDownloadProgress && onDownloadProgress(DownloadButtonProgress.IN_PROGRESS);
                let tmpBlob = blob;

                if (!!downloadFiles && downloadFiles[0].dataFetchFn) {
                    await Promise.all(
                        downloadFiles.map(async (file) => {
                            if (file.dataFetchFn) {
                                const { body } = await file.dataFetchFn();
                                const docStream = new Response(body);
                                tmpBlob = await docStream.blob();
                                executeDownload(tmpBlob, file.fileName);
                            }
                        })
                    );
                } else if (tmpBlob && fileName) {
                    executeDownload(tmpBlob, fileName);
                }
                onDownloadProgress && onDownloadProgress(DownloadButtonProgress.SUCCESS);
            } catch (e) {
                onDownloadProgress && onDownloadProgress(DownloadButtonProgress.ERROR, e.message);
            }
        }
    };

    return (
        <>
            <a ref={(input) => input && setClickAnchor(input)} />
            <div
                style={{ display: hide ? 'none' : 'inline-block', maxWidth: maxWidth || '100%' }}
                onClick={downloadFile}
            >
                {children}
            </div>
        </>
    );
};

const ForwardedDownloadButton = forwardRef(LrDownloadButton);
export default ForwardedDownloadButton;
