import { store } from 'application/redux/store';
import { getToken } from 'application/redux/selectors';

export const SubmissionURL = process.env.REACT_APP_SUBMISSION_SERVICE;
export const DocumentURL = process.env.REACT_APP_DOCUMENT_SERVICE;

export const getHeaders = (options?: any) => {
    return new Headers(getHeadersAsDict(options));
};

export const getHeadersAsDict = (options?: any) => {
    // @ts-ignore
    const token = getToken(store.getState());
    if (!token) {
        // tslint:disable-next-line:no-console
        console.warn('Missing Token, Timing Issue!');
    }
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...options,
    };
};
export const getQueryString = (params: object) => {
    if (!params || params === {}) return '';
    const query = Object.keys(params)
        .filter((key) => params[key] != null)
        .map((key) => key + '=' + (Array.isArray(params[key]) ? params[key].join(',') : params[key]))
        .join('&');
    return `?${query}`;
};
