import { Dispatch } from 'redux';
import { REDUX } from '../../../constants';
import actionCreator from '../lib/actionCreator';
import { fetchReportingLocations } from 'services/reporting';

const reportingLocationsFetchAction = actionCreator(REDUX.REPORTING_LOCATIONS);

export function reportingFetchLocations() {
    return async (dispatch: Dispatch) => {
        dispatch(reportingLocationsFetchAction.loading());
        fetchReportingLocations()
            .then((response) => {
                dispatch(reportingLocationsFetchAction.success(response));
            })
            .catch((reason) => {
                dispatch(reportingLocationsFetchAction.error(reason));
            });
    };
}

export function setReportingPrimaryLoaded(isLoaded: boolean) {
    return (dispatch: Dispatch) => {
        dispatch({ type: REDUX.REPORTING_PRIMARY_LOADED, payload: isLoaded });
    };
}

export function setReportingSelectedReport(selectedReport: string) {
    return (dispatch: Dispatch) => {
        dispatch({ type: REDUX.REPORTING_SELECTED_REPORT, payload: selectedReport });
    };
}
