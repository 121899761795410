import * as React from 'react';
import { Card, CardActions, MenuItem, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import LrButton from 'components/LrButton';
import LrDropzone from 'components/LrDropzone';
import { LrFileItemList } from 'components/LrFileItems';
import { Select, TextInput } from 'components/Input';
import { createLog } from 'services/logs';
import { COLORS } from 'constants/index';
import { documents } from 'application/Providers/API';
import { LogCreateOptions, LogOptions } from 'types/logs.types';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDocumentTypesByAction } from '../../../application/redux/selectors';

interface Props {
    error?: boolean;
    options: LogOptions;
    onNoteSubmitted?: (result?: LogCreateOptions, context?: any) => void;
    onNoteChange?: (note: string) => void;
    onFilesChange?: (files: any[]) => void;
    showCancel?: boolean;
    hideSubmit?: boolean;
    onCancel?: () => void;
    flat?: boolean;
    placeholder?: string;
    suppressSuccessMessage?: boolean;
    /**
     * @default true
     */
    doCreate?: boolean;
    notesActionType?: string;
    messageRows?: number;
    defaultNoteText?: string;
    noteTypeOptions?: Record<number, string>;
}

export interface NotesInputAPI {
    doSubmit: (context?: any) => void;
}

const NotesInput: React.ForwardRefRenderFunction<NotesInputAPI, Props> = (props: Props, ref) => {
    const {
        onNoteSubmitted,
        options,
        flat,
        showCancel,
        hideSubmit,
        onCancel,
        suppressSuccessMessage,
        doCreate,
        error,
        onNoteChange,
        onFilesChange,
        placeholder,
        notesActionType,
        messageRows,
        defaultNoteText,
        noteTypeOptions,
    } = props;

    const doCreateInternal = doCreate === undefined ? true : doCreate;

    // Local State
    const [newNote, setNewNote] = React.useState(defaultNoteText || '');
    const [isNoteValid, setIsNoteValid] = React.useState(false);
    const [files, setFiles] = React.useState([] as any[]);
    const [actionInProgress, setActionInProgress] = React.useState(false);
    const [actionError, setActionError] = React.useState(false);
    const [actionSuccess, setActionSuccess] = React.useState(false);
    const selectOptions = useSelector(getDocumentTypesByAction(notesActionType || ''));
    const fileTypeOptions = notesActionType ? selectOptions : undefined;
    const showTypeSelect = !!noteTypeOptions;
    const [noteTypeSelected, setNoteTypeSelected] = useState(options?.logTypeId);

    const addFile = (addedFiles: any[]) => setFiles([...files, ...addedFiles]);

    const successMessageTimer = () => {
        if (!actionSuccess) return;
        const timer = setTimeout(() => {
            setActionSuccess(false);
        }, 3000);
        return () => clearTimeout(timer);
    };

    const setNoteValidity = () => setIsNoteValid(!!files.length || !!newNote.length);
    const showSubmit = !hideSubmit && !!onNoteSubmitted;
    React.useEffect(setNoteValidity, [newNote, files]);
    React.useEffect(() => onFilesChange && onFilesChange(files), [files]);

    React.useEffect(successMessageTimer, [actionSuccess]);

    const mapDocsToFiles = (docIds: any[]) =>
        files.map((f, idx) => ({
            uuid: docIds[idx].documentIds[0],
            fileName: f.name,
            title: f.title,
            description: f.description,
            documentType: f.fileType?.code || 'LOG',
            year: f.fileYear || new Date().getFullYear(),
        }));

    const submitNote = async (context?: any) => {
        try {
            setActionInProgress(true);
            const filePromises = files.map((f) => documents.sendFile(f));
            const docIds = await Promise.all(filePromises);
            const documentList = mapDocsToFiles(docIds);
            const noteOpts: LogCreateOptions = {
                ...options,
                logTypeId: noteTypeSelected,
                message: newNote,
                documents: documentList,
            };
            if (doCreateInternal) {
                await createLog(noteOpts);
            }
            setNewNote('');
            setFiles([]);
            setActionSuccess(true);
            onNoteSubmitted && onNoteSubmitted(noteOpts, context);
        } catch (e) {
            setActionError(true);
        }
        setActionInProgress(false);
    };

    const styles = flat ? { boxShadow: 'none', padding: '0', borderRadius: '0' } : {};

    useImperativeHandle(ref, () => ({
        doSubmit: (context?: any) => {
            submitNote(context);
        },
    }));

    return (
        <Card style={styles}>
            <Grid container direction="row" spacing={0}>
                <Grid xs={7}>
                    <TextInput
                        spacing={0}
                        multiline
                        rows={messageRows || 5}
                        value={newNote}
                        onChange={({ target }: any) => {
                            setNewNote(target.value);
                            onNoteChange && onNoteChange(target.value);
                        }}
                        placeholder={placeholder || 'Type your message here...'}
                        error={error}
                    />
                </Grid>
                <Grid xs={5}>
                    <LrDropzone nested onFileDrop={addFile} />
                </Grid>
            </Grid>
            <LrFileItemList
                files={files}
                removable
                onFilesChange={setFiles}
                editable
                clickable
                fileTypeOptions={fileTypeOptions || undefined}
            />
            <CardActions>
                {showCancel && (
                    <LrButton lrType="text" onClick={onCancel}>
                        Cancel
                    </LrButton>
                )}
                {showSubmit && (
                    <LrButton
                        lrType="primary"
                        onClick={submitNote}
                        isLoading={actionInProgress}
                        disabled={!isNoteValid || actionInProgress}
                    >
                        Submit
                    </LrButton>
                )}
                {showTypeSelect && (
                    <Select
                        onChange={(event: any) => setNoteTypeSelected(event.target.value)}
                        value={noteTypeSelected}
                        color={'secondary'}
                        title={'Select Note Type'}
                        sx={{ width: '200px', marginTop: '-25px' }}
                    >
                        {noteTypeOptions &&
                            Object.keys(noteTypeOptions).map((noteTypeId: string, menuIdx: number) => (
                                <MenuItem key={`menu-item-${menuIdx}`} value={noteTypeId}>
                                    {noteTypeOptions[noteTypeId].displayValue}
                                </MenuItem>
                            ))}
                    </Select>
                )}
                {actionError && (
                    <Typography variant="subtitle1" style={{ color: COLORS.ERROR }}>
                        Something went wrong, please try again
                    </Typography>
                )}
                {!suppressSuccessMessage &&
                actionSuccess && ( // TODO consider using Snackbar instead
                        <Typography variant="subtitle1" style={{ color: COLORS.SUCCESS }}>
                            Note successfully submitted!
                        </Typography>
                    )}
            </CardActions>
        </Card>
    );
};

const ForwardedNotesInput = forwardRef(NotesInput);
export default React.memo(ForwardedNotesInput);
