import { StyleRules } from '@mui/styles';

const styles = (): StyleRules => ({
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '36px',
        flex: '1 1 auto',
        position: 'relative',
        overflowY: 'auto',
        // '&:hover $controlPanel':{
        //     opacity:'.9'
        // }
    },
});

export default styles;
