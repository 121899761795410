import { documents } from 'application/Providers/API';
import { DocumentURL } from 'services/config';
import ViewIMG from '../components/ViewIMG';
import ViewPDF from '../components/ViewPDF';
import * as React from 'react';
import { ComponentElement } from 'react';

export interface FileViewerProps {
    isOpen: boolean;
    fileName: string;
    fileId?: string;
    fileTitle?: string;
    fileUrl?: string;
    initialPageNumber?: number;
    onClose: () => void;
    blob?: Blob;
}

export interface PDFViewerProps {
    fileUrl: string;
    initialPageNumber?: number;
    blob?: Blob;
    fileName: string;
}

export const getFileViewer = (name: string): any => {
    const fileExtension = name && name.split('.').pop();
    const isPDF = fileExtension && fileExtension.toLowerCase() === 'pdf';
    const isIMG = fileExtension && ['jpg', 'png', 'jpeg', 'bmp'].includes(fileExtension.toLowerCase());
    if (isIMG) {
        return ViewIMG;
    }
    if (isPDF) {
        return ViewPDF;
    } else {
        return undefined;
    }
};

export const getFileSrc = async (fileName: string, fileId?: string, fileUrl?: string, blob?: Blob) => {
    // first if we have a blob, then that is our source
    if (blob) {
        return URL.createObjectURL(blob);
    }

    // if we have a fileUrl then return that
    if (fileUrl) {
        return fileUrl;
    }

    // then if we have an id then build a document url
    if (fileId) {
        const rawDoc = await documents.getDocument(fileId, fileName);
        const rawBlob = await rawDoc.blob();
        return URL.createObjectURL(rawBlob);
    }

    // if just a filename, then we assume this is a static file served from document service
    return `${DocumentURL}/static/${encodeURI(fileName)}`;
};

export const revokeFileSrc = (fileUrl: string) => {
    if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
    }
};
