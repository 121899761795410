import { createSelector } from 'reselect';

export interface ReducerState {
    data: any;
    isLoading: boolean;
    error: string;
    hasResolved: boolean;
}

const data = (state: ReducerState) => state.data;
const error = (state: ReducerState) => state.error;
const loading = (state: ReducerState) => state.isLoading;
const hasResolved = (state: ReducerState) => state.hasResolved;

export const getSelectors = (state: any) => {
    return {
        getData: createSelector(state, data),
        getError: createSelector(state, error),
        getLoading: createSelector(state, loading),
        getHasResolved: createSelector(state, hasResolved),
    };
};
