import React from 'react';
import { Card, Typography, CardContent } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import styles from './styles';
import { ReactComponent as Logo } from 'assets/lumen_logo.svg';
import { Spacing } from 'components/Layout/index';
import Logout from 'views/Logout';
import { withStyles } from '@mui/styles';

interface LoadingProps {
    classes: any;
}

const NotFound: React.FunctionComponent<LoadingProps> = ({ classes }) => {
    return (
        <>
            <Grid className={classes.root} container alignContent="center" alignItems="center" justifyContent="center">
                <Grid className={classes.content}>
                    <Card className={classes.card}>
                        <div className={classes.cardHeader}>
                            <Logo />
                        </div>
                        <CardContent className={classes.content}>
                            <Typography variant="h5">Uh-oh...your user is not in an organization</Typography>
                            <Spacing height="32px" />
                            <Typography variant="body2">Please contact Lumen support to let us know.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Logout delayMs={3000} />
        </>
    );
};

export default withStyles(styles)(NotFound);
