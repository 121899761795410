import { ROUTES, STATUS_TYPES } from 'constants/index';
import SubmissionListItem from 'application/Models/SubmissionListItem';
import { SubmissionDetails } from '../types/submission.type';
import Location from '../application/Models/Location';
import moment from 'moment';

export const createUserSubmissionUrl = (submission: SubmissionListItem | SubmissionDetails) => {
    if (submission.statusType.code === STATUS_TYPES.INCOMPLETE_SUBMISSION) {
        return ROUTES.submissionForm(submission.id);
    }
    return ROUTES.submissionDetail(submission.id);
};

export const getProductFilteredLocations = (
    productLineCode: string,
    sovLocations: Location[],
    submission: SubmissionDetails,
    returnAvailable = true
) => {
    const myPolicy = submission?.policies?.find((p) => p.productLineType === productLineCode);
    if (myPolicy) {
        return myPolicy.effectivePolicyLocations.map((epl) => epl.location);
    } else {
        // for a given product line a location is available for UW if the have  a "cleared" action and aren't "declined"
        return sovLocations.filter((l) => {
            let isCleared = false;
            let isDeclined = false;
            isCleared = !!l.isClearedByProductLine && (l.isClearedByProductLine[productLineCode] || false);
            isDeclined = !!l.isUWDeclinedByProductLine && (l.isUWDeclinedByProductLine[productLineCode] || false);
            // let seenClear = false;
            // let seenDecline = false;
            // l.locationActions?.sort((a1, a2)=>{
            //     return moment(a2.actionDateTime).isAfter(moment(a1.actionDateTime)) ?  - 1 : 1;
            // }).forEach(action=>{
            //     if(action.productLineCode === productLineCode){
            //         if(action.actionType === 'CLEARANCE' && !seenClear){
            //             if(action.isActionTrue){
            //                 isCleared = true;
            //             }
            //             seenClear = true;
            //         }
            //         if(action.actionType === 'UW_DECLINE' && !seenDecline){
            //             if(action.isActionTrue){
            //                 isDeclined = true;
            //             }
            //             seenDecline= true;
            //         }
            //     }
            // })

            const isAvailable = isCleared && !isDeclined;
            return returnAvailable === isAvailable; // XNOR operation
        });
    }
};

export const filterLocationsAvailableForUW = (locationList: Location[], submission: SubmissionDetails): Location[] =>
    locationList.filter(
        (l) =>
            (submission.isEndorsement && submission.endorsementType?.code !== 'MONIED_LOCATION_ADDITION') ||
            !l.markedAsDeleted
    );

export const isInUnderwriting = (submission: SubmissionDetails): boolean =>
    submission.statusType.code === STATUS_TYPES.READY_FOR_UNDERWRITING ||
    submission.statusType.code === STATUS_TYPES.REQUOTE_REQUESTED ||
    submission.statusType.code === STATUS_TYPES.QUOTED; // technically even after a quote is out, we are still able to underwrite for a new quote
