import { UploadListType, ResourceDocumentType } from 'types/document.type';

export const UPLOAD_LIST_TYPE: { [key: string]: UploadListType } = {
    SUBMISSION: 'SUBMISSION',
    QUOTE: 'QUOTE',
    BIND_REQUEST: 'BIND_REQUEST',
    ISSUANCE: 'ISSUANCE',
};

export const RESOURCE_DOCUMENT_TYPE: { [key: string]: ResourceDocumentType } = {
    ACORD: 'ACORD',
    LOSS_RUN: 'LOSS_RUN',
    BINDER: 'BINDER',
    INVOICE: 'INVOICE',
    POLICY: 'POLICY',
    ENDORSEMENT: 'ENDORSEMENT',
    SIGNED_DOCUMENT: 'SIGNED_DOCUMENT',
    OTHER: 'OTHER',
    QUOTE: 'QUOTE',
    PRODUCER_LICENSE: 'PRODUCER_LICENSE',
    EANDO_CERT: 'EANDO_CERT',
    SIGNED_PRODUCER_AGREEMENT: 'SIGNED_PRODUCER_AGREEMENT',
    AGENCY_LICENSE: 'AGENCY_LICENSE',
    SUPPORTING_DOCUMENT: 'SUPPORTING_DOCUMENT',
    PHOTO: 'PHOTO',
    CORRESPONDENCE: 'CORRESPONDENCE',
    INSPECTION_REPORT: 'INSPECTION_REPORT',
    INSPECTION_RECOMMENDATION: 'INSPECTION_RECOMMENDATION',
    OTHER_SOV: 'OTHER_SOV',
    SUPPLEMENTAL_APP: 'SUPPLEMENTAL_APP',
    LUMEN_SOV: 'LUMEN_SOV',
};
