import { getHeaders, SubmissionURL } from './config';
import { checkStatus, getData } from './normalizer';
import { setTypes } from '../helpers/typeHelper';
import { Referral, ReferralCreateRequest } from '../types/referrals.type';
import { LogCreateOptions } from '../types/logs.types';

export async function fetchSubmissionReferralMetadata(submissionId: string) {
    const url = `${SubmissionURL}/referrals/metadata?submissionId=${submissionId}`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options)
        .then(checkStatus)
        .then((data) => data.json())
        .then(setTypes);
}

export async function createReferral(submissionId: string, payload: ReferralCreateRequest) {
    const url = `${SubmissionURL}/referrals`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };
    return fetch(url, options).then(checkStatus).then(getData).then(setTypes);
}

export async function updateReferral(
    submissionId: string,
    referralId: string,
    payload: { referral: Referral; updateReferralMessage: LogCreateOptions }
) {
    const url = `${SubmissionURL}/referrals/${referralId}?submissionId=${submissionId}`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };
    return fetch(url, options).then(checkStatus).then(getData).then(setTypes);
}

export async function toggleReferralHold(submissionId: string, referralId: string) {
    const url = `${SubmissionURL}/referrals/${referralId}/recall?submissionId=${submissionId}`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus).then(getData).then(setTypes);
}
