export const GRID_NAMES = {
    SUBMISSIONS: 'SUBMISSIONS',
    CLEARING: 'CLEARING',
    SOV: 'SOV',
};

export const GRID_DATA_TYPES = {
    COLUMNS: 'COLUMNS',
    FILTERS: 'FILTERS',
};
