import styled from 'styled-components';
import { COLORS } from 'constants/index';

export const MessagingClientStyleWrapper = styled.div`
    & .rcw-launcher,
    & .rcw-conversation-container .rcw-header {
        background-color: ${COLORS.PRIMARY_LIGHT};
        color: ${COLORS.SECONDARY};
    }

    & .rcw-client .rcw-message-text {
        background-color: ${COLORS.PRIMARY_LIGHTER};
        color: ${COLORS.SECONDARY};
    }

    & .rcw-response .rcw-message-text {
        background-color: ${COLORS.SECONDARY_LIGHTER};
        color: ${COLORS.SECONDARY};
    }

    & .rcw-close-launcher {
        width: 36px;
    }

    & .quick-button {
        border-color: ${COLORS.PRIMARY_LIGHTER};
        color: ${COLORS.SECONDARY_LIGHTER};
        &:active {
            background: ${COLORS.PRIMARY_LIGHTER};
        }
    }
`;
