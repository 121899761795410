import { createSelector } from 'reselect';

import ApplicationState from '../store/ApplicationState';
import ReportingState, { ReportingStateLocationData } from '../store/ReportingState';

const reportingState = (state: ApplicationState) => state.reporting;
const reportingPrimaryLoaded = (state: ReportingState) => state.reportingPrimaryLoaded;
const reportingSelectedReport = (state: ReportingState) => state.reportingSelectedReport;
const reportingLocations = (state: ReportingState) => state.reportingLocations;
const reportingLocationsData = (state: ReportingStateLocationData) => state.data;
const reportingLocationsLoading = (state: ReportingStateLocationData) => state.isLoading;
const reportingLocationsError = (state: ReportingStateLocationData) => state.error;

const getReportingLocationsData = createSelector(reportingState, reportingLocations);

export const getReportingLocations = createSelector(getReportingLocationsData, reportingLocationsData);

export const getReportingLocationsLoading = createSelector(getReportingLocationsData, reportingLocationsLoading);

export const getReportingLocactionError = createSelector(getReportingLocationsData, reportingLocationsError);

export const getReportingPrimaryLoaded = createSelector(reportingState, reportingPrimaryLoaded);

export const getReportingSelectedReport = createSelector(reportingState, reportingSelectedReport);
