import ApplicationState from '../store/ApplicationState';
import { getGridSelectors } from '../lib/gridSelectorCreators';

const clearingState = (state: ApplicationState) => state.clearing;

const gridSelectors = getGridSelectors(clearingState);

export const getClearingsGridData = gridSelectors.getGridData;
export const getClearingsGridColumns = gridSelectors.getGridColumns;
export const getClearingsGridFilters = gridSelectors.getGridFilters;
export const getClearingsGridError = gridSelectors.getGridError;
