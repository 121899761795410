import * as React from 'react';
import { MenuItem, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { GridDirection } from '@mui/material/Grid';

import { Select } from 'components/Input';
import { Spacing } from 'components/Layout';
import { DocumentType } from 'types/types.type';

import FileItem from './FileItem';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getProductLineTypesByCode } from '../../../application/redux/selectors';

interface Props {
    clickable?: boolean;
    editable?: boolean;
    disableIdFiles?: boolean;
    direction?: GridDirection;
    files?: any[];
    fileTypeOptions?: DocumentType[];
    fileTypeNeedsYear?: { [docType: string]: string };
    fileTypeNeedsProduct?: { [docType: string]: { productsAvailable: string[]; toolTip: string } };
    removable?: boolean;
    onFilesChange?: (newFiles: any[]) => void;
}

const defaultProps = {
    clickable: false,
    files: [],
    removable: false,
};

const FileItemList: React.FunctionComponent<Props> = (props) => {
    const {
        editable,
        clickable,
        disableIdFiles,
        direction,
        files,
        fileTypeOptions,
        fileTypeNeedsYear,
        fileTypeNeedsProduct,
        onFilesChange,
        removable,
    } = props;

    // Global State
    const productLineTypes = useSelector(getProductLineTypesByCode);

    // Local State
    const currentYear = new Date().getFullYear();
    const years = [...new Array(10)];
    const yearOptions = years.map((n, idx) => currentYear - idx);
    const [fileYearSelectTooltipOpen, setFileYearSelectTooltipOpen] = useState<{ [idx: number]: boolean }>(
        files?.reduce((d, n, idx) => {
            d[idx] = false;
            return d;
        }, {})
    );
    const [fileProductSelectTooltipOpen, setFileProductSelectTooltipOpen] = useState<{ [idx: number]: boolean }>(
        files?.reduce((d, n, idx) => {
            d[idx] = false;
            return d;
        }, {})
    );

    const handleChangeFile = (newFileTitle: string, newFileDescription: string, fileIdx: number) => {
        if (!files || !onFilesChange) return;
        files[fileIdx].title = newFileTitle;
        files[fileIdx].description = newFileDescription;
        onFilesChange([...files]);
    };

    const handleRemoveFile = (fileIdx: number) => {
        if (!files || !onFilesChange) return;
        const newFiles = files.slice(0, fileIdx).concat(files.slice(fileIdx + 1));
        onFilesChange(newFiles);
    };

    const handleFileTypeChange = (code: string, fileIdx: number) => {
        if (!files || !fileTypeOptions || !onFilesChange) return;
        const selectedType = fileTypeOptions.find((o) => o.code === code);
        files[fileIdx].fileType = selectedType;
        if (files[fileIdx].productLineCode === null && getFileProductSelectOptions(fileIdx).length === 1) {
            // we only have one option for product line type so let's default to that
            files[fileIdx].productLineCode = getFileProductSelectOptions(fileIdx)[0];
        }
        onFilesChange([...files]);
    };

    const handleYearChange = (year: number, fileIdx: number) => {
        if (!files || !onFilesChange) return;
        files[fileIdx].fileYear = year;
        onFilesChange([...files]);
    };

    const handleProductChange = (productLineCode: string, fileIdx: number) => {
        if (!files || !onFilesChange) return;
        files[fileIdx].productLineCode = productLineCode;
        onFilesChange([...files]);
    };

    const getFileTypeSelectSize = (idx: number) => {
        const ret = 6;
        let div = 1;
        div += showYearSelect(idx) ? 1 : 0;
        div += showProductSelect(idx) ? 1 : 0;
        return fileTypeOptions ? ret / div : 6;
    };

    const getFileTypeSelectValue = (idx: number) => {
        if (!files) return '';
        return files[idx].fileType?.code;
    };

    const getFileYearSelectValue = (idx: number) => {
        if (!files) return '';
        return files[idx].fileYear || '';
    };

    const getFileProductSelectValue = (idx: number) => {
        if (!files) return '';
        return files[idx].productLineCode || '';
    };

    const getFileProductSelectOptions = (idx: number) => {
        if (!fileTypeNeedsProduct || !files) return [];
        const fileType = files[idx].fileType || {};
        if (fileType.code in fileTypeNeedsProduct) {
            return fileTypeNeedsProduct[fileType.code].productsAvailable;
        }
        return [];
    };

    const showYearSelect = (fileIdx: number) => {
        if (!fileTypeNeedsYear || !files) return false;
        const fileType = files[fileIdx].fileType || {};
        return Object.keys(fileTypeNeedsYear).includes(fileType.code);
    };

    const showProductSelect = (fileIdx: number) => {
        if (!fileTypeNeedsProduct || !files) return false;
        const fileType = files[fileIdx].fileType || {};
        return Object.keys(fileTypeNeedsProduct).includes(fileType.code);
    };

    const getYearSelectTooltip = (fileIdx: number) => {
        if (!fileTypeNeedsYear || !files) return '';
        const fileType = files[fileIdx].fileType || {};
        if (fileType.code in fileTypeNeedsYear) {
            return fileTypeNeedsYear[fileType.code];
        }
        return '';
    };

    const getProductSelectTooltip = (fileIdx: number) => {
        if (!fileTypeNeedsProduct || !files) return '';
        const fileType = files[fileIdx].fileType || {};
        if (fileType.code in fileTypeNeedsProduct) {
            return fileTypeNeedsProduct[fileType.code].toolTip;
        }
        return '';
    };

    const alignItems = direction === 'column' ? 'flex-start' : 'center';
    const itemSize = fileTypeOptions ? 6 : 4;

    if (!files || !files.length) return <Spacing height="16px" />;

    function handleFileYearSelectTooltipOpen(open: boolean, idx: number) {
        fileYearSelectTooltipOpen[idx] = open;
        setFileYearSelectTooltipOpen({ ...fileYearSelectTooltipOpen });
    }

    function handleFileProductSelectTooltipOpen(open: boolean, idx: number) {
        fileProductSelectTooltipOpen[idx] = open;
        setFileProductSelectTooltipOpen({ ...fileProductSelectTooltipOpen });
    }

    return (
        <Grid container direction={direction || 'row'} spacing={1} alignItems={alignItems}>
            {files.map((fileItem, idx) => {
                const isIdDisabled = disableIdFiles && !!fileItem.uuid;
                return (
                    <React.Fragment key={idx}>
                        <Grid sm={itemSize} xs={12}>
                            <FileItem
                                editable={editable && !isIdDisabled}
                                removable={removable && !isIdDisabled}
                                name={fileItem.name || fileItem.fileName}
                                label={fileItem.title}
                                description={fileItem.description}
                                onRemoveClick={() => handleRemoveFile(idx)}
                                onFileChange={(newTitle, newDescription) =>
                                    handleChangeFile(newTitle, newDescription, idx)
                                }
                                uuid={clickable ? fileItem.uuid : undefined}
                                blob={fileItem instanceof Blob && clickable ? fileItem : undefined}
                            />
                        </Grid>
                        {fileTypeOptions && (
                            <Grid sm={getFileTypeSelectSize(idx)} xs={12}>
                                <Select
                                    placeholder="Select Document Type"
                                    readOnly={!editable || isIdDisabled}
                                    onChange={(event: any) => handleFileTypeChange(event.target.value, idx)}
                                    value={getFileTypeSelectValue(idx)}
                                >
                                    <MenuItem key="menu-item-default" disabled value="">
                                        Select Document Type
                                    </MenuItem>
                                    {fileTypeOptions &&
                                        fileTypeOptions.map((fileOpt: DocumentType, menuIdx: number) => (
                                            <MenuItem key={`menu-item-${menuIdx}`} value={fileOpt.code}>
                                                {fileOpt.displayValue}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                        )}
                        {fileTypeOptions && showProductSelect(idx) && (
                            <>
                                <Grid sm={getFileTypeSelectSize(idx)} xs={12}>
                                    {/*control the tooltip here to make sure it doesn't stay open over menuitems*/}
                                    <Tooltip
                                        title={getProductSelectTooltip(idx)}
                                        open={!!fileProductSelectTooltipOpen[idx]}
                                    >
                                        <div
                                            onMouseEnter={() => handleFileProductSelectTooltipOpen(true, idx)}
                                            onMouseLeave={() => handleFileProductSelectTooltipOpen(false, idx)}
                                        >
                                            <Select
                                                placeholder="Select Product Line"
                                                readOnly={!editable || isIdDisabled}
                                                onChange={(event: any) => handleProductChange(event.target.value, idx)}
                                                value={getFileProductSelectValue(idx)}
                                                onOpen={() => {
                                                    handleFileProductSelectTooltipOpen(false, idx);
                                                }}
                                            >
                                                <MenuItem key="menu-item-default" disabled value="">
                                                    Select Product
                                                </MenuItem>
                                                {getFileProductSelectOptions(idx).map((productCode: string) => (
                                                    <MenuItem key={`menu-item-${productCode}`} value={productCode}>
                                                        {productLineTypes[productCode].displayValue}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    </Tooltip>
                                </Grid>
                            </>
                        )}
                        {fileTypeOptions && showYearSelect(idx) && (
                            <>
                                <Grid sm={getFileTypeSelectSize(idx)} xs={12}>
                                    {/*control the tooltip here to make sure it doesn't stay open over menuitems*/}
                                    <Tooltip title={getYearSelectTooltip(idx)} open={!!fileYearSelectTooltipOpen[idx]}>
                                        <div
                                            onMouseEnter={() => handleFileYearSelectTooltipOpen(true, idx)}
                                            onMouseLeave={() => handleFileYearSelectTooltipOpen(false, idx)}
                                        >
                                            <Select
                                                placeholder="Select Document Year"
                                                readOnly={!editable || isIdDisabled}
                                                onChange={(event: any) => handleYearChange(event.target.value, idx)}
                                                value={getFileYearSelectValue(idx)}
                                                onOpen={() => {
                                                    handleFileYearSelectTooltipOpen(false, idx);
                                                }}
                                            >
                                                <MenuItem key="menu-item-default" disabled value="">
                                                    Select Year
                                                </MenuItem>
                                                {yearOptions.map((n: number) => (
                                                    <MenuItem key={`menu-item-${n}`} value={n}>
                                                        {n}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                    </Tooltip>
                                </Grid>
                            </>
                        )}
                    </React.Fragment>
                );
            })}
        </Grid>
    );
};

FileItemList.defaultProps = defaultProps;
export default React.memo(FileItemList);
