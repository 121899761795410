import { FONTS, COLORS } from 'constants/index';
import { TypographyOptions } from '@mui/material/styles/createTypography';

const typography: TypographyOptions = {
    fontFamily: FONTS,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontSize: 14,
    h1: {
        lineHeight: '41px',
        fontSize: '34px',
        fontWeight: 700,
        color: COLORS.SECONDARY,
    },
    h2: {
        lineHeight: '28px',
        fontSize: '22px',
        fontWeight: 700,
        color: COLORS.SECONDARY,
    },
    h3: {
        lineHeight: '22px',
        fontSize: '17px',
        fontWeight: 700,
        color: COLORS.SECONDARY,
    },
    h4: {
        lineHeight: '20px',
        fontSize: '15px',
        fontWeight: 700,
        color: COLORS.SECONDARY,
    },
    h5: {
        lineHeight: '18px',
        fontSize: '14px',
        fontWeight: 700,
        color: COLORS.SECONDARY,
    },
    button: {
        lineHeight: '22px',
        fontSize: '17px',
        fontWeight: 700,
        // ts-ignore
        textTransform: 'uppercase',
        color: COLORS.SECONDARY,
    },
    // styleguid = caption 1
    subtitle1: {
        lineHeight: '16px',
        fontSize: '12px',
        fontWeight: 700,
        color: COLORS.SECONDARY,
    },
    // styleguid = caption 2
    subtitle2: {
        lineHeight: '13px',
        fontSize: '11px',
        fontWeight: 700,
        color: COLORS.SECONDARY,
    },
    body1: {
        lineHeight: '24px',
        fontSize: '16px',
        fontWeight: 400,
        color: COLORS.SECONDARY,
    },
    body2: {
        lineHeight: '18px',
        fontSize: '13px',
        fontWeight: 400,
        color: COLORS.SECONDARY,
    },
    // styleguid = small text
    caption: {
        lineHeight: '13px',
        fontSize: '11px',
        fontWeight: 400,
        color: COLORS.SECONDARY_LIGHT,
    },
};

export default typography;
