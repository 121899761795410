import * as React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import { Dialog, DialogTitle } from '@mui/material';

import { FileViewerProps, getFileSrc, revokeFileSrc } from '../../helpers/files';
import ReactPDF from './components/ReactPDF';
import PDFjsExpress from './components/PDFjsExpress';

const ViewPDF: FunctionComponent<FileViewerProps> = (props) => {
    const { isOpen, onClose, fileName, fileUrl, fileId, initialPageNumber, fileTitle, blob } = { ...props };

    const [effectiveFileURL, setEffectiveFileURL] = useState<string>('');

    const getFileUrl = async () => {
        const url = await getFileSrc(fileName, fileId, fileUrl, blob);
        setEffectiveFileURL(url);
    };

    useEffect(() => {
        if (isOpen) {
            getFileUrl();
        }
        return () => {
            revokeFileSrc(effectiveFileURL);
        };
    }, [isOpen, fileName, fileId, fileUrl, blob]);

    if (!isOpen || !effectiveFileURL) {
        return <></>;
    }

    return (
        <Dialog
            maxWidth={'lg'}
            fullWidth={true}
            open={isOpen}
            onClose={onClose}
            PaperProps={{ style: { height: 'calc(100% - 64px)' } }}
        >
            <DialogTitle>{fileTitle}</DialogTitle>
            <PDFjsExpress
                fileUrl={effectiveFileURL}
                fileName={fileName}
                blob={blob}
                initialPageNumber={initialPageNumber}
            />
        </Dialog>
    );
};

export default ViewPDF;
