import { getHeaders, SubmissionURL } from './config';
import { checkStatus, getAll, getData } from './normalizer';
import { MessagingChannelMessage } from 'types/messaging.type';

export async function getSubmissionMessages(id: string): Promise<MessagingChannelMessage[]> {
    return fetch(`${SubmissionURL}/messaging/submissionChannel/${id}`, {
        method: 'GET',
        headers: getHeaders(),
    })
        .then(checkStatus)
        .then(getAll);
}

export async function createSubmissionMessage(id: string, payload: MessagingChannelMessage) {
    return fetch(`${SubmissionURL}/messaging/submissionChannel/${id}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    }).then(checkStatus);
}

export async function markSubmissionMessagesRead(id: string) {
    return fetch(`${SubmissionURL}/messaging/submissionChannel/${id}?isAllRead=true`, {
        method: 'PATCH',
        headers: getHeaders(),
    }).then(checkStatus);
}
