import * as React from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { Accordion, AccordionDetails, AccordionSummary, MenuItem, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import {
    getDocumentTypesByAction,
    getPerilTypesList,
    getProductLineTypesList,
    getSovLocations,
    getSubmissionDetails,
} from 'application/redux/selectors';
import LrButton from 'components/LrButton';
import { Margin } from 'components/Layout';
import { createLosses } from 'services/submission';
import { ACTION_TYPES, COLORS } from 'constants/index';
import { currencyToNumber } from 'helpers/formatters';
import { Loss } from 'types/losses.type';

import lossSchema from './ValidationSchema';
import { LrFileUpload, LrFileUploadAPI } from '../../../LrFileItems';
import { useRef } from 'react';
import { SubmissionDetails } from 'types/submission.type';
import { Select, TextInput } from '../../../Input';
import { currencyMask, dateMask } from '../../../../helpers/masks';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AmType, PerilType } from '../../../../types/types.type';

interface LossFormProps {
    onCancel?: () => void;
    onSubmit: () => void;
    allowCancel?: boolean;
    submitText?: string;
    currentLoss?: Loss;
    useCurrentLoss?: boolean;
    showReason?: boolean;
    isClaim?: boolean;
    productLineCode?: string;
}

export interface LossFormData {
    locationId: number;
    date: string;
    description: string;
    perilType: string;
    status: string;
    paid: string;
    reserved: string;
    totalLoss: string;
    note: string;
    isClaim: boolean;
    claimNumber: string;
    productLineCode: string;
}

const CLOSED: string = '1';
const OPEN: string = '0';

const initialState: (isClaim: boolean, productLineCode?: string) => LossFormData = (isClaim, productLineCode) => {
    return {
        locationId: 0,
        date: '',
        description: '',
        perilType: '',
        status: '',
        paid: '',
        reserved: '',
        totalLoss: '',
        note: '',
        isClaim: isClaim,
        claimNumber: '',
        productLineCode: productLineCode || 'COMMERCIAL_PROPERTY',
    };
};

const LossForm: React.FunctionComponent<LossFormProps> = (props) => {
    const {
        onCancel,
        onSubmit,
        submitText,
        allowCancel,
        currentLoss,
        useCurrentLoss,
        showReason,
        isClaim,
        productLineCode,
    } = props;

    // Global State
    const locations = useSelector(getSovLocations);
    const perilTypes = useSelector(getPerilTypesList) as PerilType[];
    const productTypes = useSelector(getProductLineTypesList) as AmType[];
    const currentActionType = ACTION_TYPES.SUBMISSION_LOSSES;
    const fileTypeOptions = useSelector(getDocumentTypesByAction(currentActionType));
    const submission = useSelector(getSubmissionDetails) || ({} as SubmissionDetails);

    // Local State
    const [isLoading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const fileUploadAPI = useRef<LrFileUploadAPI>(null);

    const lossToFormState = (loss: Loss) => {
        const state = {} as LossFormData;
        state.locationId = Number(loss.locationId);
        state.date = loss.dateOfLoss;
        state.description = loss.narrative;
        state.perilType = loss.perilType.code;
        state.status = loss.isClosed ? '1' : '0';
        state.paid = `${loss.paid || 0}`;
        state.reserved = `${loss.reserved || 0}`;
        state.isClaim = !!loss.isClaim;
        state.totalLoss = '0';
        state.note = '';
        state.claimNumber = '';
        state.productLineCode = loss.productLineCode;
        return state;
    };

    const checkValid = ({ initialValues }: any) => {
        return lossSchema(!!showReason, !!isClaim).isValidSync(initialValues);
    };

    const handleFormSubmit = async (values: any, { resetForm }: { resetForm: any }) => {
        setLoading(true);
        try {
            const docs = await fileUploadAPI?.current?.doSubmit();
            await createLosses(submission.id, {
                propertyLosses: [
                    {
                        ...values,
                        paid: currencyToNumber(values.paid),
                        reserved: values.reserved ? currencyToNumber(values.reserved) : 0,
                        id: currentLoss && currentLoss.id,
                        documents: docs || [],
                    },
                ],
            });
            setLoading(false);
            fileUploadAPI?.current?.clearFiles();
            resetForm(initialState);
            setError(false);
            onSubmit();
        } catch (e) {
            setError(true);
            setLoading(false);
        }
    };

    return (
        <>
            <Formik
                initialValues={
                    useCurrentLoss && currentLoss
                        ? lossToFormState(currentLoss)
                        : initialState(!!isClaim, productLineCode)
                }
                isInitialValid={checkValid}
                onSubmit={handleFormSubmit}
                validationSchema={lossSchema(!!showReason, !!isClaim)}
                enableReinitialize
            >
                {({ errors, handleBlur, handleChange, touched, values, isValid }) => (
                    <Form>
                        <Grid container spacing={3}>
                            <Grid lg={6}>
                                <Select
                                    value={values.locationId}
                                    name="locationId"
                                    title="Location Name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    errorMessage={touched.locationId && errors.locationId}
                                >
                                    {!values.status && (
                                        <MenuItem disabled value="">
                                            Select Location
                                        </MenuItem>
                                    )}
                                    {locations.map((loc) => (
                                        <MenuItem key={loc.id} value={loc.id}>
                                            {loc.locationName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid lg={6}>
                                <TextInput
                                    value={values.date}
                                    name="date"
                                    title="Date of Loss"
                                    placeholder="MM/DD/YYYY"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    errorMessage={touched.date && errors.date}
                                    mask={dateMask}
                                />
                            </Grid>
                            <Grid lg={12}>
                                <TextInput
                                    value={values.description}
                                    name="description"
                                    title="Loss Description"
                                    placeholder="Enter Description"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    errorMessage={touched.description && errors.description}
                                />
                            </Grid>
                            <Grid lg={6}>
                                <Select
                                    value={values.perilType}
                                    name="perilType"
                                    title={
                                        values.productLineCode === 'GENERAL_LIABILITY' ? 'Incident Type' : 'Peril Type'
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={!values.locationId}
                                    errorMessage={touched.perilType && errors.perilType}
                                >
                                    {!values.perilType && (
                                        <MenuItem disabled value="">
                                            Select Type
                                        </MenuItem>
                                    )}
                                    {perilTypes
                                        .filter((type) => type.productLineCode === values.productLineCode)
                                        .map((type) => (
                                            <MenuItem key={type.id} value={type.code.toString()}>
                                                {type.displayValue}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                            <Grid lg={6}>
                                <Select
                                    value={values.productLineCode}
                                    name="productLineCode"
                                    title="Product"
                                    readOnly={!!productLineCode}
                                    disabled={!values.locationId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    errorMessage={touched.productLineCode && errors.productLineCode}
                                >
                                    {!values.productLineCode && (
                                        <MenuItem disabled value="">
                                            Select Product
                                        </MenuItem>
                                    )}
                                    {productTypes
                                        .filter((type) => {
                                            if (!isClaim) return true;
                                            const loc = locations.find((l) => l.id === values.locationId);
                                            if (!!loc && loc.policyLocations) {
                                                return loc.policyLocations.some(
                                                    (pl) => pl.productLineCode === type.code
                                                );
                                            }
                                            return false;
                                        })
                                        .map((type) => (
                                            <MenuItem key={'ProductType' + type.id} value={type.code}>
                                                {type.displayValue}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                            {!values.isClaim && (
                                <>
                                    <Grid lg={6}>
                                        <Select
                                            value={values.status}
                                            name="status"
                                            title="Open/Closed"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMessage={touched.status && errors.status}
                                        >
                                            {!values.status && (
                                                <MenuItem disabled value="">
                                                    Select Status
                                                </MenuItem>
                                            )}
                                            <MenuItem key={'open'} value={OPEN}>
                                                Open
                                            </MenuItem>
                                            <MenuItem key={'closed'} value={CLOSED}>
                                                Closed
                                            </MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid xs={4}>
                                        <TextInput
                                            value={values.paid}
                                            name="paid"
                                            title="Paid"
                                            placeholder="0.00"
                                            onChange={handleChange}
                                            readOnly={values.isClaim}
                                            onBlur={handleBlur}
                                            errorMessage={touched.paid && errors.paid}
                                            mask={currencyMask}
                                        />
                                    </Grid>
                                    <Grid xs={4}>
                                        <TextInput
                                            // Disable and set to 0 if closed
                                            value={values.status === CLOSED ? '0' : values.reserved}
                                            disabled={values.status === CLOSED}
                                            name="reserved"
                                            title="Reserved"
                                            placeholder="0.00"
                                            readOnly={values.isClaim}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errorMessage={touched.reserved && errors.reserved}
                                            mask={currencyMask}
                                        />
                                    </Grid>
                                    <Grid xs={4}>
                                        <TextInput
                                            value={
                                                parseFloat(currencyToNumber(values.paid || '0')) +
                                                parseFloat(
                                                    currencyToNumber(
                                                        values.status === CLOSED ? '0' : values.reserved || '0'
                                                    )
                                                )
                                            }
                                            name="totalLoss"
                                            title="Total Incurred"
                                            placeholder="0.00"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            mask={currencyMask}
                                            disabled
                                        />
                                    </Grid>
                                </>
                            )}
                            {values.isClaim && (
                                <Grid lg={6}>
                                    <TextInput
                                        value={values.claimNumber}
                                        name="claimNumber"
                                        title="Claim Number"
                                        placeholder="Enter Claim Number"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        errorMessage={touched.claimNumber && errors.claimNumber}
                                    />
                                </Grid>
                            )}
                            <Grid xs={12}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} id={'addDocuments header'}>
                                        <Typography>Add Documents</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <LrFileUpload
                                            fileTypeOptions={fileTypeOptions}
                                            fileTypeNeedsYear={{}}
                                            ref={fileUploadAPI}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            {showReason && (
                                <Grid xs={12}>
                                    <TextInput
                                        id={'Loss change Reason'}
                                        name={'note'}
                                        title={'Reason'}
                                        value={values.note}
                                        errorMessage={touched.note && errors.note}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={'Please indicate the reason for this action'}
                                        multiline
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <Margin top="24" />
                        <Grid container spacing={2} alignItems="center">
                            {(allowCancel || useCurrentLoss) && (
                                <Grid>
                                    <LrButton lrType="text" onClick={onCancel}>
                                        Cancel
                                    </LrButton>
                                </Grid>
                            )}
                            <Grid>
                                <LrButton disabled={!isValid} type="submit" lrType="primary" isLoading={isLoading}>
                                    {useCurrentLoss ? 'Save' : submitText || 'Submit'}
                                </LrButton>
                            </Grid>
                            <Grid>
                                {error && (
                                    <Typography variant="subtitle1" style={{ color: COLORS.ERROR }}>
                                        Something went wrong, please try again
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default React.memo(LossForm);
