import { ActiveUserProducer } from 'types/types.type';
import { Organization } from 'types/organization.type';
import { getOrganizationTreeByGrantors } from 'services/organizations';
import { Link } from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react';

export class BaseActiveProducer implements ActiveUserProducer {
    protected activeProducer: Organization;
    protected availableProducers: Organization[] = [];

    constructor(activeProducer: Organization) {
        this.activeProducer = activeProducer;
    }

    async fetchAvailableAgents() {
        // TODO modify this to support users assigned to mulitple Organazational Units

        if (this.activeProducer && this.activeProducer.id) {
            this.availableProducers = await getOrganizationTreeByGrantors(this.activeProducer.id).then((ret) =>
                ret.filter((a) => a.isLicensedAgent || a.organizationType === 'AGENCY')
            );
            if (this.getIsAgent() && !this.availableProducers.find((a) => a.id === this.activeProducer.id)) {
                this.availableProducers.push(this.activeProducer);
            }
        }
    }

    public getIsActiveProducerAssigned(assignedProducer: Organization): boolean {
        if (!assignedProducer) {
            return false;
        }
        return assignedProducer.id === this.activeProducer.id;
    }

    public getEffectiveActiveProducer(assignedProducer: Organization): Organization | undefined {
        // here we are determining if we have an effective assigned producer, meaning either an actual assigned producer, or the logged in user if they are an agent
        if (assignedProducer && assignedProducer.id) {
            return assignedProducer;
        }
        return this.getIsAgent() ? this.activeProducer : undefined;
    }

    public getIsAgent(): boolean {
        return false;
    }

    getSubHeader(assignedProducer: Organization, doSelectProducer: () => void): ReactElement | string {
        if (assignedProducer) {
            return (
                <>
                    Producer Context:{' '}
                    <Link
                        href="#"
                        onClick={() => {
                            doSelectProducer();
                        }}
                        underline="hover"
                    >
                        {assignedProducer?.name || ''}
                    </Link>
                </>
            );
        }
        return '';
    }

    public getAvailableProducers(): Organization[] {
        return [...this.availableProducers];
    }

    public getProducer(): Organization {
        return this.activeProducer;
    }

    public getId(): string | undefined {
        return this.activeProducer.id;
    }

    validateAssignedProducer(assignedProducer: Organization): Organization | undefined {
        return undefined;
    }

    getTemplateQualifier(fileType: string, availableQualifiers: string[] | undefined): string {
        return '';
    }
}
