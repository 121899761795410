import * as React from 'react';
import { FunctionComponent, useEffect, useRef, useState, WheelEvent } from 'react';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import styles from './styles';
import Viewer from 'react-viewer';
import { Alert } from '@mui/lab';
import { FileViewerProps, getFileSrc, revokeFileSrc } from '../../helpers/files';
import { withStyles, WithStyles } from '@mui/styles';

const ViewIMG: FunctionComponent<FileViewerProps & WithStyles<typeof styles>> = (props) => {
    const { isOpen, onClose, fileName, fileId, fileTitle, fileUrl, classes, blob } = { ...props };

    const clickAnchor = React.useRef({} as HTMLAnchorElement);
    const [IMGSrc, setIMGSrc] = useState<string>('');
    const [haveRef, setHaveRef] = useState<boolean>(false);
    const [zoom, setZoom] = useState<number>(0.15);
    const [errorString, setErrorString] = useState<string>('');
    const container = useRef<HTMLDivElement | null>(null);

    const renderLoading = () => <Typography>Please Wait...</Typography>;
    const getImageBlob = async () => {
        try {
            const url = await getFileSrc(fileName, fileId, fileUrl, blob);
            setIMGSrc(url);
        } catch (e) {
            setErrorString('Failed to Load IMG! ' + (e.status === 403 ? 'Unauthorized for current user!' : ''));
        }
    };
    useEffect(() => {
        if (isOpen) {
            getImageBlob();
        }
        return () => {
            revokeFileSrc(IMGSrc);
        };
    }, [fileName, fileId, blob, fileUrl, isOpen]);

    if (!isOpen || !IMGSrc) {
        return <></>;
    }

    return (
        <>
            <Dialog
                maxWidth={'lg'}
                fullWidth={true}
                open={isOpen}
                onClose={onClose}
                PaperProps={{ style: { height: 'calc(100% - 64px)' } }}
            >
                <DialogTitle>{fileTitle}</DialogTitle>
                <div className={classes.contentWrapper}>
                    <DialogContent>
                        <Grid container justifyContent={'center'} style={{ height: '100%' }}>
                            <Grid
                                ref={(c) => {
                                    container.current = c;
                                    setHaveRef(!!c);
                                }}
                                style={{ width: '95%' }}
                            >
                                {errorString && (
                                    <Alert variant={'outlined'}>
                                        <Typography>{errorString}</Typography>
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </DialogContent>
                </div>
            </Dialog>
            {IMGSrc && (
                <Viewer
                    images={[{ src: IMGSrc }]}
                    visible={haveRef && isOpen}
                    container={container.current || undefined}
                    onClose={onClose}
                    zoomable
                    rotatable
                    downloadable
                    noNavbar
                    zoomSpeed={zoom}
                    noClose
                    customToolbar={(toolbars) => {
                        return toolbars
                            .filter((tb) => !['next', 'prev'].includes(tb.key))
                            .map((tb) => {
                                if (tb.key === 'download') {
                                    return {
                                        ...tb,
                                        onClick: (activeImage) => {
                                            clickAnchor.current.href = activeImage.src;
                                            clickAnchor.current.download = fileName;
                                            clickAnchor.current.click();
                                        },
                                    };
                                } else return tb;
                            });
                    }}
                />
            )}
            <a ref={clickAnchor} />
        </>
    );
};

export default withStyles(styles)(ViewIMG);
