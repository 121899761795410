import { getHeaders, getQueryString, SubmissionURL } from './config';
import { checkStatus, getAll, getData, sortByCreatedAt } from './normalizer';
import { LogFetchOptions, LogCreateOptions } from 'types/logs.types';

export async function fetchLogs(opts: LogFetchOptions) {
    const qs = getQueryString(opts);
    const url = `${SubmissionURL}/logs${qs}`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus).then(getAll).then(sortByCreatedAt);
}

export async function fetchLogsCount(opts: LogFetchOptions) {
    const qs = getQueryString(opts);
    const url = `${SubmissionURL}/logs/count${qs}`;
    const options = {
        method: 'GET',
        headers: getHeaders(),
    };
    return fetch(url, options).then(checkStatus).then(getData);
}

export async function createLog(opts: LogCreateOptions) {
    const url = `${SubmissionURL}/logs`;
    const options = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(opts),
    };
    return fetch(url, options).then(checkStatus).then(getData);
}
