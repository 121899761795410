import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import HTTPError from 'helpers/error';
import { ERRORS } from '../../constants';

interface ErrorDialogProps {
    errorTitle?: string;
    errorMsg?: string;
    error?: any;
    onClose?: () => void;
}

interface ErrorDialogState {
    dialogOpen: boolean;
}

export default class ErrorDialog extends React.Component<ErrorDialogProps, ErrorDialogState> {
    state: ErrorDialogState = {
        dialogOpen: true,
    };

    public render() {
        const { errorTitle, errorMsg, error, onClose } = this.props;
        let title = errorTitle || ERRORS.API_ERROR_MSG_TITLE;
        let msg = errorMsg || ERRORS.API_ERROR_MSG_BODY;
        if (error && error.statusCode) {
            switch (error.statusCode) {
                case 403:
                    title = ERRORS.FORBIDDEN_API_ERROR_MSG_TITLE;
                    msg = ERRORS.FORBIDDEN_API_ERROR_MSG_BODY;
                    break;
            }
        }

        return (
            <Dialog
                fullWidth={true}
                PaperProps={{ style: { backgroundColor: 'white' } }}
                open={this.state.dialogOpen}
                onClose={this._closeDialog}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{msg}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this._closeDialog} color={'primary'}>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    _closeDialog = () => {
        this.setState({ dialogOpen: false });
        this.props.onClose && this.props.onClose();
    };
}
