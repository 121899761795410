import * as React from 'react';
import { useSelector } from 'react-redux';
import { GetContextMenuItems, RowClassParams, RowClickedEvent } from 'ag-grid-community';

import { getSubmissionDetails } from 'application/redux/selectors';
import LrGrid from 'components/LrGrid';
import ErrorDialog from 'components/ErrorDialog';
import { ERRORS } from 'constants/index';
import { CircularLoader } from 'components/Loader';
import { fetchLosses as fetchAllLosses } from 'services/losses';
import { Claim, Loss } from 'types/losses.type';

import { columnDefs, detailColumnDefs } from './GridDefs';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components';
import { LrLabeledFileItemList } from '../../../LrFileItems';
import { DefaultTheme, ThemeProvider as MuiThemeProvider, WithTheme, withTheme } from '@mui/styles';
import { currencyToNumber } from '../../../../helpers/formatters';
import ClaimDataDialog from './components/ClaimDataDialog';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';

interface Props {
    statusBar?: any;
    needsRefresh?: boolean;
    needsRowsCleared?: boolean;
    height?: string;
    submissionId: string;
    onLossesRefreshed: () => void;
    onRowDelete?: (arg0: Loss) => void;
    onRowEdit?: (arg0: Loss) => void;
    onRowClicked?: (arg0: Loss) => void;
    onRowsCleared?: () => void;
    setLossesParent?: (arg0: Loss[], arg1?: string) => void;
    productLineCode?: string;
}

const LossGridWrapper = styled.div`
    .ag-row-hover {
        cursor: pointer;
    }
`;

const Losses: React.FunctionComponent<Props & WithTheme> = (props) => {
    const {
        statusBar,
        needsRefresh,
        onLossesRefreshed,
        onRowClicked,
        onRowDelete,
        onRowEdit,
        needsRowsCleared,
        onRowsCleared,
        height,
        setLossesParent,
        theme,
        productLineCode,
    } = props;

    // Global State
    const submission = useSelector(getSubmissionDetails);

    // Local State
    const [isLoading, setIsLoading] = React.useState(false);
    const [losses, setLosses] = React.useState([] as Loss[]);
    const [error, setError] = React.useState(false);
    const [gridApi, setApi] = React.useState({} as any);
    const [viewClaimData, setViewClaimData] = React.useState<Claim | undefined>(undefined);

    const setPropertyLosses = (propLosses: Loss[]) => {
        setLossesParent && setLossesParent(propLosses, props.productLineCode);
        setLosses(propLosses);
    };

    const fetchLosses = async () => {
        try {
            setIsLoading(true);
            const response = await fetchAllLosses(props.submissionId, props.productLineCode);
            setPropertyLosses(response);

            onLossesRefreshed && onLossesRefreshed();
        } catch (e) {
            setError(true);
        }
        setIsLoading(false);
    };

    const refresh = () => {
        !isLoading && needsRefresh && fetchLosses();
    };
    const reload = () => {
        !isLoading && !needsRefresh && fetchLosses();
    };

    React.useEffect(refresh, [needsRefresh]);
    React.useEffect(reload, []);

    const handleRowClicked = (event: RowClickedEvent) => {
        const selectedRows = event.api.getSelectedRows();
        if (!selectedRows.length || !selectedRows.find(({ id }) => id === event.node.data.id)) return;

        const { node } = event;
        const { id: selectedId } = node.data;

        const selectedLoss = losses.find(({ id }) => id === selectedId) || ({} as Loss);
        onRowClicked && onRowClicked(selectedLoss);
    };

    const onRowViewClaimData = (lossData: Loss) => {
        setViewClaimData(lossData.claim);
    };

    const getContextMenuItems: GetContextMenuItems = (params) => {
        return [
            onRowDelete && params.node && !params.node?.rowPinned
                ? {
                      name: 'Delete Loss',
                      icon: renderToString(
                          <DeleteIcon style={{ marginLeft: '-3px' }} htmlColor={theme.palette.primary.main} /> // negative margin helps the mui-icon align with built-in
                      ),
                      action: () => {
                          onRowDelete(params.node?.data);
                      },
                  }
                : '',
            onRowEdit && params.node && !params.node?.data?.isClaim && !params.node?.rowPinned
                ? {
                      name: 'Edit Loss',
                      icon: renderToString(
                          <EditIcon style={{ marginLeft: '-3px' }} htmlColor={theme.palette.primary.main} /> // negative margin helps the mui-icon align with built-in
                      ),
                      action: () => {
                          onRowEdit(params.node?.data);
                      },
                  }
                : '',
            onRowEdit && params.node?.data?.isClaim && !params.node?.rowPinned
                ? {
                      name: 'View Claim Data',
                      icon: renderToString(
                          <AssignmentLateIcon style={{ marginLeft: '-3px' }} htmlColor={theme.palette.primary.main} /> // negative margin helps the mui-icon align with built-in
                      ),
                      action: () => {
                          onRowViewClaimData(params.node?.data);
                      },
                  }
                : '',
            'copy',
            'copyWithHeaders',
            'paste',
            'separator',
            'export',
        ];
    };

    const setGridApi = (api: any) => {
        setApi(api);
    };

    const clearRows = () => {
        if (!needsRowsCleared) return;
        gridApi.deselectAll();
        onRowsCleared && onRowsCleared();
    };
    React.useEffect(clearRows, [needsRowsCleared]);

    if (error) {
        return <ErrorDialog errorTitle={ERRORS.API_ERROR_MSG_TITLE} errorMsg={ERRORS.API_ERROR_MSG_BODY} />;
    }

    const isRowMaster = (params: any) => {
        return !!params.documents?.length;
    };

    const fileItemListCellRenderer = (params: any) => {
        return (
            <MuiThemeProvider theme={theme}>
                <Grid container>
                    <Grid xs={12} style={{ paddingLeft: '50px', marginTop: '5px' }}>
                        <Typography>Documents</Typography>
                    </Grid>
                    <Grid container style={{ height: '150px', overflowY: 'auto' }}>
                        <Grid xs={1} />
                        <Grid xs={6}>
                            <LrLabeledFileItemList labeledDocs={[{ documents: params.data.documents }]} />
                        </Grid>
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        );
    };

    if (!submission || isLoading) return <CircularLoader />;

    return (
        <>
            <LossGridWrapper>
                <LrGrid
                    title="Loss Summary"
                    columnDefs={columnDefs}
                    autosize
                    rowSelection="single"
                    rowData={losses}
                    pinnedBottomRowData={[
                        {
                            totalLoss: losses.reduce(
                                (agg, cur) =>
                                    agg +
                                    (cur.isLocationDeleted
                                        ? 0
                                        : parseFloat(currencyToNumber(cur.totalLoss?.toString() || '0'))),
                                0
                            ),
                        },
                    ]}
                    onRowSelected={handleRowClicked}
                    setGridApi={setGridApi}
                    height={height}
                    getContextMenuItems={getContextMenuItems}
                    frameworkComponents={statusBar ? { statusBar: statusBar } : undefined}
                    masterDetail={true}
                    detailRowHeight={200}
                    isRowMaster={isRowMaster}
                    detailCellRendererFramework={fileItemListCellRenderer}
                    detailCellRendererParams={{
                        detailGridOptions: {
                            columnDefs: detailColumnDefs,
                            defaultColDef: {
                                flex: 1,
                            },
                        },
                        getDetailRowData: (params: any) => {
                            params.successCallback(params.data.documents);
                        },
                    }}
                    statusBar={
                        statusBar
                            ? {
                                  statusPanels: [
                                      {
                                          statusPanel: 'statusBar',
                                          align: 'left',
                                          statusPanelParams: {},
                                      },
                                  ],
                              }
                            : undefined
                    }
                    gridOptions={{
                        getRowClass: (params: RowClassParams) => {
                            return params.data.isLocationDeleted ? 'ag-row-disabled' : '';
                        },
                    }}
                />
            </LossGridWrapper>
            <ClaimDataDialog
                claimData={viewClaimData}
                isOpen={!!viewClaimData}
                onSubmit={() => setViewClaimData(undefined)}
                onCancel={() => setViewClaimData(undefined)}
                readonly={true}
            />
        </>
    );
};

export default React.memo(withTheme<DefaultTheme, typeof Losses>(Losses));
