import { Dispatch } from 'redux';

import { REDUX, STATUS_TYPES } from 'constants/index';
import actionCreator from '../lib/actionCreator';
import { fetchQuotes, fetchQuotesForReview, fetchQuotesForBindReview } from 'services/quotes';
import { SubmissionDetails } from 'types/submission.type';

const quotesAction = actionCreator(REDUX.QUOTES);

export const fetchAllQuotes = (submissionId: string) => async (dispatch: Dispatch) => {
    dispatch(quotesAction.loading());
    fetchQuotes(submissionId)
        .then((quotes) => dispatch(quotesAction.success(quotes)))
        .catch((reason) => dispatch(quotesAction.error(reason)));
};

export function fetchQuotesByStatus(submission: SubmissionDetails) {
    return async (dispatch: Dispatch) => {
        dispatch(quotesAction.loading());
        const fetchQuotesAction = getQuotesAction(submission);
        fetchQuotesAction
            .then((quotes) => dispatch(quotesAction.success(quotes)))
            .catch((reason) => dispatch(quotesAction.error(reason)));
    };
}

function getQuotesAction(submission: SubmissionDetails) {
    switch (submission.statusType.code) {
        case STATUS_TYPES.READY_TO_QUOTE:
        case STATUS_TYPES.REQUOTE_REQUESTED:
            return fetchQuotes(submission.id);
        case STATUS_TYPES.QUOTED:
            return fetchQuotesForReview(submission.id);
        case STATUS_TYPES.BIND_REQUEST_REVIEW:
            return fetchQuotesForBindReview(submission.id);
        default:
            return fetchQuotes(submission.id);
    }
}
