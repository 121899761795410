import * as React from 'react';
import { AutocompleteProps, TextField } from '@mui/material';
import { Autocomplete as MuiAutoComplete } from '@mui/lab';
import InputWrapper from './InputWrapper';
import { CircularLoader } from '../../Loader';
import { GridSpacing } from '@mui/material/Grid';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';

interface Props extends Partial<AutocompleteProps<any, any, any, any>> {
    errorMessage?: any;
    hasError?: boolean;
    fullHeight?: boolean;
    loading?: boolean;
    title?: string;
    value?: any;
    variant?: any;
    options: any[];
    emptyLabel?: string;
    freeType?: boolean;
    getOptionLabel?: (option: any) => any;
    inputRef?: React.Ref<any>;
    spacing?: GridSpacing;
}

const Autocomplete: React.FunctionComponent<Props> = (props) => {
    const {
        title,
        variant,
        errorMessage,
        hasError,
        emptyLabel,
        freeType,
        fullHeight,
        loading,
        options,
        getOptionLabel,
        inputRef,
        spacing,
        ...restOfProps
    } = props;
    // setting disable to false below and then using disabled prop to set readonly of underlying input
    // causes value to not be alterable, yet not grayed out which coincides with other behavior elsewhere
    const input = (params: AutocompleteRenderInputParams) => (
        <TextField
            {...params}
            label={emptyLabel ? emptyLabel : ''}
            id={'Autocomplete-input-' + title}
            fullWidth
            error={hasError}
            variant={variant}
            disabled={false}
            InputProps={{
                ...params.InputProps,
                readOnly: params.disabled,
                endAdornment: (
                    <React.Fragment>
                        {loading ? <CircularLoader size={15} fillHeight="20px" /> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>
                ),
            }}
            inputRef={inputRef}
        />
    );
    return (
        <InputWrapper title={title} error={errorMessage} fullHeight={fullHeight} spacing={spacing}>
            <MuiAutoComplete
                id={title}
                autoSelect={true}
                freeSolo={freeType}
                options={options}
                getOptionLabel={getOptionLabel}
                renderInput={(params) => input(params)}
                {...restOfProps}
            />
        </InputWrapper>
    );
};

export default Autocomplete;
