import actionCreator from '../lib/actionCreator';
import { Dispatch } from 'redux';
import { REDUX } from 'constants/redux';
import { User } from '../../../types/users.types';
import { ActiveUserProducer } from 'types/types.type';
import { UserClaims } from '@okta/okta-auth-js/lib/types';

interface Payload {
    token: string;
    user: UserClaims;
}

const loginAction = actionCreator(REDUX.AUTH_LOGIN);
const activeUserAction = actionCreator(REDUX.ACTIVE_USER);
const logoutAction = actionCreator(REDUX.AUTH_LOGOUT);

export const logoutSuccess = () => (dispatch: Dispatch) => {
    dispatch(logoutAction.success());
};

export const signInSuccess = (payload: Payload) => (dispatch: Dispatch) => {
    dispatch(loginAction.success(payload));
};

export const signInError = (error: string) => (dispatch: Dispatch) => {
    dispatch(loginAction.error(error));
};

export const activeUserSuccess = (payload: { activeUser: User; activeUserProducer: ActiveUserProducer }) => (
    dispatch: Dispatch
) => {
    dispatch(activeUserAction.success(payload));
};

export const activeUserError = (error: string) => (dispatch: Dispatch) => {
    dispatch(activeUserAction.error(error));
};

export const activeUserLoading = () => (dispatch: Dispatch) => {
    dispatch(activeUserAction.loading());
};
