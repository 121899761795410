import * as React from 'react';
import Location from 'application/Models/Location';
import { OKTA_GROUPS, ENDORSEMENT_TYPES, STATUS_TYPES, ROUTES } from 'constants/index';
import { isEndorsementWithoutUnderwriting, isNonMoniedEndorsement } from '../endorsements';
import { SubmissionDetails } from 'types/submission.type';
import { getIsAgentRole, getIsCarrierRepRole } from '../roles';
import { Domain, FolderOpen, Person, Public, InsertChart } from '@mui/icons-material';
import { Margin } from 'components/Layout';
import { ReactComponent as List } from '../../assets/icons/Shape.svg';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { NavAction, NavLink } from 'types/nav.types';
import SubmissionLossSummaryLink from '../../components/SideNav/components/SubmissionLossSummaryLink';
import UWReviewLink from '../../components/SideNav/components/UWReviewLink';

export const DETAIL_LINKS = {
    CLEARING: 'Clearing',
    PREPARE_QUOTES: 'Prepare Quotes',
    ENDORSEMENT_REVIEW: 'Endorsement Review',
    QUOTE_REVIEW: 'Quote Review',
    QUOTES_VIEW: 'Quotes',
    BIND_REQUEST_REVIEW: 'Bind Request Review',
    ISSUANCE: 'Issuance',
    OVERVIEW: 'Overview',
    FINANCIAL: 'Financial',
    SOV: 'Statement of Values',
    LOSS_SUMMARY: 'Loss Summary',
    SOV_MAP: 'Location Map',
    UW_REVIEW: 'Underwriting Review',
    AGENT_DASHBOARD: 'Agent Dashboard',
    REFERRALS: 'Referrals',
    LOCATION: 'LOCATION',
};

const carrierRepDetailLinks = [DETAIL_LINKS.REFERRALS, DETAIL_LINKS.LOSS_SUMMARY, DETAIL_LINKS.LOCATION];

const agentDetailLinks = [
    DETAIL_LINKS.QUOTE_REVIEW,
    DETAIL_LINKS.OVERVIEW,
    DETAIL_LINKS.SOV,
    DETAIL_LINKS.LOSS_SUMMARY,
];
const underwriterDetailLinks = [
    DETAIL_LINKS.CLEARING,
    DETAIL_LINKS.ISSUANCE,
    DETAIL_LINKS.BIND_REQUEST_REVIEW,
    DETAIL_LINKS.ENDORSEMENT_REVIEW,
    DETAIL_LINKS.PREPARE_QUOTES,
    DETAIL_LINKS.OVERVIEW,
    DETAIL_LINKS.REFERRALS,
    DETAIL_LINKS.FINANCIAL,
    DETAIL_LINKS.QUOTES_VIEW,
    DETAIL_LINKS.SOV,
    DETAIL_LINKS.LOSS_SUMMARY,
    DETAIL_LINKS.SOV_MAP,
    DETAIL_LINKS.UW_REVIEW,
    DETAIL_LINKS.LOCATION,
];
const accountManagerDetailLinks = [
    DETAIL_LINKS.CLEARING,
    DETAIL_LINKS.ISSUANCE,
    DETAIL_LINKS.BIND_REQUEST_REVIEW,
    DETAIL_LINKS.ENDORSEMENT_REVIEW,
    DETAIL_LINKS.PREPARE_QUOTES,
    DETAIL_LINKS.OVERVIEW,
    DETAIL_LINKS.FINANCIAL,
    DETAIL_LINKS.QUOTES_VIEW,
    DETAIL_LINKS.SOV,
    DETAIL_LINKS.LOSS_SUMMARY,
    DETAIL_LINKS.SOV_MAP,
    DETAIL_LINKS.UW_REVIEW,
    DETAIL_LINKS.LOCATION,
];

const adminDetailLinks = [...underwriterDetailLinks];
adminDetailLinks.splice(adminDetailLinks.indexOf(DETAIL_LINKS.QUOTES_VIEW) + 1, 0, DETAIL_LINKS.AGENT_DASHBOARD); // insert dashboard after quotes link

const isRequoteDeclined = (submission: SubmissionDetails) =>
    STATUS_TYPES.UNDERWRITER_DECLINED === submission.statusType.code &&
    submission.underwriterReviews &&
    submission.underwriterReviews.length >= 1;
const isReadyToRequote = (submission: SubmissionDetails) =>
    STATUS_TYPES.READY_TO_QUOTE === submission.statusType.code &&
    submission.underwriterReviews &&
    submission.underwriterReviews.length > 1;

const getDetailLinksForGroup = (group: string) => {
    switch (group) {
        case OKTA_GROUPS.AGENT:
            return agentDetailLinks;
        case OKTA_GROUPS.ACCOUNT_MANAGER:
            return accountManagerDetailLinks;
        case OKTA_GROUPS.UNDERWRITER:
            return underwriterDetailLinks;
        case OKTA_GROUPS.ADMIN:
            return adminDetailLinks;
        case OKTA_GROUPS.CARRIER_REP:
            return carrierRepDetailLinks;
        default:
            return [];
    }
};

export const isDetailLinkAvailable = (link: string, submission: SubmissionDetails) => {
    const { statusType } = submission;

    switch (link) {
        case DETAIL_LINKS.CLEARING:
            return [STATUS_TYPES.READY_FOR_CLEARING, STATUS_TYPES.BLOCKED_BY_CLEARING].includes(statusType.code);
        case DETAIL_LINKS.SOV:
            return true;
        case DETAIL_LINKS.LOSS_SUMMARY:
            return !isEndorsementWithoutUnderwriting(submission);
        case DETAIL_LINKS.BIND_REQUEST_REVIEW:
            return statusType.code === STATUS_TYPES.BIND_REQUEST_REVIEW;
        case DETAIL_LINKS.PREPARE_QUOTES:
            return statusType.code === STATUS_TYPES.READY_TO_QUOTE;
        case DETAIL_LINKS.QUOTES_VIEW:
            return (
                [
                    STATUS_TYPES.QUOTED,
                    STATUS_TYPES.REQUOTE_REQUESTED,
                    STATUS_TYPES.BIND_REQUEST_REVIEW,
                    STATUS_TYPES.BOUND,
                    STATUS_TYPES.ISSUED,
                ].includes(statusType.code) && !isEndorsementWithoutUnderwriting(submission)
            );
        case DETAIL_LINKS.ENDORSEMENT_REVIEW:
            return (
                statusType.code === STATUS_TYPES.ENDORSEMENT_REQUEST_REVIEW &&
                [ENDORSEMENT_TYPES.MONIED_LOCATION_REMOVAL, ENDORSEMENT_TYPES.CANCELLATION].includes(
                    submission?.endorsementType?.code ?? ''
                )
            );
        case DETAIL_LINKS.ISSUANCE:
            return [STATUS_TYPES.READY_TO_ISSUE, STATUS_TYPES.BOUND].includes(statusType.code);
        case DETAIL_LINKS.QUOTE_REVIEW:
            return !submission.isInForce && submission.quotes && submission.quotes.length > 0; // if the submission has quotes and isn't yet inForce, then the user should be able to review them
        case DETAIL_LINKS.UW_REVIEW:
            return (
                ![STATUS_TYPES.READY_FOR_CLEARING, STATUS_TYPES.BLOCKED_BY_CLEARING].includes(statusType.code) &&
                ![ENDORSEMENT_TYPES.CANCELLATION].includes(submission?.endorsementType?.code ?? '')
            );
        case DETAIL_LINKS.REFERRALS:
            return submission.referrals && submission.referrals.length > 0;
        default:
            return true;
    }
};

const getDetailLinkUrl = (link: string, submissionId: string) => {
    switch (link) {
        case DETAIL_LINKS.CLEARING:
            return ROUTES.clearing(submissionId);
        case DETAIL_LINKS.PREPARE_QUOTES:
            return ROUTES.quotes(submissionId);
        case DETAIL_LINKS.QUOTE_REVIEW:
            return ROUTES.quoteReview(submissionId);
        case DETAIL_LINKS.QUOTES_VIEW:
            return ROUTES.quotesView(submissionId);
        case DETAIL_LINKS.BIND_REQUEST_REVIEW:
            return ROUTES.bindRequest(submissionId);
        case DETAIL_LINKS.ENDORSEMENT_REVIEW:
            return ROUTES.endorsememtReview(submissionId);
        case DETAIL_LINKS.ISSUANCE:
            return ROUTES.issuance(submissionId);
        case DETAIL_LINKS.OVERVIEW:
            return ROUTES.overview(submissionId);
        case DETAIL_LINKS.FINANCIAL:
            return ROUTES.financial(submissionId);
        case DETAIL_LINKS.SOV:
            return ROUTES.SOV(submissionId);
        case DETAIL_LINKS.LOSS_SUMMARY:
            return ROUTES.lossSummary(submissionId);
        case DETAIL_LINKS.SOV_MAP:
            return ROUTES.sovMap(submissionId);
        case DETAIL_LINKS.UW_REVIEW:
            return ROUTES.uwReview(submissionId);
        case DETAIL_LINKS.AGENT_DASHBOARD:
            return ROUTES.submissionAgentDashboard(submissionId);
        case DETAIL_LINKS.REFERRALS:
            return ROUTES.referrals(submissionId);
        default:
            return '/';
    }
};

export const getAvailableDetailLinks = (group: string, submission: SubmissionDetails) => {
    const baseLinks = getDetailLinksForGroup(group);
    return baseLinks.filter((l: string) => isDetailLinkAvailable(l, submission));
};

export const getDetailLinks = (group: string, submission?: SubmissionDetails, sovLocations?: Location[]) => {
    if (!submission) return [];
    const links = getAvailableDetailLinks(group, submission);
    return links.reduce((agg: any[], l: string) => {
        if (l !== DETAIL_LINKS.LOCATION) {
            return [
                ...agg,
                {
                    label: l,
                    ...getDetailLinkDetails(l, submission),
                },
            ];
        } else {
            return [...agg, ...getAddressLinks(group, submission, sovLocations)];
        }
    }, []);
};

function getAddressAvailable(group: string, l: Location, submission: SubmissionDetails) {
    if (getIsCarrierRepRole(group)) {
        // carriers can only see locations they are part of a referral for
        const nuRefs =
            submission.referrals &&
            submission.referrals.filter((r) => {
                const nuLos = r.locations.filter((rl) => {
                    return rl.locationId === l.id;
                });
                return nuLos.length > 0;
            }).length;
        return nuRefs && nuRefs > 0;
    }
    return true;
}

export const getAddressLinks = (group: string, submission?: SubmissionDetails, sovLocations?: Location[]) => {
    if (!submission || !sovLocations) return [];
    const areLinksAvailable =
        !getIsAgentRole(group) &&
        (getIsCarrierRepRole(group) || isDetailLinkAvailable(DETAIL_LINKS.UW_REVIEW, submission));

    return !areLinksAvailable
        ? []
        : sovLocations
              .filter(
                  (l) =>
                      (submission.isRemovalEndorsement || !l.markedAsDeleted) &&
                      getAddressAvailable(group, l, submission)
              )
              .map((l: Location) => ({
                  label: l.locationName,
                  to: ROUTES.location(submission.id, l.id),
                  isNested: true,
              }));
};

export const NAV_LINKS = {
    SUBMISSIONS: 'Accounts',
    REPORTING: 'Reporting',
    GEO_FUSE: 'GeoFuse',
    USERS: 'Users',
    ORGANIZATIONS: 'Organizations',
    DOCUMENT_TEMPLATES: 'Document Templates',
    AGENT_DASHBOARD: 'Agent Dashboard',
    LUMEN_DASHBOARD: 'Lumen Dashboard',
};

const agentNavLinks = [NAV_LINKS.AGENT_DASHBOARD, NAV_LINKS.SUBMISSIONS];

const accountManagerNavLinks = [
    NAV_LINKS.LUMEN_DASHBOARD,
    NAV_LINKS.SUBMISSIONS,
    NAV_LINKS.REPORTING,
    NAV_LINKS.GEO_FUSE,
];

const accountManagerConfigNavLinks: string[] = [NAV_LINKS.ORGANIZATIONS];

const adminNavLinks = [NAV_LINKS.LUMEN_DASHBOARD, NAV_LINKS.SUBMISSIONS, NAV_LINKS.REPORTING, NAV_LINKS.GEO_FUSE];

const adminConfigNavLinks = [NAV_LINKS.USERS, NAV_LINKS.ORGANIZATIONS, NAV_LINKS.DOCUMENT_TEMPLATES];

const carrierRepNavLinks = [NAV_LINKS.SUBMISSIONS];

const getNavLinksForGroup = (group: string) => {
    switch (group) {
        case OKTA_GROUPS.ADMIN:
            return adminNavLinks;
        case OKTA_GROUPS.ACCOUNT_MANAGER:
        case OKTA_GROUPS.UNDERWRITER:
            return accountManagerNavLinks;
        case OKTA_GROUPS.AGENT:
            return agentNavLinks;
        case OKTA_GROUPS.CARRIER_REP:
            return carrierRepNavLinks;
        default:
            return [];
    }
};

const getConfigNavLinksForGroup = (group: string) => {
    switch (group) {
        case OKTA_GROUPS.ADMIN:
            return adminConfigNavLinks;
        case OKTA_GROUPS.ACCOUNT_MANAGER:
        case OKTA_GROUPS.UNDERWRITER:
            return accountManagerConfigNavLinks;
        case OKTA_GROUPS.AGENT:
        case OKTA_GROUPS.CARRIER_REP:
        default:
            return [];
    }
};

const getDetailLinkDetails = (link: string, submission: SubmissionDetails) => {
    switch (link) {
        case DETAIL_LINKS.LOSS_SUMMARY:
            return {
                to: getDetailLinkUrl(link, submission.id),
                actionComponent: (key: string | number, props: NavAction) => (
                    <SubmissionLossSummaryLink submission={submission} key={key} linkProps={props} />
                ),
            };
            break;
        case DETAIL_LINKS.UW_REVIEW:
            return {
                to: getDetailLinkUrl(link, submission.id),
                actionComponent: (key: string | number, props: NavAction) => (
                    <UWReviewLink submission={submission} key={key} linkProps={props} />
                ),
            };
        default:
            return {
                to: getDetailLinkUrl(link, submission.id),
            };
    }
};

const getNavLinkDetails = (link: string) => {
    switch (link) {
        case NAV_LINKS.LUMEN_DASHBOARD:
            return {
                to: ROUTES.lumenDashboard(),
                icon: <DashboardIcon />,
                label: 'Dashboard',
            };
            break;
        case NAV_LINKS.AGENT_DASHBOARD:
            return {
                to: ROUTES.agentDashboard(),
                icon: <DashboardIcon />,
                label: 'Dashboard',
            };
            break;
        case NAV_LINKS.SUBMISSIONS:
            return {
                to: ROUTES.submissions(),
                icon: (
                    <Margin top="3" right="3" left="2">
                        <List />
                    </Margin>
                ),
            };
            break;
        case NAV_LINKS.REPORTING:
            return {
                to: ROUTES.reporting(),
                icon: <InsertChart fontSize="small" />,
            };
            break;
        case NAV_LINKS.GEO_FUSE:
            return {
                to: ROUTES.geoFuse(),
                icon: <Public fontSize="small" />,
            };
            break;
        case NAV_LINKS.USERS:
            return {
                to: ROUTES.users(),
                icon: <Person fontSize="small" />,
            };
            break;
        case NAV_LINKS.ORGANIZATIONS:
            return {
                to: ROUTES.organizations(),
                icon: <Domain fontSize="small" />,
            };
            break;
        case NAV_LINKS.DOCUMENT_TEMPLATES:
            return {
                to: ROUTES.templateManagement(),
                icon: <FolderOpen fontSize="small" />,
            };
        default:
            return false;
    }
};

export const getAvailableNavLinks = (group: string) => {
    const baseLinks = getNavLinksForGroup(group);
    return baseLinks;
};

export const getAvailableConfigNavLinks = (group: string) => {
    const baseLinks = getConfigNavLinksForGroup(group);
    return baseLinks;
};

export const getNavLinks = (group: string): NavLink[] => {
    const links = getAvailableNavLinks(group);
    return links.map((l: string) => ({
        label: l,
        ...getNavLinkDetails(l),
    }));
};

export const getConfigNavLinks = (group: string): NavLink[] => {
    const links = getAvailableConfigNavLinks(group);
    return links.map((l: string) => ({
        label: l,
        ...getNavLinkDetails(l),
    }));
};
