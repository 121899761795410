import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import { PDFViewerProps } from '../../../../helpers/files';
import LrDownloadButton, { LrDownloadButtonAPI } from '../../../LrDownloadButton';
import { getDocument } from '../../../../services/templateManagement';
import { COLORS } from '../../../../constants';
import { getHeadersAsDict } from '../../../../services/config';

const PDFjsExpress: FunctionComponent<PDFViewerProps> = (props) => {
    const { fileUrl, fileName, blob, initialPageNumber } = props;
    const [viewerApi, setViewerApi] = useState<any>();
    const downloadButtonApi = useRef<LrDownloadButtonAPI>(null);
    const viewer = useRef(null);

    useEffect(() => {
        WebViewer(
            {
                path: process.env.PUBLIC_URL + '/webviewer/lib',
                licenseKey: window.location.hostname.includes('lumenrisk.com')
                    ? '0yqjN8xdljTvgQgufSSQ'
                    : 'bhC3A6g7QkIOcrDOiHV5',
                disabledElements: [
                    'stickyToolButton',
                    'highlightToolButton',
                    'freeHandHighlightToolButton',
                    'freeHandToolButton',
                    'freeTextToolButton',
                ],
            },
            viewer.current
        ).then((instance: any) => {
            // now you can access APIs through the WebViewer instance
            setViewerApi(instance);

            instance.UI.setHeaderItems((header: any) => {
                const downloadButton = {
                    type: 'actionButton',
                    img:
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>download file</title><path  d="M0 0h24v24H0z" fill="none"/><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/></svg>',
                    onClick: handleDownloadButton,
                };

                header.push(downloadButton);
            });

            const { documentViewer } = instance.Core;
            documentViewer.addEventListener('documentLoaded', () => {
                if (initialPageNumber) {
                    documentViewer.displayPageLocation(initialPageNumber, 0, 0, true);
                }
            });

            const style = instance.UI.iframeWindow.document.documentElement.style;
            style.setProperty(`--icon-color`, COLORS.SECONDARY_LIGHT);
            style.setProperty(`--view-header-icon-active-fill`, COLORS.SECONDARY);
        });
    }, []);

    useEffect(() => {
        if (fileUrl && viewerApi) {
            viewerApi.UI.loadDocument(fileUrl, { filename: fileName, customHeaders: getHeadersAsDict() });
        }
    }, [fileUrl, viewerApi]);

    const handleDownloadButton = () => {
        downloadButtonApi?.current?.doDownload();
    };

    const dataFetchFn = useCallback(async () => await getDocument(fileUrl), [fileUrl]);

    return (
        <>
            <div style={{ height: '100%' }} ref={viewer} />
            <LrDownloadButton blob={blob} fileName={fileName} dataFetchFn={dataFetchFn} ref={downloadButtonApi} />
        </>
    );
};

export default PDFjsExpress;
