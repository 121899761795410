import { combineReducers } from 'redux';

import authentication from './authentication';
import clearing from './clearing';
import dialogs from './dialogs';
import quotes from './quotes';
import submissions from './submissions';
import types from './types';
import sov from './sov';
import submissionDetails from './submissionDetails';
import uwReview from './uwReview';
import uwReviews from './uwReviews';
import users from './users';
import submissionUserPreferences from './submissionUserPreferences';
import reporting from './reporting';
import locationOptions from './locationOptions';
import createEndorsementContext from './createEndorsementContext';
import submissionReferralMetadata from './submissionReferralMetadata';
import business from './business';

const appReducer = combineReducers({
    authentication,
    clearing,
    dialogs,
    quotes,
    sov,
    submissions,
    submissionDetails,
    uwReview,
    uwReviews,
    types,
    locationOptions,
    users,
    submissionUserPreferences,
    reporting,
    createEndorsementContext,
    submissionReferralMetadata,
    business,
});

export default appReducer;
