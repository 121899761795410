import { Dispatch } from 'redux';

import { REDUX } from 'constants/index';
import { getUsers } from 'services/users';
import actionCreator from '../lib/actionCreator';

const userAction = actionCreator(REDUX.USERS);

export function fetchAllUsers() {
    return async (dispatch: Dispatch) => {
        dispatch(userAction.loading());
        getUsers()
            .then((users) => dispatch(userAction.success(users)))
            .catch((reason) => dispatch(userAction.error(reason || 'Policy Error')));
    };
}
