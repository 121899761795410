import * as React from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { IconButton, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';

import LrButton from 'components/LrButton';
import { Margin } from 'components/Layout';
import { COLORS } from 'constants/index';

import styles, { Wrapper } from '../styled/FileItem';
import { useCallback, useRef, useState } from 'react';
import LrDownloadButton from '../../LrDownloadButton';
import { documents } from 'application/Providers/API';
import { getFileViewer } from '../../../helpers/files';
import TitleIcon from '@mui/icons-material/Title';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { TextInput } from '../../Input';
import LrDialog from '../../LrDialog';
import { withStyles, WithStyles } from '@mui/styles';

interface Props {
    name: string;
    label?: string;
    description?: string;
    showDescriptionHover?: boolean;
    removable?: boolean;
    editable?: boolean;
    uuid?: string;
    noMargin?: boolean;
    onRemoveClick?: () => void;
    onFileChange?: (fileTitle: string, fileDescription: string) => void;
    blob?: Blob;
}

const defaultProps = {
    removable: false,
    noMargin: false,
    showDescriptionHover: false,
};

const fileButtonStyles = {
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: '0',
};

const FileItem: React.FunctionComponent<Props & WithStyles<typeof styles>> = (props) => {
    const {
        classes,
        name,
        label,
        description,
        showDescriptionHover,
        onRemoveClick,
        removable,
        editable,
        uuid,
        noMargin,
        blob,
        onFileChange,
    } = props;

    const [viewFile, setViewFile] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [tmpFileTitle, setTmpFileTitle] = useState<string>(label || '');
    const [tmpFileDescription, setTmpFileDescription] = useState<string>(description || '');

    const renderRemoveButton = () => (
        <Grid xs={1}>
            <IconButton className={classes.iconButton} onClick={onRemoveClick} size="large">
                <CloseIcon style={{ fontSize: '13px' }} />
            </IconButton>
        </Grid>
    );

    const fileTitle = label || name;
    const clickable = !!uuid || !!blob;
    const viewer = getFileViewer(name);

    const handleViewClick = viewer
        ? (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              setViewFile(true);
              event.preventDefault();
              event.stopPropagation();
          }
        : undefined;

    const handleFileChange = () => {
        onFileChange?.(tmpFileTitle, tmpFileDescription);
        setIsEditing(false);
        setShowDescription(false);
    };

    const dataFetchFn = useCallback(async () => documents.getDocument(uuid || '', name), [uuid]);

    return (
        <Wrapper noMargin={noMargin} className={classes.wrapperDiv}>
            <Grid container>
                {removable && renderRemoveButton()}
                <Grid xs={removable ? 11 : 12}>
                    {isEditing && (
                        <TextInput
                            value={tmpFileTitle}
                            name="fileTitle"
                            className={classes.editInput}
                            placeholder="File Title"
                            onChange={(event) => setTmpFileTitle(event.target.value)}
                            onKeyUp={(event) => {
                                if (event.key === 'Enter') {
                                    handleFileChange();
                                }
                            }}
                            onBlur={handleFileChange}
                            fullheight
                            autoFocus
                        />
                    )}
                    {!isEditing && (
                        <LrDownloadButton
                            fileName={name}
                            blob={blob}
                            dataFetchFn={uuid && !blob ? dataFetchFn : undefined} // if we already have the blob no need to pass a data fetch function
                            // maxWidth={removable ? '80%' : '100%'}
                        >
                            <LrButton
                                className={classes.fileButton}
                                href={''}
                                lrType="text"
                                disabled={!clickable}
                                onClick={handleViewClick ? handleViewClick : () => true} // let the even bubble to the LrDownloadButton if not viewable
                                style={{ width: '100%', ...fileButtonStyles }}
                            >
                                <Margin right="5">
                                    <InsertDriveFileIcon style={{ color: COLORS.GRAY }} />
                                </Margin>
                                <Tooltip title={showDescriptionHover ? description || '' : ''}>
                                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {fileTitle}
                                    </div>
                                </Tooltip>
                                {editable && (
                                    <>
                                        <Tooltip title={'Edit Display Title of this File'}>
                                            <IconButton
                                                className={classes.editIcon}
                                                size={'small'}
                                                onClick={(e) => {
                                                    setIsEditing(true);
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <TitleIcon style={{ fontSize: 15 }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={'Edit Description of this File'}>
                                            <IconButton
                                                className={classes.editIcon}
                                                size={'small'}
                                                onClick={(e) => {
                                                    setShowDescription(true);
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <DescriptionOutlinedIcon style={{ fontSize: 15 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )}
                            </LrButton>
                        </LrDownloadButton>
                    )}
                </Grid>
                {viewer &&
                    React.createElement(viewer, {
                        isOpen: viewFile,
                        fileName: name,
                        fileId: uuid ?? '',
                        fileTitle: fileTitle,
                        onClose: () => setViewFile(false),
                        blob: blob,
                    })}
                <LrDialog
                    isOpen={showDescription}
                    title={'Add File Description'}
                    onCancel={() => setShowDescription(false)}
                    onSubmit={handleFileChange}
                    submitButtonText={'OK'}
                >
                    <TextInput
                        multiline
                        value={tmpFileDescription}
                        onChange={(event) => setTmpFileDescription(event.target.value)}
                    />
                </LrDialog>
            </Grid>
        </Wrapper>
    );
};

FileItem.defaultProps = defaultProps;
export default withStyles(styles)(FileItem);
