import { Dispatch } from 'redux';

import { REDUX, GRID_NAMES } from 'constants/index';
import { fetchSov } from 'services/sov';
import actionCreator from '../lib/actionCreator';
import { getGridAction } from '../lib/gridActionCreator';
import { ProductAvailableLocationsState } from '../store/SOVState';

export const saveSovGrid = getGridAction(GRID_NAMES.SOV);

const sovAction = actionCreator(REDUX.SOV);
const sovLocationUWNotesAction = actionCreator(REDUX.SOV_LOCATION_UW_NOTES);

export function updateSOVLocationUWNotes(locationId: number, underwritingNote: string) {
    return (dispatch: Dispatch) => dispatch(sovLocationUWNotesAction.success({ locationId, underwritingNote }));
}

export function fetchSovReport(submissionId: string) {
    return async (dispatch: Dispatch) => {
        dispatch(sovAction.loading());
        fetchSov(submissionId)
            .then((sovReport) => dispatch(sovAction.success(sovReport)))
            .catch((reason) => dispatch(sovAction.error(reason)));
    };
}

export function sovSaveProductAvailableLocations(locations: ProductAvailableLocationsState) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: REDUX.SOV_SAVE_PRODUCT_AVAILABLE_LOCATIONS, payload: locations });
    };
}
