import { COLORS } from 'constants/index';

const MuiCardHeader = {
    title: {
        fontWeight: 700,
        fontSize: '17px',
        color: COLORS.SECONDARY,
    },
};

export default MuiCardHeader;
